import { FC } from "react";
import * as Yup from "yup";
import { Formik, FormikProps } from "formik";
import { PrimaryButton } from "../Atoms/Buttons";
import { FormText } from "../FormFields/FormText";
import { useAppSelector } from "../../store/hooks";
import { FormSelect } from "../FormFields/FormSelect";
import { FormTextArea } from "../FormFields/FormTextArea";

export interface DestinationFormValues {
  code: string;
  abbreviationName: string;
  identificationNumber: string;
  name: string;
  alias: string;
  destination: string;
  store: string;
  address: string;
  contact: string;
  phone1: string;
  phone2: string;
  email: string;
}
interface FormInterface {
  isUpdate?: boolean;
  formik: FormikProps<DestinationFormValues>;
}
const Form: FC<FormInterface> = ({ isUpdate, formik }) => {
  const identificationTypes = useAppSelector(
    (state) => state.inmutable.taxIdentificationTypes
  );

  return (
    <form onSubmit={formik.handleSubmit} className="flex h-full flex-col gap-6">
      <div className="flex h-full flex-col gap-4 pr-8">
        <div>
          <FormText
            disabled
            id="code"
            name="code"
            label="Código"
            value={formik.values.code}
            error={
              formik.touched.code && formik.errors.code ? formik.errors.code : undefined
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            labelClassname="text-md"
            labelContainerClassname="flex sm:!justify-end sm:w-52"
            containerClassname="flex flex-1 ml-8 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
            style={{ maxWidth: "10rem" }}
          />
        </div>

        <div>
          <FormSelect
            id="abbreviationName"
            name="abbreviationName"
            label="Tipo de Documento"
            selected={formik.values.abbreviationName}
            options={identificationTypes.map((type) => type.abbreviationName)}
            error={
              formik.touched.abbreviationName && formik.errors.abbreviationName
                ? formik.errors.abbreviationName
                : undefined
            }
            onBlur={formik.handleBlur}
            labelClassname="text-md"
            labelContainerClassname="flex sm:!justify-end sm:w-52"
            containerClassname="flex flex-1 ml-8 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
            style={{ maxWidth: "10rem" }}
            optionString={(option) => option.slice(0, 1)}
            onSelectOption={(option) => formik.setFieldValue("abbreviationName", option)}
          />
        </div>

        <div>
          <FormText
            id="identificationNumber"
            name="identificationNumber"
            label="Nro. Documento"
            value={formik.values.identificationNumber}
            error={
              formik.touched.identificationNumber && formik.errors.identificationNumber
                ? formik.errors.identificationNumber
                : undefined
            }
            onChange={(e) => {
              let re: RegExp;
              if (formik.values.abbreviationName !== "P-") {
                // Only numbers
                re = /^[0-9\b]{0,10}$/;
              } else {
                // Only numbers and letters
                re = /^[a-zA-Z0-9\b]{0,10}$/;
              }

              if (e.target.value == "" || re.test(e.target.value)) {
                formik.handleChange(e);
              }
            }}
            onBlur={formik.handleBlur}
            labelClassname="text-md"
            labelContainerClassname="flex sm:!justify-end sm:w-52"
            containerClassname="flex flex-1 ml-8 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
            style={{ maxWidth: "20rem" }}
          />
        </div>

        <div>
          <FormText
            id="name"
            name="name"
            label="Nombre/Descripción"
            autoComplete="off"
            value={formik.values.name}
            error={
              formik.touched.name && formik.errors.name ? formik.errors.name : undefined
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            labelClassname="text-md"
            labelContainerClassname="flex sm:!justify-end sm:w-52"
            containerClassname="flex flex-1 ml-8 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
            style={{ maxWidth: "20rem" }}
          />
        </div>

        <div>
          <FormText
            id="alias"
            name="alias"
            label="Alias"
            value={formik.values.alias}
            error={
              formik.touched.alias && formik.errors.alias
                ? formik.errors.alias
                : undefined
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            labelClassname="text-md"
            labelContainerClassname="flex sm:!justify-end sm:w-52"
            containerClassname="flex flex-1 ml-8 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
            style={{ maxWidth: "20rem" }}
          />
        </div>

        <div>
          <FormText
            id="destination"
            name="destination"
            label="Ciudad Destino"
            value={formik.values.destination}
            error={
              formik.touched.destination && formik.errors.destination
                ? formik.errors.destination
                : undefined
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            labelClassname="text-md"
            labelContainerClassname="flex sm:!justify-end sm:w-52"
            containerClassname="flex flex-1 ml-8 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
            style={{ maxWidth: "20rem" }}
          />
        </div>

        <div>
          <FormText
            id="store"
            name="store"
            label="Tienda Receptora"
            value={formik.values.store}
            error={
              formik.touched.store && formik.errors.store
                ? formik.errors.store
                : undefined
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            labelClassname="text-md"
            labelContainerClassname="flex sm:!justify-end sm:w-52"
            containerClassname="flex flex-1 ml-8 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
            style={{ maxWidth: "20rem" }}
          />
        </div>

        <div>
          <FormTextArea
            id="address"
            name="address"
            label="Dirección"
            autoComplete="off"
            value={formik.values.address}
            error={
              formik.touched.address && formik.errors.address
                ? formik.errors.address
                : undefined
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            labelClassname="text-md"
            labelContainerClassname="flex sm:!justify-end sm:w-52"
            containerClassname="flex flex-1 ml-8 sm:ml-0 sm:gap-8 flex-col sm:flex-row"
            style={{ maxWidth: "30rem" }}
          />
        </div>

        <div>
          <FormText
            id="contact"
            name="contact"
            label="Nombre Contacto"
            value={formik.values.contact}
            error={
              formik.touched.contact && formik.errors.contact
                ? formik.errors.contact
                : undefined
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            labelClassname="text-md"
            labelContainerClassname="flex sm:!justify-end sm:w-52"
            containerClassname="flex flex-1 ml-8 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
            style={{ maxWidth: "20rem" }}
          />
        </div>

        <div>
          <FormText
            id="phone1"
            name="phone1"
            label="Teléfono 1"
            value={formik.values.phone1}
            error={
              formik.touched.phone1 && formik.errors.phone1
                ? formik.errors.phone1
                : undefined
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            labelClassname="text-md"
            labelContainerClassname="flex sm:!justify-end sm:w-52"
            containerClassname="flex flex-1 ml-8 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
            style={{ maxWidth: "20rem" }}
          />
        </div>

        <div>
          <FormText
            id="phone2"
            name="phone2"
            label="Teléfono 2"
            value={formik.values.phone2}
            error={
              formik.touched.phone2 && formik.errors.phone2
                ? formik.errors.phone2
                : undefined
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            labelClassname="text-md"
            labelContainerClassname="flex sm:!justify-end sm:w-52"
            containerClassname="flex flex-1 ml-8 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
            style={{ maxWidth: "20rem" }}
          />
        </div>

        <div>
          <FormText
            id="email"
            name="email"
            label="Email"
            autoComplete="off"
            value={formik.values.email}
            error={
              formik.touched.email && formik.errors.email
                ? formik.errors.email
                : undefined
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            labelClassname="text-md"
            labelContainerClassname="flex sm:!justify-end sm:w-52"
            containerClassname="flex flex-1 ml-8 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
            style={{ maxWidth: "20rem" }}
          />
        </div>

        <div className="sm:ml-52">
          <PrimaryButton type="submit" className="ml-8">
            {isUpdate ? "Actualizar" : "Crear Destinatario"}
          </PrimaryButton>
        </div>
      </div>
    </form>
  );
};

interface DestinationFormProps {
  isUpdate?: boolean;
  initialValues?: DestinationFormValues;
  onSubmit?: (prop: DestinationFormValues) => void;
}
export const DestinationForm: FC<DestinationFormProps> = ({
  isUpdate = false,
  initialValues = {
    code: "",
    abbreviationName: "V-",
    identificationNumber: "",
    name: "",
    alias: "",
    destination: "",
    store: "",
    address: "",
    contact: "",
    phone1: "",
    phone2: "",
    email: "",
  },
  onSubmit = () => {},
}) => {
  // Validations with Yup for Formik form
  const validationSchema = Yup.object().shape({
    identificationNumber: Yup.string().required("Este campo es requerido"),
    name: Yup.string().required("Este campo es requerido"),
    alias: Yup.string().required("Este campo es requerido"),
    destination: Yup.string().required("Este campo es requerido"),
    store: Yup.string().required("Este campo es requerido"),
    address: Yup.string().required("Este campo es requerido"),
    contact: Yup.string().required("Este campo es requerido"),
    phone1: Yup.string().required("Este campo es requerido"),
    email: Yup.string().required("Este campo es requerido"),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        onSubmit(values);
        resetForm();
      }}
    >
      {(formik) => <Form isUpdate={isUpdate} formik={formik} />}
    </Formik>
  );
};
