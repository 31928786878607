import { FormText } from '../FormFields/FormText';
import { useAppSelector } from '../../store/hooks';
import { FormSelect } from '../FormFields/FormSelect';
import {
  AccountInterface,
  BusinessUnitInterface,
  DeliveryEnum,
  DestinationInterface,
  FavoriteDestinationsInterfase,
  LocationInterface,
  TaxIdentificationTypeInterface,
} from '../../interfaces';
import { FormTextArea } from '../FormFields/FormTextArea';
import { FormikErrors, FormikProps, FormikTouched } from 'formik';
import { AccountSearchFavoriteHistoric } from '../Account/AccountSearch';
import { useCallback, useEffect, useState } from 'react';
import {
  filterBusinessUnit,
  filterGeneral,
  filterCity,
} from '../../utils/filters';
import {
  BusinessUnitOption,
  CityOption,
  GenericoOption,
} from '../FormFields/FormSearchOptions';
import { FormSearch } from '../FormFields/FormSearch';
import { FormRadioGroup } from '../FormFields/FormRadioGroup';
import { AddressSearch } from '../FormFields/AddressSearch';
import { AutocompleteRegion } from '../FormFields/FormMaps';
import { GeneralTypePhoneServices } from '../../services/GeneralServices';
import { GeneralTypeInterface } from '../../interfaces/Dto/GeneralTypeInterface';
import { getAllFavoriteDestinations } from '../../services/FavDestinationsServices';
import { ConfirmModalStatus } from '../Preshipment/ModalSuccess';
import { ModalLoading } from '../Atoms/ModalLoading';
import { obtenerCodigoArea, obtenerNumero } from '../../utils/format';
import { getBusinessUnitByTerritory } from '../../services';

export interface FavoriteDestinationFormValues {
  abbreviationName: TaxIdentificationTypeInterface;
  identificationNumber: string;
  fullName: string;
  codePhoneNumber?: string;
  phoneId?: string;
  phoneTypeId: string;
  phoneNumber: string;
  acceptsSMS: boolean;
  codecellNumber?: string;
  cellPhoneNumber: string;
  emailId?: string;
  email: string;
  locationId?: string;
  city: string;
  cityCode?: string;
  postalCode: string;
  addressId?: string;
  address: string;
  businessUnit?: BusinessUnitInterface;
  accountSelected?: AccountInterface;
  ShipmentType?: DeliveryEnum;
  reference: string;
  reset?: boolean;
  id?: string;
  favoriteId?: string;
}

interface FavoriteDestinationFormField {
  consignee: FavoriteDestinationFormValues;
  shippingLocation: LocationInterface;
}
interface ConsigneeFormPropsV2<T> {
  formik: FormikProps<T & FavoriteDestinationFormField>;
}

export const FavoriteDestinationForm = <T extends any>({
  formik,
}: ConsigneeFormPropsV2<T>) => {
  const cities = useAppSelector((state) => state.inmutable.cities);
  const businessUnits = useAppSelector(
    (state) => state.inmutable.businessUnits
  );
  const identificationTypes = useAppSelector(
    (state) => state.inmutable.taxIdentificationTypes
  );

  const clientId = useAppSelector((state) => state.user.client?.id);

  const user = useAppSelector((state) => state.user)!;

  const errors = formik.errors.consignee as
    | FormikErrors<FavoriteDestinationFormValues>
    | undefined;

  const touched = formik.touched.consignee as
    | FormikTouched<FavoriteDestinationFormValues>
    | undefined;

  const [phonePreSearch, setPhonePreSearch] = useState('');
  const [businessUnitSearch, setBusinessUnitSearch] = useState('');
  const [citySearch, setCitySearch] = useState('');

  const [titleShipment] = useState('Oficina que entrega');

  const [favoriteList, setFavoriteList] = useState<DestinationInterface[]>([]);
  const [historicList, setHistoricList] = useState<DestinationInterface[]>([]);
  const [selectDestination, setselectDestination] = useState<boolean>(false);
  const [phonePrefixList, setPhonePrefixList] = useState<string[]>([]);
  const [disabledIdentification, setDisabledIdentification] =
    useState<boolean>(false);
  const stores = useAppSelector((state) => state.inmutable.businessUnits);

  let [loading, setLoading] = useState(false);

  let [loadingStatus, setLoadingStatus] = useState(
    ConfirmModalStatus.PROCESSING
  );
  let [loadingTitle, setLoadingTitle] = useState('Cargando...');

  const updatePhoneSearch = useCallback(() => {
    if (!!formik.values.consignee.codePhoneNumber) {
      setPhonePreSearch(formik.values.consignee.codePhoneNumber);
    } else {
      setPhonePreSearch('');
    }
  }, [formik.values.consignee.codePhoneNumber]);

  const [favDestination, setfavoriteDestination] =
    useState<FavoriteDestinationsInterfase>();

  useEffect(() => {
    const accountID = '' + user?.client?.id;
    if (!accountID) return;
    if (!formik.values.consignee.favoriteId) return;

    const fetchShipments = async () => {
      setLoadingStatus(ConfirmModalStatus.PROCESSING);
      setLoadingTitle('Cargando...');
      setLoading(true);

      const favoriteDestination = await getAllFavoriteDestinations(
        accountID,
        formik.values.consignee.favoriteId
      );
      var listfavoriteDestination =
        favoriteDestination?.model as FavoriteDestinationsInterfase[];
      if (!favoriteDestination?.didError) {
        setfavoriteDestination(listfavoriteDestination[0]);
        let client = listfavoriteDestination[0];

        setselectDestination(true);
        setDisabledIdentification(true);

        formik.setValues({
          ...formik.values,
          consignee: {
            ...formik.values.consignee,
            id: client.accountDestinationID,
            abbreviationName: identificationTypes.find(
              (t) => t.abbreviationName === client?.taxIDentificationTypeCode
            )!,
            identificationNumber: client.identificationNumber,
            address: client.addressLine1 ?? '',
            fullName: client.accountFullName ?? '',
            phoneId: client.phoneID?.toString() ?? undefined,
            phoneNumber: obtenerNumero(client.phoneNumber ?? ''),
            emailId: client.emailID ?? undefined,
            email: client.email ?? '',
            codePhoneNumber: obtenerCodigoArea(client.phoneNumber) ?? '',
            reset: false,
            accountSelected: {} as AccountInterface,
            ShipmentType:
              client.destinationAddressID !== null
                ? DeliveryEnum.DELIVERY
                : DeliveryEnum.OFFICE,
          },
        });

        let buWork = businessUnits.find(
          (x) => x.buCode === client.buCodeConsignee
        );
        formik.setFieldValue('shippingLocation', buWork?.location);
        updateBusinessUnitSearch(
          buWork,
          client.destinationAddressID !== undefined
            ? client.addressLine1
            : undefined
        );
        setLoadingStatus(ConfirmModalStatus.SUCCESS);
        setLoading(false);
      } else {
        setLoadingStatus(ConfirmModalStatus.ERROR);
        setLoadingTitle('Error: ' + favoriteDestination?.errorMessage);
        setLoading(true);
      }
    };
    fetchShipments();
  }, [user?.client?.id]);

  useEffect(() => {
    if (formik.values.consignee.reset) setselectDestination(false);
    else setselectDestination(true);
  }, [formik.values.consignee.reset]);

  useEffect(() => {
    if (formik.values.consignee.businessUnit === undefined) {
      setBusinessUnitSearch('');
      setCitySearch('');
    }
  }, [formik.values.consignee.businessUnit]);

  const updateBusinessUnitSearch = (
    businessUnit?: BusinessUnitInterface,
    Address?: string
  ) => {
    if (!!businessUnit) {
      const findBusiness = businessUnits.find(
        (x) => x.buCode === businessUnit.buCode
      );
      const fiendCities = cities.find(
        (c) => c.locationCode === findBusiness?.location?.locationCode
      );

      if (
        formik.values.consignee?.ShipmentType?.toString() !==
        DeliveryEnum.DELIVERY?.toString()
      ) {
        formik.setFieldValue(
          'consignee.address',
          businessUnit?.location.address ?? ''
        );
      }
      if (Address !== undefined) {
        formik.setFieldValue('consignee.address', Address ?? '');
      }
      formik.setFieldValue(
        'consignee.cityCode',
        fiendCities?.locationCode ?? ''
      );
      formik.setFieldValue(
        'consignee.cityCode',
        fiendCities?.locationCode ?? ''
      );
      formik.setFieldValue('consignee.businessUnit', businessUnit);
      formik.setFieldValue('consignee.city', fiendCities?.locationName ?? '');
      setBusinessUnitSearch(
        businessUnit?.buCode + ' - ' + businessUnit?.location.name
      );
    } else {
      setBusinessUnitSearch('');
      setCitySearch('');
    }
  };

  const updateCitySearch = useCallback(() => {
    if (!!formik.values.consignee.city) {
      setCitySearch(formik.values.consignee.city);
    } else {
      setCitySearch('');
    }
  }, [formik.values.consignee.city]);

  const getLocations = async () => {
    setFavoriteList([]);
    setHistoricList([]);

    const phonetype = await GeneralTypePhoneServices('236');
    if (!!phonetype.didError || !phonetype.model) return;

    const emptyPhonePrefix = { value: 'FIJO', text: 'FIJO' };
    let phonetypeList = phonetype.model as unknown as GeneralTypeInterface[];
    phonetypeList.push(emptyPhonePrefix);

    setPhonePrefixList(
      (phonetypeList as unknown as GeneralTypeInterface[]).map(
        (phone) => phone.value
      )
    );
  };

  useEffect(() => {
    getLocations();
  }, [clientId]);

  return (
    <div className="flex h-full flex-col gap-1">
      {formik.values.consignee.favoriteId === undefined && (
        <div>
          <AccountSearchFavoriteHistoric
            title=""
            hideFavAndShip={true}
            selected={formik.values.consignee.accountSelected}
            error={
              touched?.accountSelected && errors?.accountSelected
                ? errors?.accountSelected
                : undefined
            }
            placeholder="Buscar destinatario..."
            favoriteLocations={[]}
            historicLocations={[]}
            onSelectClient={async (client) => {
              setselectDestination(true);
              setDisabledIdentification(true);
              formik.setValues({
                ...formik.values,
                consignee: {
                  ...formik.values.consignee,
                  id: client.id,
                  abbreviationName: identificationTypes.find(
                    (t) => t.abbreviationName === client?.abreviationName
                  )!,
                  identificationNumber: client.identificationNumber,
                  address: client.fiscalAddress ?? '',
                  fullName: client.accountFullName ?? '',
                  phoneId:
                    client.listAccountPhone[0]?.phoneID?.toString() ??
                    undefined,
                  phoneTypeId: client.listAccountPhone[0]?.phoneTypeId ?? '',
                  phoneNumber:
                    obtenerNumero(client.listAccountPhone[0]?.phoneNumber) ??
                    '',
                  emailId: client.listAccountEmail[0]?.emailID ?? undefined,
                  email: client.listAccountEmail[0]?.email ?? '',
                  codePhoneNumber:
                    obtenerCodigoArea(
                      client.listAccountPhone[0]?.phoneNumber
                    ) ?? '',
                  accountSelected: client,
                  reset: false,
                },
              });
              let buWork = businessUnits.find(
                (x) => x.buCode === client?.businessUnit?.buCode
              );
              //formik.setFieldValue("shippingLocation", buWork?.location);
              updateBusinessUnitSearch(buWork ?? client?.businessUnit);
            }}
            onSelectDestination={async (destination) => {
              formik.setValues({
                ...formik.values,
                consignee: {
                  ...formik.values.consignee,
                  businessUnit: destination.businessUnit,
                  abbreviationName: identificationTypes.find(
                    (t) =>
                      t.abbreviationName ===
                      destination.account?.abbreviationName
                  )!,
                  identificationNumber:
                    destination.account.identificationNumber,
                  address: destination.location.address ?? '',
                  fullName: destination.account.accountFullName ?? '',
                  codePhoneNumber:
                    obtenerCodigoArea(
                      destination.account.listAccountPhone[0]?.phoneNumber
                    ) ?? '',
                  phoneNumber:
                    obtenerNumero(
                      destination.account.listAccountPhone[0]?.phoneNumber
                    ) ?? '',
                  email: destination.account.listAccountEmail[0]?.email ?? '',
                  postalCode: destination.location.postalCode ?? '',
                  accountSelected: destination.account,
                  reset: false,
                  emailId:
                    destination.account?.listAccountEmail[0]?.emailID ??
                    undefined,
                  phoneId:
                    destination.account.listAccountPhone[0]?.phoneID?.toString() ??
                    undefined,
                  phoneTypeId:
                    destination.account.listAccountPhone[0]?.phoneTypeId?.toString() ??
                    '',
                  ShipmentType: destination.isHomeDelivery
                    ? DeliveryEnum.DELIVERY
                    : DeliveryEnum.OFFICE,
                },
              });

              let buWork: BusinessUnitInterface | undefined;
              if (!destination.isHomeDelivery) {
                buWork = businessUnits.find(
                  (bu) => bu.buCode === destination.businessUnit.buCode
                )!;
              } else {
                buWork = await getBusinessUnitByTerritory(
                  destination.location.coordinates.lng,
                  destination.location.coordinates.lat
                ).then((response: any) => response.model!);
              }

              updateBusinessUnitSearch(
                buWork ?? destination.businessUnit,
                destination.store?.location?.address ?? undefined
              );
              setselectDestination(true);
              setDisabledIdentification(true);
            }}
            openCreationModal={() => {
              formik.setValues({
                ...formik.values,
                consignee: {
                  ...formik.values.consignee,
                  abbreviationName: identificationTypes.find(
                    (t) => t.abbreviationName === 'V-'
                  )!,
                  identificationNumber: '',
                  codePhoneNumber: '',
                  address: '',
                  fullName: '',
                  phoneNumber: '',
                  email: '',
                  businessUnit: undefined,
                  accountSelected: {} as AccountInterface,
                  reset: false,
                },
              });
              setselectDestination(true);
              setDisabledIdentification(false);
            }}
          />
          <hr className="my-6" />
        </div>
      )}

      {selectDestination === true ||
      (formik.values.consignee.id !== undefined &&
        formik.values.consignee.id !== '') ? (
        <div className="flex h-full flex-col gap-1 mt-1 pl-6">
          <h4 className="text-sm text-main-500 gap-2 font-bold mb-3 mt-1">
            Detalles del Destinatario:
          </h4>
          <FormSelect
            autoComplete="off"
            id="consignee.abbreviationName"
            name="consignee.abbreviationName"
            label="Tipo de Documento"
            selected={formik.values.consignee.abbreviationName}
            options={identificationTypes}
            error={
              touched?.abbreviationName && errors?.abbreviationName
                ? errors?.abbreviationName
                : undefined
            }
            onBlur={formik.handleBlur}
            className="!h-7"
            labelClassname="!text-xs"
            labelContainerClassname="flex sm:!justify-end sm:w-32"
            containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
            style={{ maxWidth: '10rem' }}
            optionString={(option) => option.abbreviationName.slice(0, 1)}
            onSelectOption={(option) =>
              formik.setFieldValue('consignee.abbreviationName', option)
            }
            disabled={disabledIdentification}
          />

          <FormText
            id="consignee.identificationNumber"
            name="consignee.identificationNumber"
            label="Nro. Documento"
            value={formik.values.consignee.identificationNumber}
            error={
              touched?.identificationNumber && errors?.identificationNumber
                ? errors?.identificationNumber
                : undefined
            }
            onChange={(e) => {
              let re: RegExp;
              if (
                formik.values.consignee.abbreviationName.abbreviationName !==
                'P-'
              ) {
                // Only numbers
                re = /^[0-9\b]{0,10}$/;
              } else {
                // Only numbers and letters
                re = /^[a-zA-Z0-9\b]{0,10}$/;
              }

              if (e.target.value === '' || re.test(e.target.value)) {
                formik.handleChange(e);
              }
            }}
            onBlur={formik.handleBlur}
            className="!h-8"
            labelClassname="!text-xs"
            labelContainerClassname="flex sm:!justify-end sm:w-32"
            containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
            style={{ maxWidth: '10rem' }}
            disabled={disabledIdentification}
          />

          <FormText
            id="consignee.fullName"
            name="consignee.fullName"
            label="Nombre"
            value={formik.values.consignee.fullName}
            error={
              touched?.fullName && errors?.fullName
                ? errors?.fullName
                : undefined
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="!h-8"
            labelClassname="!text-xs"
            labelContainerClassname="flex sm:!justify-end sm:w-32"
            containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
            style={{ maxWidth: '15rem' }}
          />
          <FormSearch
            id="consignee.codePhoneNumber"
            name="consignee.codePhoneNumber"
            label="Operadora"
            value={phonePreSearch}
            options={phonePrefixList}
            unselect={!!formik.values.consignee.codePhoneNumber}
            className="!h-8"
            labelClassname="!text-xs"
            labelContainerClassname="flex sm:!justify-end sm:w-32"
            containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
            error={
              touched?.codePhoneNumber && errors?.codePhoneNumber
                ? errors?.codePhoneNumber
                : undefined
            }
            onBlur={formik.handleBlur}
            onChange={(e) => setPhonePreSearch(e.target.value)}
            onSelectOption={(option) =>
              formik.setFieldValue('consignee.codePhoneNumber', option)
            }
            onChangeFocus={(focus) => !focus && updatePhoneSearch()}
            style={{ maxWidth: '15rem' }}
            onFilter={filterGeneral}
            RenderOption={({ option }) => <GenericoOption text={option} />}
          />

          <FormText
            id="consignee.phoneNumber"
            name="consignee.phoneNumber"
            label="Teléfono"
            value={formik.values.consignee.phoneNumber}
            error={
              touched?.phoneNumber && errors?.phoneNumber
                ? errors?.phoneNumber
                : undefined
            }
            onChange={(e) => {
              let re: RegExp;
              re = /^[0-9\b]{0,7}$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                formik.handleChange(e);
              }
            }}
            maxLength={
              formik.values.consignee.codePhoneNumber !== 'FIJO' ? 7 : 10
            }
            onBlur={formik.handleBlur}
            className="!h-8"
            labelClassname="!text-xs"
            labelContainerClassname="flex sm:!justify-end sm:w-32"
            containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
            style={{ maxWidth: '15rem' }}
          />

          <FormText
            id="consignee.email"
            name="consignee.email"
            label="Correo"
            value={formik.values.consignee.email}
            error={touched?.email && errors?.email ? errors?.email : undefined}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="!h-8"
            labelClassname="!text-xs"
            labelContainerClassname="flex sm:!justify-end sm:w-32"
            containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
            style={{ maxWidth: '15rem' }}
          />

          <FormRadioGroup
            id="consignee.ShipmentType"
            name="consignee.ShipmentType"
            label="Tipo de retiro"
            selected={formik.values.consignee.ShipmentType}
            labelClassname="!text-xs flex sm:!justify-end sm:w-32"
            className="flex flex-1 sm:ml-0 sm:gap-5 sm:items-center flex-col sm:flex-row"
            wrapperClassName="flex !flex-row ml-4"
            optionsClassName="text-xs !ml-1"
            options={[
              { value: DeliveryEnum.OFFICE, name: 'Tienda' },
              { value: DeliveryEnum.DELIVERY, name: 'Domicilio' },
            ]}
            onSelectOption={(opt) => {
              formik.setFieldValue('consignee.ShipmentType', opt);
            }}
          />

          <div className="lex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row ">
            {formik.values.consignee?.ShipmentType?.toString() ===
              DeliveryEnum.DELIVERY?.toString() && (
              <div className="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row">
                <div className="flex sm:!justify-end sm:w-32 !text-xs block text-sm font-bold leading-6 text-gray-700 truncate !text-xs">
                  Ubicación
                </div>
                <div className="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row ml-2">
                  <AddressSearch
                    stores={stores}
                    selected={formik.values.shippingLocation}
                    region={AutocompleteRegion.TEALCA}
                    error={
                      formik.touched?.shippingLocation &&
                      formik.errors?.shippingLocation
                        ? formik.errors?.shippingLocation
                        : undefined
                    }
                    setSelectedLocation={(location, store) => {
                      formik.setFieldValue('shippingLocation', location);
                      updateBusinessUnitSearch(store);
                      formik.setFieldValue('consignee.businessUnit', store);
                      formik.setFieldValue(
                        'consignee.address',
                        location?.address ?? ''
                      );
                    }}
                  />
                </div>
              </div>
            )}

            {formik.values.consignee?.ShipmentType?.toString() ===
              DeliveryEnum.DELIVERY?.toString() && (
              <FormText
                value={formik.values.consignee?.reference ?? ''}
                id="consignee.reference"
                name="consignee.reference"
                label="Punto de Referencia"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="!h-8 mt-1"
                labelClassname="!text-xs"
                labelContainerClassname="flex sm:!justify-end sm:w-32"
                containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
                style={{ maxWidth: '15rem' }}
              />
            )}
          </div>

          <FormSearch
            id="consignee.businessUnit"
            name="consignee.businessUnit"
            label={titleShipment}
            placeholder="Ingresa la tienda"
            value={businessUnitSearch}
            options={businessUnits}
            unselect={!!(businessUnitSearch.length > 0 ? '' : undefined)}
            className="!h-8"
            disabled={
              formik.values.consignee?.ShipmentType?.toString() ===
              DeliveryEnum.DELIVERY?.toString()
                ? true
                : false
            }
            labelClassname="!text-xs"
            labelContainerClassname="flex sm:!justify-end sm:w-32"
            containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
            error={
              touched?.businessUnit && errors?.businessUnit
                ? errors?.businessUnit
                : undefined
            }
            onBlur={formik.handleBlur}
            onChange={(e) => setBusinessUnitSearch(e.target.value)}
            onSelectOption={(option) => {
              updateBusinessUnitSearch(option);
              formik.setFieldValue('consignee.businessUnit', option);
            }}
            onFilter={filterBusinessUnit}
            style={{ maxWidth: '20rem' }}
            RenderOption={({ option }) => <BusinessUnitOption {...option} />}
          />

          <FormSearch
            id="consignee.city"
            name="consignee.city"
            label="Ciudad Destino"
            value={citySearch}
            disabled={true}
            options={cities}
            unselect={!!formik.values.consignee.city}
            className="!h-8"
            labelClassname="!text-xs"
            labelContainerClassname="flex sm:!justify-end sm:w-32"
            containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
            error={touched?.city && errors?.city ? errors?.city : undefined}
            onBlur={formik.handleBlur}
            onChange={(e) => setCitySearch(e.target.value)}
            onChangeFocus={(focus) => !focus && updateCitySearch()}
            onSelectOption={(option) => {
              formik.setFieldValue('consignee.cityCode', option?.locationCode);
              formik.setFieldValue('consignee.city', option?.locationName);
            }}
            onFilter={filterCity}
            style={{ maxWidth: '20rem' }}
            RenderOption={({ option }) => <CityOption {...option} />}
          />
          <FormTextArea
            id="consignee.address"
            name="consignee.address"
            label="Dirección"
            value={formik.values.consignee.address}
            error={
              touched?.address && errors?.address ? errors?.address : undefined
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            labelClassname="!text-xs"
            labelContainerClassname="flex sm:!justify-end sm:w-32 sm:mt-2"
            containerClassname="flex flex-1 sm:ml-0 sm:gap-8 flex-col sm:flex-row"
            disabled={true}
          />
          <ModalLoading
            open={loading}
            title={loadingTitle}
            status={loadingStatus}
            setOpen={setLoading}
            onPrint={() => {}}
          />
        </div>
      ) : (
        <div /* Este es el div 2 */ className="red2" />
      )}
    </div>
  );
};
