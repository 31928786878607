import moment from "moment";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { utils, writeFile } from "xlsx";
import logo from "../assets/images/TEALCA-logo.jpeg";
import {
  UserInterface,
  ShipmentInterface,
  DestinationInterface,
  DispatcherCCWInterface,
  FavoriteDestinationsInterfase,
} from "../interfaces";

export const exportDestinationsPDF = (
  user: UserInterface,
  destinations: DestinationInterface[]
) => {
  const doc = new jsPDF();

  // Logo
  let img = new Image();
  img.src = logo;
  doc.addImage(img, "JPEG", 13, 13, 25, 20);

  // Title
  doc.setFontSize(16).setFont("helvetica", "bold");
  doc.text("REPORTE DE", 87, 20);
  doc.text("DESTINATARIO", 84, 26);

  // Date
  const date = moment();
  doc.setFontSize(10).setFont("helvetica", "normal");
  doc.text(date.format("DD/MM/YYYY"), 178, 20);
  doc.text(date.format("HH:mm"), 186.5, 24);

  // Destination table
  const columns = [
    "Código destinatario",
    "Alias",
    "Ciudad",
    "Teléfono",
    "Tienda",
    "Persona de accounto",
  ];
  const rows = destinations.map((destination) => [
    destination.location.code ?? "",
    destination.location.name,
    "CCS",
    destination.account.listAccountPhone[0]?.phoneNumber ?? "",
    destination.businessUnit?.buCode,
    destination.account.accountFullName,
  ]);
  autoTable(doc, {
    head: [columns],
    body: rows,
    margin: { top: 40, bottom: 30 },
    styles: { halign: "center", valign: "middle" },
    headStyles: {
      fillColor: [0, 100, 172],
      textColor: 255,
      fontStyle: "bold",
    },
    bodyStyles: {
      minCellHeight: 16,
      lineWidth: 0.1,
      textColor: "black",
    },
    didDrawPage: (data) => {
      if (data.pageCount > 0) {
        data.settings.margin.top = 15;
        doc.setPage(data.pageCount);
      }
    },
  });

  // Footer
  for (let i = 1; i <= doc.getNumberOfPages(); i++) {
    doc.setPage(i);
    doc.text(`Solicitado por: ${user.login}`, 13, 280);
    doc.text(`${i}`, 195, 280);
  }

  doc.save(`ConsultaDestinatario_${moment().format("DDMMYYYYHHmm")}.pdf`);
};

export const exportDestinationsXLSX = (
  destinations: DestinationInterface[]
) => {
  // New sheet book
  const wb = utils.book_new();

  // Destinations data
  const ws_data = destinations.map((destination) => [
    destination.location.code,
    destination.location.name,
    "CCS",
    destination.account.listAccountPhone[0]?.phoneNumber ?? "",
    destination.businessUnit?.buCode,
    destination.account.accountFullName,
  ]);

  // Add header
  ws_data.unshift([
    "Código destinatario",
    "Alias",
    "Ciudad",
    "Teléfono",
    "Tienda",
    "Persona de accounto",
  ]);

  const ws = utils.aoa_to_sheet(ws_data);

  utils.book_append_sheet(wb, ws, "Destinations");

  // Export
  writeFile(wb, `ConsultaDestinatario_${moment().format("DDMMYYYYHHmm")}.xlsx`);
};

export const exportDestinationsTXT = (destinations: DestinationInterface[]) => {
  let txt = "";

  for (let i = 0; i < destinations.length; i++) {
    const destination = destinations[i];
    const identificationType = destination.account.abbreviationName;

    txt += `"DestinationId":"${destination.id}",\n`;
    txt += `"DestinationCod":"${destination.location.code}",\n`;
    txt += `"Description":"${destination.location.name}",\n`;
    txt += `"Alias":"${destination.location.name}",\n`;
    txt += `"AddressFull":"${destination.location.address}",\n`;
    txt += `"City":"CCS",\n`;
    txt += `"Country":"VZL",\n`;
    txt += `"Phone":"${destination.account.listAccountPhone[0]?.phoneNumber}",\n`;
    txt += `"Phone2":"${destination.account.listAccountPhone[1]?.phoneNumber}",\n`;
    txt += `"Email":"${destination.account.listAccountEmail[0]?.email}",\n`;
    txt += `"OfficeCod":"",\n`;
    txt += `"NameContact":"${destination.account.accountFullName}",\n`;
    txt += `"DestinationDocument":"${identificationType}${destination.account.identificationNumber}",\n`;
    txt += `"DocumentType":"${identificationType.slice(0, 1)}",\n`;
    txt += `"NameContact":"${destination.account.identificationNumber}"`;
    txt += i < destinations.length - 1 ? ",\n\n" : "\n\n\n";
  }

  let element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(txt)
  );
  element.setAttribute(
    "download",
    `ConsultaDestinatario_${moment().format("DDMMYYYYHHmm")}.txt`
  );

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

//////////Export Shipments

export const exportShipmentsPDF = (
  user: UserInterface,
  shipments: ShipmentInterface[]
) => {
  const doc = new jsPDF();

  // Logo
  let img = new Image();
  img.src = logo;
  doc.addImage(img, "JPEG", 13, 13, 25, 20);

  // Title
  doc.setFontSize(16).setFont("helvetica", "bold");
  doc.text("REPORTE DE ENVIO", 80, 25);
  // Date
  const date = moment();
  doc.setFontSize(10).setFont("helvetica", "normal");
  doc.text(date.format("DD/MM/YYYY"), 178, 20);
  doc.text(date.format("HH:mm"), 186.5, 24);

  // Destination table
  const columns = [
    " Guía ",
    "Piezas",
    "Peso Kg",
    "Alias",
    "Teléfono",
    "Fecha de Creación",
  ];
  const rows = shipments.map((shipment) => [
    shipment.number,
    shipment.totalPieces ?? 0,
    shipment.totalWeight ?? 0,
    shipment.consignee.accountFullName,
    shipment.consignee.listAccountPhone[0]?.phoneNumber ?? "",
    moment(shipment.date).format("DD/MM/YYYY h:mm:ss A"),
  ]);
  autoTable(doc, {
    head: [columns],
    body: rows,
    margin: { top: 45, bottom: 30 },
    styles: { halign: "center", valign: "middle" },
    headStyles: {
      fillColor: [0, 100, 172],
      textColor: 255,
      fontStyle: "bold",
      minCellHeight: 11,
    },
    bodyStyles: {
      minCellHeight: 16,
      lineWidth: 0.1,
      textColor: "black",
    },
    didDrawPage: (data) => {
      if (data.pageCount > 0) {
        data.settings.margin.top = 15;
        doc.setPage(data.pageCount);
      }
    },
  });

  // Footer
  for (let i = 1; i <= doc.getNumberOfPages(); i++) {
    doc.setPage(i);
    doc.text(`Solicitado por: ${user.login}`, 13, 280);
    doc.text(`${i}`, 195, 280);
  }

  doc.save(`ConsultaDestinatario_${moment().format("DDMMYYYYHHmm")}.pdf`);
};

export const exportShipmentsXLSX = (shipments: ShipmentInterface[]) => {
  // New sheet book
  const wb = utils.book_new();

  // Destinations data
  const ws_data = shipments.map((shipment) => [
    shipment.number,
    shipment.totalPieces ?? 0,
    shipment.totalWeight ?? 0,
    shipment.consignee.accountFullName,
    shipment.consignee.listAccountPhone[0]?.phoneNumber ?? "",
    moment(shipment.date).format("DD/MM/YYYY h:mm:ss A"),
  ]);

  // Add header
  ws_data.unshift([
    "Guía",
    "Piezas",
    "Peso Kg",
    "Alias",
    "Teléfono",
    "Fecha de Creación",
  ]);

  const ws = utils.aoa_to_sheet(ws_data);

  utils.book_append_sheet(wb, ws, "Destinations");

  // Export
  writeFile(wb, `ConsultaDestinatario_${moment().format("DDMMYYYYHHmm")}.xlsx`);
};

export const exportShipmentsTXT = (shipments: ShipmentInterface[]) => {
  let txt = "";

  for (let i = 0; i < shipments.length; i++) {
    const shipment = shipments[i];

    txt += `"GUÍA":"${shipment.number}",\n`;
    txt += `"Piezas":${shipment.totalPieces},\n`;
    txt += `"PesoKg":${shipment.totalWeight},\n`;
    txt += `"Estatus":"${shipment.status}",\n`;
    txt += `"Origen":"${shipment.businessUnitOrigin.buCode}",\n`;
    txt += `"Servicio":"${shipment.service}",\n`;
    txt += `"Observaciones":"${shipment.observations}"`;
    txt += i < shipments.length - 1 ? ",\n\n" : "\n\n\n";
  }

  let element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(txt)
  );
  element.setAttribute(
    "download",
    `ConsultaDestinatario_${moment().format("DDMMYYYYHHmm")}.txt`
  );

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

///////////// Export Dispatch
export const exportDispatchPDF = (
  user: UserInterface,
  dispatchs: DispatcherCCWInterface[]
) => {
  const doc = new jsPDF();

  // Logo
  let img = new Image();
  img.src = logo;
  doc.addImage(img, "JPEG", 13, 13, 25, 20);

  // Title
  doc.setFontSize(16).setFont("helvetica", "bold");
  doc.text("REPORTE DE DESPACHO", 80, 25);
  // Date
  const date = moment();
  doc.setFontSize(10).setFont("helvetica", "normal");
  doc.text(date.format("DD/MM/YYYY"), 178, 20);
  doc.text(date.format("HH:mm"), 186.5, 24);

  // Destination table
  const columns = [
    "Codigo ",
    "Origen",
    "Destino",
    "Transporte",
    "Conductor",
    "Precinto 1",
    "Precinto 2",
    "Precinto 3",
  ];
  const rows = dispatchs.map((dispatch) => [
    dispatch.masterShipmentHeaderCode,
    dispatch.buCodeSource,
    dispatch.buCodeDestination,
    dispatch.transport,
    dispatch.brandName,
    dispatch.seal1 ?? "N/D",
    dispatch.seal2 ?? "N/D",
    dispatch.seal3 ?? "N/D",
  ]);
  autoTable(doc, {
    head: [columns],
    body: rows,
    margin: { top: 45, bottom: 30 },
    styles: { halign: "center", valign: "middle" },
    headStyles: {
      fillColor: [0, 100, 172],
      textColor: 255,
      fontStyle: "bold",
      minCellHeight: 11,
    },
    bodyStyles: {
      minCellHeight: 16,
      lineWidth: 0.1,
      textColor: "black",
    },
    didDrawPage: (data) => {
      if (data.pageCount > 0) {
        data.settings.margin.top = 15;
        doc.setPage(data.pageCount);
      }
    },
  });

  // Footer
  for (let i = 1; i <= doc.getNumberOfPages(); i++) {
    doc.setPage(i);
    doc.text(`Solicitado por: ${user.login}`, 13, 280);
    doc.text(`${i}`, 195, 280);
  }

  doc.save(`ConsultaDespacho_${moment().format("DDMMYYYYHHmm")}.pdf`);
};

export const exportDispatchXLSX = (dispatchs: DispatcherCCWInterface[]) => {
  // New sheet book
  const wb = utils.book_new();

  // Destinations data
  const ws_data = dispatchs.map((dispatch) => [
    dispatch.masterShipmentHeaderCode,
    dispatch.buCodeSource,
    dispatch.buCodeDestination,
    dispatch.transport,
    dispatch.brandName,
    dispatch.seal1 ?? "N/D",
    dispatch.seal2 ?? "N/D",
    dispatch.seal3 ?? "N/D",
  ]);

  // Add header
  ws_data.unshift([
    "Codigo ",
    "Origen",
    "Destino",
    "Transporte",
    "Conductor",
    "Precinto 1",
    "Precinto 2",
    "Precinto 3",
  ]);

  const ws = utils.aoa_to_sheet(ws_data);

  utils.book_append_sheet(wb, ws, "Destinations");

  // Export
  writeFile(wb, `ConsultaDespacho_${moment().format("DDMMYYYYHHmm")}.xlsx`);
};

export const exportDispatchTXT = (dispatchs: DispatcherCCWInterface[]) => {
  let txt = "";

  for (let i = 0; i < dispatchs.length; i++) {
    const shipment = dispatchs[i];

    txt += `"Codigo":"${shipment.masterShipmentHeaderCode}",\n`;
    txt += `"Origen":${shipment.buCodeSource},\n`;
    txt += `"Destino":${shipment.buCodeDestination},\n`;
    txt += `"Transporte":"${shipment.transport}",\n`;
    txt += `"Conductor":"${shipment.brandName}",\n`;
    txt += `"Precinto 1":"${shipment.seal1 ?? "N/D"}",\n`;
    txt += `"Precinto 2":"${shipment.seal2 ?? "N/D"}",\n`;
    txt += `"Precinto 3":"${shipment.seal3 ?? "N/D"}"`;
    txt += i < dispatchs.length - 1 ? ",\n\n" : "\n\n\n";
  }

  let element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(txt)
  );
  element.setAttribute(
    "download",
    `ConsultaDestinatario_${moment().format("DDMMYYYYHHmm")}.txt`
  );

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

export const exportFormatoShipmentXLSX = () => {
  // New sheet book
  const wb = utils.book_new();

  // Destinations data
  const ws_data = [];

  // Add header
  ws_data.unshift([
    "Fecha de Creación",
    "Numero de Guía / Tracking aliado",
    "Tipo de retiro",
    "Ciudad Destino",
    "Codigo Tienda destinatario",
    "Tipo de Documento Remitente",
    "Nº de Documento Remitente",
    "Nombre Remitente",
    "Tipo de Documento destinatario",
    "Nº de Documento destinatario",
    "Nombre destinatario",
    "Telefono destinatario",
    "Correo destinatario",
    "Dirección Destino",
    "Codigo Postal",
    "Observaciones",
    "Nº Pieza",
    "Peso en Kg de la pieza",
    "Dimension Alto de la pieza",
    "Dimension Anchode la pieza",
    "Dimension Largo de la pieza",
    "Valor declarado de la pieza",
    "Categoria de la pieza",
  ]);

  const ws = utils.aoa_to_sheet(ws_data);

  utils.book_append_sheet(wb, ws, "FormatoMasivo");

  // Export
  writeFile(wb, `FormatoCargaMasiva_${moment().format("DDMMYYYYHHmm")}.xlsx`);
};

//////////////// Export Favorite Destinations
export const exportFavDestinationTXT = (favDestinations: FavoriteDestinationsInterfase[]) => {
  let txt = "";

  for (let i = 0; i < favDestinations.length; i++) {
    const fav = favDestinations[i];

    txt += `"Nombre":"${fav.accountFullName}",\n`;
    txt += `"Numero Identificador":${fav.taxIDentificationTypeCode+fav.identificationNumber},\n`;
    txt += `"Teléfono":${fav.phoneNumber},\n`;
    txt += `"Email":"${fav.email}",\n`;
    txt += `"Dirección":"${fav.addressLine1}",\n`;
    txt += i < favDestinations.length - 1 ? ",\n\n" : "\n\n\n";
  }

  let element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(txt)
  );
  element.setAttribute(
    "download",
    `ConsultaDestinosFavoritos_${moment().format("DDMMYYYYHHmm")}.txt`
  );

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

export const exportFavDestinationXLSX = (favDestinations: FavoriteDestinationsInterfase[]) => {
  // New sheet book
  const wb = utils.book_new();

  // Destinations data
  const ws_data = favDestinations.map((fav) => [
    fav.accountFullName,
    fav.taxIDentificationTypeCode+fav.identificationNumber,
    fav.phoneNumber,
    fav.email,
    fav.addressLine1,
  ]);

  // Add header
  ws_data.unshift([
    "Nombre ",
    "Numero Identificador",
    "Teléfono",
    "Email",
    "Dirección"
  ]);

  const ws = utils.aoa_to_sheet(ws_data);

  utils.book_append_sheet(wb, ws, "Destinations");

  // Export
  writeFile(wb, `ConsultaDespacho_${moment().format("DDMMYYYYHHmm")}.xlsx`);
};

export const exportFavDestinationPDF = (
  user: UserInterface,
  favDestinations: FavoriteDestinationsInterfase[]
) => {
  const doc = new jsPDF();

  // Logo
  let img = new Image();
  img.src = logo;
  doc.addImage(img, "JPEG", 13, 13, 25, 20);

  // Title
  doc.setFontSize(16).setFont("helvetica", "bold");
  doc.text("REPORTE DE DESTINOS FAVORITOS", 80, 25);
  // Date
  const date = moment();
  doc.setFontSize(10).setFont("helvetica", "normal");
  doc.text(date.format("DD/MM/YYYY"), 178, 20);
  doc.text(date.format("HH:mm"), 186.5, 24);

  // Destination table
  const columns = [
    "Nombre ",
    "Numero Identificador",
    "Teléfono",
    "Email",
    "Dirección",
  ];
  const rows = favDestinations.map((fav) => [
    fav.accountFullName,
    fav.taxIDentificationTypeCode+fav.identificationNumber,
    fav.phoneNumber,
    fav.email,
    fav.addressLine1,
  ]);
  autoTable(doc, {
    head: [columns],
    body: rows,
    margin: { top: 45, bottom: 30 },
    styles: { halign: "center", valign: "middle" },
    headStyles: {
      fillColor: [0, 100, 172],
      textColor: 255,
      fontStyle: "bold",
      minCellHeight: 11,
    },
    bodyStyles: {
      minCellHeight: 16,
      lineWidth: 0.1,
      textColor: "black",
    },
    didDrawPage: (data) => {
      if (data.pageCount > 0) {
        data.settings.margin.top = 15;
        doc.setPage(data.pageCount);
      }
    },
  });

  // Footer
  for (let i = 1; i <= doc.getNumberOfPages(); i++) {
    doc.setPage(i);
    doc.text(`Solicitado por: ${user.login}`, 13, 280);
    doc.text(`${i}`, 195, 280);
  }

  doc.save(`ConsultaDestinosFavoritos_${moment().format("DDMMYYYYHHmm")}.pdf`);
};
