import { FC, useEffect, useRef, useState } from "react";
import { Modal, PrimaryButton } from "..";
import { logout, setTimer } from "../../store/slices";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

const LOGOUT_TIME = 120 * 10; // 10 minutes;

export const LogoutTimer: FC = () => {
  const dispatch = useAppDispatch();
  const timer = useAppSelector((state) => state.user.timer);
  const logged = useAppSelector((state) => state.user.logged);

  const timerRef = useRef(timer);
  const [remainingTime, setRemainingTime] = useState(LOGOUT_TIME);
  const [openLogoutModal, setOpenLogoutModal] = useState(false);

  useEffect(() => {
    if (!logged) return;

    const intervalId = setInterval(() => {
      setRemainingTime(
        LOGOUT_TIME - (new Date().getTime() / 1000 - timerRef.current)
      );
    }, 1000);

    return () => clearInterval(intervalId);
  }, [logged, timerRef, dispatch]);

  useEffect(() => {
    if (remainingTime > 0 && remainingTime <= 120 && !openLogoutModal) {
      setOpenLogoutModal(true);
    } else if (remainingTime <= 0) {
      setOpenLogoutModal(false);
      dispatch(logout());
    }
    // Don't add openLogoutModal to the dependencies array because it will cause an
    // infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remainingTime, dispatch]);

  useEffect(() => {
    timerRef.current = timer;
  }, [timer]);

  const handleCloseModal = () => {
    const currentTime = new Date().getTime() / 1000;

    timerRef.current = currentTime;
    setOpenLogoutModal(false);
    dispatch(setTimer(currentTime));
  };

  return (
    <Modal
      open={openLogoutModal}
      setOpen={() => {}}
      className="flex flex-1 flex-col"
      style={{ maxWidth: "28rem" }}
    >
      <div className="flex flex-1 gap-6 items-center">
        <div className="w-10 h-10">
          <InformationCircleIcon className="w-10 h-10 text-main-500" />
        </div>
        <span>
          La sesión finalizará en <b>{remainingTime.toFixed(0)} segundos</b>.
          Presione continuar para extender el tiempo de la sesión.
        </span>
      </div>

      <hr className="w-full my-4" />

      <div className="flex flex-1 w-full justify-end">
        <PrimaryButton onClick={handleCloseModal}>Continuar</PrimaryButton>
      </div>
    </Modal>
  );
};
