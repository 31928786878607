import { PrimaryButton } from "../Atoms/Buttons";
import { FormText } from "../FormFields/FormText";
import { FormCheckbox } from "../FormFields/FormCheckbox";
import { FormTextArea } from "../FormFields/FormTextArea";
import { FormRadioGroup } from "../FormFields/FormRadioGroup";
import { FormikErrors, FormikProps, FormikTouched } from "formik";
import { handleNumberChange } from "../../utils/format";
import classNames from "classnames";

export enum UnitEnum {
  KG = "Peso",
  CM3 = "Volumen",
  FT3 = "Pies cúbicos",
}
export interface ShipmentFormValues {
  service: string;
  tracking: string;
  pieces: string;
  unit: UnitEnum;
  weight: string;
  volume: {
    length: string;
    width: string;
    height: string;
  };
  amountDeclared: string;
  amountDeclaredByInvoice: string;
  isSafeKeeping: boolean;
  observations: string;
}
interface PreShipmentField {
  shipment: ShipmentFormValues;
}
interface ShipmentFormProps<T> {
  formik: FormikProps<T & PreShipmentField>;
}
export const ShipmentForm = <T extends any>({ formik }: ShipmentFormProps<T>) => {
  const errors = formik.errors.shipment as FormikErrors<ShipmentFormValues> | undefined;
  const touched = formik.touched.shipment as
    | FormikTouched<ShipmentFormValues>
    | undefined;

  return (
    <div className="flex flex-1 flex-col gap-12">
      <div className="flex flex-1 flex-col gap-4">
        <FormText
          disabled
          id="shipment.service"
          name="shipment.service"
          label="Servicio"
          value={formik.values.shipment.service}
          error={touched?.service && errors?.service ? errors?.service : undefined}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          className="!h-8"
          labelClassname="!text-xs"
          labelContainerClassname="flex sm:!justify-end sm:w-32"
          containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
          style={{ maxWidth: "10rem" }}
        />

        <FormText
          id="shipment.tracking"
          name="shipment.tracking"
          label="N° de Tracking/Precinto"
          value={formik.values.shipment.tracking}
          error={touched?.tracking && errors?.tracking ? errors?.tracking : undefined}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          className="!h-8"
          labelClassname="!text-xs"
          labelContainerClassname="flex sm:!justify-end sm:w-32"
          containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
          style={{ maxWidth: "10rem" }}
        />

        <FormText
          id="shipment.pieces"
          name="shipment.pieces"
          label="N° de Piezas"
          value={formik.values.shipment.pieces}
          error={touched?.pieces && errors?.pieces ? errors?.pieces : undefined}
          onChange={(e) => handleNumberChange(e, formik, false)}
          onBlur={formik.handleBlur}
          className="!h-8"
          labelClassname="!text-xs"
          labelContainerClassname="flex sm:!justify-end sm:w-32"
          containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
          style={{ maxWidth: "10rem" }}
        />

        <FormRadioGroup
          id="shipment.unit"
          name="shipment.unit"
          selected={formik.values.shipment.unit}
          labelClassname="!text-xs flex sm:!justify-end sm:w-32"
          className="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
          wrapperClassName="flex !flex-row"
          optionsClassName="text-xs !ml-1.5"
          options={Object.values(UnitEnum).map((method) => ({
            name: method,
            value: method,
          }))}
          onSelectOption={(opt) => formik.setFieldValue("shipment.unit", opt)}
        />

        <FormText
          id="shipment.weight"
          name="shipment.weight"
          label="Peso"
          value={formik.values.shipment.weight}
          error={touched?.weight && errors?.weight ? errors?.weight : undefined}
          onChange={(e) => handleNumberChange(e, formik, true)}
          onBlur={formik.handleBlur}
          className="!h-8"
          labelClassname="!text-xs"
          labelContainerClassname="flex sm:!justify-end sm:w-32"
          containerClassname={classNames(
            "flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row",
            formik.values.shipment.unit !== UnitEnum.KG && "hidden"
          )}
          style={{ maxWidth: "10rem" }}
        />

        <div
          className={classNames(
            "flex flex-1 flex-col sm:flex-row sm:items-center sm:gap-8 gap-2",
            formik.values.shipment.unit == UnitEnum.KG && "hidden"
          )}
        >
          <span className="flex sm:!justify-end sm:w-32 text-xs font-bold text-gray-700">
            Volumen
          </span>

          <div className="flex flex-1 flex-col sm:flex-row gap-4">
            <FormText
              id="shipment.volume.width"
              name="shipment.volume.width"
              label="Ancho"
              value={formik.values.shipment.volume.width}
              error={
                touched?.volume?.width && errors?.volume?.width
                  ? errors?.volume.width
                  : undefined
              }
              onChange={(e) => handleNumberChange(e, formik, false)}
              onBlur={formik.handleBlur}
              className="!h-8 w-40 sm:w-24"
              labelClassname="!text-xs"
            />

            <FormText
              id="shipment.volume.height"
              name="shipment.volume.height"
              label="Alto"
              value={formik.values.shipment.volume.height}
              error={
                touched?.volume?.height && errors?.volume?.height
                  ? errors?.volume.height
                  : undefined
              }
              onChange={(e) => handleNumberChange(e, formik, false)}
              onBlur={formik.handleBlur}
              className="!h-8 w-40 sm:w-24"
              labelClassname="!text-xs"
            />

            <FormText
              id="shipment.volume.length"
              name="shipment.volume.length"
              label="Largo"
              value={formik.values.shipment.volume.length}
              error={
                touched?.volume?.length && errors?.volume?.length
                  ? errors?.volume.length
                  : undefined
              }
              onChange={(e) => handleNumberChange(e, formik, false)}
              onBlur={formik.handleBlur}
              className="!h-8 w-40 sm:w-24"
              labelClassname="!text-xs"
            />
          </div>
        </div>

        <div className="flex flex-1 flex-col sm:flex-row sm:items-center gap-6">
          <FormCheckbox
            id="shipment.isSafeKeeping"
            name="shipment.isSafeKeeping"
            label="¿Viaja asegurado?"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            checked={formik.values.shipment.isSafeKeeping}
            labelClassname="!text-xs !ml-0"
            labelContainerClassname="flex sm:!justify-end sm:w-32"
            containerClassname="justify-end flex gap-8 items-center !flex-row-reverse"
          />

          <div
            className={classNames(
              "flex flex-1 flex-col sm:flex-row gap-4",
              !formik.values.shipment.isSafeKeeping && "hidden"
            )}
          >
            <FormText
              id="shipment.amountDeclared"
              name="shipment.amountDeclared"
              label="Monto declarado"
              value={formik.values.shipment.amountDeclared}
              error={
                touched?.volume?.width && errors?.volume?.width
                  ? errors?.amountDeclared
                  : undefined
              }
              onChange={(e) => handleNumberChange(e, formik, true)}
              onBlur={formik.handleBlur}
              className="!h-8"
              labelClassname="!text-xs"
              style={{ maxWidth: "10rem" }}
            />

            <FormText
              id="shipment.amountDeclaredByInvoice"
              name="shipment.amountDeclaredByInvoice"
              label="Según Factura"
              value={formik.values.shipment.amountDeclaredByInvoice}
              error={
                touched?.amountDeclaredByInvoice && errors?.amountDeclaredByInvoice
                  ? errors?.amountDeclaredByInvoice
                  : undefined
              }
              onChange={(e) => handleNumberChange(e, formik, true)}
              onBlur={formik.handleBlur}
              className="!h-8"
              labelClassname="!text-xs"
              style={{ maxWidth: "10rem" }}
            />
          </div>
        </div>

        <FormTextArea
          id="shipment.observations"
          name="shipment.observations"
          label="Observaciones"
          value={formik.values.shipment.observations}
          error={
            touched?.observations && errors?.observations
              ? errors?.observations
              : undefined
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          labelClassname="!text-xs"
          labelContainerClassname="flex sm:!justify-end sm:w-32 sm:mt-2"
          containerClassname="flex flex-1 sm:ml-0 sm:gap-8 flex-col sm:flex-row"
          style={{ maxWidth: "30rem" }}
        />
      </div>

      <div className="flex flex-1 justify-center">
        <PrimaryButton className="px-10 w-full sm:w-32" type="submit">
          Generar
        </PrimaryButton>
      </div>
    </div>
  );
};
