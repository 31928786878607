import { FC, useState } from "react";
import { Formik } from "formik";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";
import { BusinessUnitInterface } from "../../interfaces";
import { LoadPODForm } from "../../components/POD/LoadPODForm";
import { DocumentTextIcon, QrCodeIcon } from "@heroicons/react/24/outline";
import {
  FormText,
  MainPage,
  PageTitle,
  FormSelect,
  PrimaryButton,
  HorizontalTab,
} from "../../components";

const LOAD_POD_TABS = [
  {
    name: "Por guía",
    icon: <DocumentTextIcon className="w-5 h-5" />,
  },
  {
    name: "Por código de barras",
    icon: <QrCodeIcon className="w-5 h-5" />,
  },
];

const LoadPOD: FC = () => {
  const location = useLocation();
  const businessUnits = useAppSelector((state) => state.inmutable.businessUnits);

  const [tab, setTab] = useState(0);

  return (
    <MainPage>
      <PageTitle title="Cargar POD" back={location.state?.back} />

      <HorizontalTab current={tab} tabs={LOAD_POD_TABS} onChange={setTab} />

      <div className="flex flex-1 justify-center mt-6">
        <div className="w-full" style={{ maxWidth: "30rem" }}>
          <Formik
            initialValues={{
              date: undefined as Date | undefined,
              identificationNumber: "",
              client: "",
              barCode: "",
              shipmentId: "",
              businessUnit: undefined as BusinessUnitInterface | undefined,
            }}
            onSubmit={(values, { resetForm }) => {
              resetForm();
            }}
          >
            {(formik) => (
              <div className="flex flex-col gap-4">
                <div>
                  <LoadPODForm formik={formik} />
                </div>

                <div className="flex flex-col gap-4">
                  {tab == 0 && (
                    <>
                      <FormText
                        id="shipmentId"
                        name="shipmentId"
                        label="Guía"
                        value={formik.values.shipmentId}
                        error={
                          formik.touched?.shipmentId && formik.errors?.shipmentId
                            ? formik.errors?.shipmentId
                            : undefined
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="!h-8"
                        labelClassname="!text-xs"
                        labelContainerClassname="flex sm:!justify-end sm:w-32"
                        containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
                        style={{ maxWidth: "15rem" }}
                      />

                      <FormSelect
                        id="businessUnit"
                        name="businessUnit"
                        label="Retiro en Oficina"
                        selected={formik.values.businessUnit}
                        options={businessUnits}
                        error={
                          formik.touched?.businessUnit && formik.errors?.businessUnit
                            ? formik.errors?.businessUnit
                            : undefined
                        }
                        onBlur={formik.handleBlur}
                        className="!h-8"
                        labelClassname="!text-xs"
                        labelContainerClassname="flex sm:!justify-end sm:w-32"
                        containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
                        style={{ maxWidth: "20rem" }}
                        optionString={(option) =>
                          option.buCode + " - " + option.location.name
                        }
                        onSelectOption={(option) =>
                          formik.setFieldValue("businessUnit", option)
                        }
                      />
                    </>
                  )}

                  {tab == 1 && (
                    <>
                      <FormText
                        id="barCode"
                        name="barCode"
                        label="Código de barras"
                        value={formik.values.barCode}
                        error={
                          formik.touched?.barCode && formik.errors?.barCode
                            ? formik.errors?.barCode
                            : undefined
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="!h-8"
                        labelClassname="!text-xs"
                        labelContainerClassname="flex sm:!justify-end sm:w-32"
                        containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
                        style={{ maxWidth: "15rem" }}
                      />
                    </>
                  )}

                  <div className="flex w-full justify-center">
                    <PrimaryButton className="mt-4 w-full sm:w-32">Generar</PrimaryButton>
                  </div>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </MainPage>
  );
};

export default LoadPOD;
