import { FC, useState } from 'react';
import classNames from 'classnames';
import { Popover } from '../Atoms/Popover';
import { FavoriteDestinationsInterfase } from '../../interfaces';

import {
  ChevronDownIcon,
  EllipsisVerticalIcon,
  PencilIcon,
} from '@heroicons/react/24/outline';
import { v4 } from 'uuid';
import moment from 'moment';
import { NavLink } from 'react-router-dom';

export enum FavoriteField {
  NAME,
  IDENTIFICATION,
  PHONE,
  EMAIL,
  TYPESEND,
  ADDRESS,
  DATE,
}

const TableOptionsButton = (
  <div className="flex w-8 h-8 items-center justify-center">
    <EllipsisVerticalIcon className="w-5 h-5" />
  </div>
);

interface FavoriteDestinationTableProps {
  favoriteDestination: FavoriteDestinationsInterfase[];
  sortBy: { field: FavoriteField; asc: boolean };
  onChangeSortBy: (field: FavoriteField) => void;
}
export const FavoriteDestinationTable: FC<FavoriteDestinationTableProps> = ({
  favoriteDestination,
  sortBy,
  onChangeSortBy,
}) => {
  const [selected, setSelected] = useState<FavoriteDestinationsInterfase>();

  return (
    <div className="relative w-full table-fix-head mt-4 overflow-auto">
      <table className="table-auto w-full text-gray-600 text-xs text-left border border-gray-300">
        <thead className="select-none font-semibold border-b border-gray-300 bg-gray-50">
          <tr>
            <th
              className="px-2 py-2"
              onClick={() => onChangeSortBy(FavoriteField.NAME)}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>Nombre</span>
                <div className="h-4 w-4">
                  {sortBy.field === FavoriteField.NAME && (
                    <ChevronDownIcon
                      className={classNames(
                        'w-4 h-4',
                        sortBy.asc && 'rotate-180'
                      )}
                    />
                  )}
                </div>
              </div>
            </th>
            <th
              className="px-2 py-2"
              onClick={() => onChangeSortBy(FavoriteField.IDENTIFICATION)}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>Identificacion</span>
                <div className="h-4 w-4">
                  {sortBy.field === FavoriteField.IDENTIFICATION && (
                    <ChevronDownIcon
                      className={classNames(
                        'w-4 h-4',
                        sortBy.asc && 'rotate-180'
                      )}
                    />
                  )}
                </div>
              </div>
            </th>

            <th
              className="px-2 py-2 truncate"
              onClick={() => onChangeSortBy(FavoriteField.PHONE)}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>Teléfono</span>
                <div className="h-4 w-4">
                  {sortBy.field === FavoriteField.PHONE && (
                    <ChevronDownIcon
                      className={classNames(
                        'w-4 h-4',
                        sortBy.asc && 'rotate-180'
                      )}
                    />
                  )}
                </div>
              </div>
            </th>

            <th
              className="px-2 py-2"
              onClick={() => onChangeSortBy(FavoriteField.EMAIL)}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>Email</span>
                <div className="h-4 w-4">
                  {sortBy.field === FavoriteField.EMAIL && (
                    <ChevronDownIcon
                      className={classNames(
                        'w-4 h-4',
                        sortBy.asc && 'rotate-180'
                      )}
                    />
                  )}
                </div>
              </div>
            </th>

            <th
              className="px-2 py-2"
              onClick={() => onChangeSortBy(FavoriteField.TYPESEND)}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>Tipo de envio</span>
                <div className="h-4 w-4">
                  {sortBy.field === FavoriteField.TYPESEND && (
                    <ChevronDownIcon
                      className={classNames(
                        'w-4 h-4',
                        sortBy.asc && 'rotate-180'
                      )}
                    />
                  )}
                </div>
              </div>
            </th>

            <th className="px-2 py-2">
              <div className="flex gap-2 items-center cursor-pointer">
                <span>Tienda</span>
              </div>
            </th>

            <th
              className="px-2 py-2"
              onClick={() => onChangeSortBy(FavoriteField.ADDRESS)}
            >
              <div className="flex gap-2 items-center cursor-pointer truncate">
                <span>Dirección</span>
                <div className="h-4 w-4">
                  {sortBy.field === FavoriteField.ADDRESS && (
                    <ChevronDownIcon
                      className={classNames(
                        'w-4 h-4',
                        sortBy.asc && 'rotate-180'
                      )}
                    />
                  )}
                </div>
              </div>
            </th>
            <th
              className="px-2 py-2"
              onClick={() => onChangeSortBy(FavoriteField.DATE)}
            >
              <div className="flex gap-2 items-center cursor-pointer truncate">
                <span>Fecha Creación</span>
                <div className="h-4 w-4">
                  {sortBy.field == FavoriteField.DATE && (
                    <ChevronDownIcon
                      className={classNames(
                        'w-4 h-4',
                        sortBy.asc && 'rotate-180'
                      )}
                    />
                  )}
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {favoriteDestination.map((favDestination, index) => {
            return (
              <tr
                key={v4() + index}
                className={classNames(
                  'hover:bg-gray-200',
                  index % 2 === 0 && 'bg-gray-100',
                  selected && '!bg-blue-200'
                )}
              >
                <td className="px-2 py-1 font-semibold">
                  {favDestination.accountFullName}
                </td>
                <td className="px-2 py-1">
                  {favDestination.taxIDentificationTypeCode +
                    favDestination.identificationNumber}
                </td>
                <td className="px-2 py-1">{favDestination.phoneNumber}</td>
                <td className="px-2 py-1">{favDestination.email}</td>
                <td className="px-2 py-1">
                  {favDestination.destinationAddressID != undefined
                    ? 'Domicilio'
                    : 'Tienda'}
                </td>
                <td className="px-2 py-1">{favDestination.buCodeConsignee}</td>
                <td className="px-2 py-1">
                  {favDestination.addressLine1?.substring(0, 30)}
                </td>
                <td className="px-2 py-1">
                  {moment(favDestination.creationDate).format('DD/MM/YYYY')}
                </td>
                <td className="pr-2">
                  <Popover
                    button={TableOptionsButton}
                    containerClassname="!-mt-8 ml-2 mr-5"
                  >
                    <div className="flex flex-col font-semibold cursor-pointer">
                      <div className="flex flex-1 flex-col text-sm text-gray-700 truncate hover:bg-main-500 hover:text-white">
                        <NavLink
                          to={`/favoriteDestination/create/${favDestination.favoriteDestinationID}`}
                        >
                          <div className="flex gap-2 py-2 px-4 w-32 items-center">
                            <PencilIcon className="w-5 h-5" />
                            <span>Editar</span>
                          </div>
                        </NavLink>
                      </div>
                    </div>
                  </Popover>
                </td>
              </tr>
            );
          })}

          {favoriteDestination.length === 0 && (
            <tr>
              <td colSpan={11} className="px-4 py-4 text-center">
                No hay despachos en esta tabla.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
