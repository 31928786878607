import { FC, useState } from "react";
import {
  ConfirmModalStatus,
  MainPage,
  ModalLoading,
  PageTitle,
  ResetPasswordForm,
  ResetPasswordFormValues,
} from "../../components";
import { useNavigate, useParams } from "react-router-dom";
import { changePassword } from "../../services";
import { useAppSelector } from "../../store/hooks";
export interface UserPasswordUpdateFormValues {
  loginOrEmail: string;
  oldPassword: string;
  newPassword: string;
  repeatPassword: string;
}

const ResetPassword: FC = () => {
  const navigate = useNavigate();
  const { userToken } = useParams();
  const { userLoginEmailToken } = useParams();
  const user = useAppSelector((state) => state.user);
  let [loading, setLoading] = useState(false);
  let [loadingStatus, setLoadingStatus] = useState(
    ConfirmModalStatus.PROCESSING
  );
  let [loadingTitle, setLoadingTitle] = useState("Cargando...");
  const userLogged = useAppSelector((state) => state.user.user);

  const updatePassword = async (values: ResetPasswordFormValues) => {
    setLoadingStatus(ConfirmModalStatus.PROCESSING);
    setLoadingTitle("Validando información...");
    setLoading(true);

    const response = await changePassword(
      {
        UserEmailOrLogin: user.user!.login ?? userLoginEmailToken,
        UserOldPassword: values.passwordOld,
        UserNewPassword: values.password,
        ApplicationCode: "020",
        UserTokenHash: !!userToken ? userToken : null,
      }
      //userLogged?.token ?? ""
    );

    if (!response || response.didError) {
      setLoadingStatus(ConfirmModalStatus.ERROR);
      setLoadingTitle("Error al cambiar la contraseña: " + response.message);
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    } else {
      setLoadingStatus(ConfirmModalStatus.SUCCESS);
      setLoadingTitle("Por favor inicie sesión con la nueva contraseña");
      navigate("/");
    }
  };

  return (
    <MainPage>
      <PageTitle title="Cambiar Contraseña" />

      <div>
        <ResetPasswordForm
          onSubmit={(e) => {
            updatePassword(e);
          }}
        />
        <ModalLoading
          open={loading}
          title={loadingTitle}
          status={loadingStatus}
          setOpen={setLoading}
          onPrint={() => {}}
        />
      </div>
    </MainPage>
  );
};

export default ResetPassword;
