import { FC, useState } from 'react';
import {
  Card,
  ConfirmModalStatus,
  FavoriteDestinationForm,
  MainPage,
  ModalLoading,
  PageTitle,
  PrimaryButton,
} from '../../components';
import { useAppSelector } from '../../store/hooks';
import {
  AccountInterface,
  BusinessUnitInterface,
  DeliveryEnum,
  FavoriteDestinationsSpDto,
} from '../../interfaces';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { ChevronLeftIcon } from '@heroicons/react/20/solid';
import { PaperAirplaneIcon } from '@heroicons/react/24/outline';
import { createFavoriteDestinationsApi } from '../../services/FavDestinationsServices';
import { NavLink, useParams, useNavigate } from 'react-router-dom';

const CreateFavoriteDestination: FC<{ id?: string }> = () => {
  let { id } = useParams<{ id?: string }>();

  const user = useAppSelector((state) => state.user)!;
  const identificationTypes = useAppSelector(
    (state) => state.inmutable.taxIdentificationTypes
  );

  const consigneeInitialValues = {
    abbreviationName: identificationTypes.find(
      (t) => t.abbreviationName === 'V-'
    )!,
    id: undefined,
    favoriteId: id,
    identificationNumber: '',
    fullName: '',
    codePhoneNumber: '',
    phoneId: undefined,
    phoneTypeId: '',
    phoneNumber: '',
    acceptsSMS: false,
    codeCellPhoneNumber: '',
    cellPhoneNumber: '',
    emailId: undefined,
    email: '',
    city: '',
    cityCode: '',
    postalCode: '',
    addressId: undefined,
    address: '',
    reference: '',
    reset: true,
    businessUnit: undefined as BusinessUnitInterface | undefined,
    accountSelected: undefined as AccountInterface | undefined,
    newAccount: undefined,
    ShipmentType: '10' as DeliveryEnum | undefined,
    locationId: undefined,
  };

  let [loading, setLoading] = useState(false);
  let [loadingStatus, setLoadingStatus] = useState(
    ConfirmModalStatus.PROCESSING
  );
  let [loadingTitle, setLoadingTitle] = useState('Cargando...');

  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    consignee: Yup.object().shape({
      fullName: Yup.string().required('Este campo es requerido'),
      abbreviationName: Yup.object().required('Este campo es requerido'),
      identificationNumber: Yup.string().required('Este campo es requerido'),
      phoneNumber: Yup.string().required('Este campo es requerido'),
      codePhoneNumber: Yup.string().required('Este campo es requerido'),
      email: Yup.string()
        .email('Correo invalido. Ejemplo: tealca@tealca.com')
        .required('Este campo es requerido'),
      city: Yup.string().required('Este campo es requerido'),
      address: Yup.string().required('Este campo es requerido'),
      businessUnit: Yup.object().required('Este campo es requerido'),
      accountSelected: Yup.object().required('Este campo es requerido'),
      ShipmentType: Yup.string().required('Este campo es requerido'),
    }),
  });

  return (
    <MainPage>
      <PageTitle
        title={`${id === undefined ? 'Crear' : 'Editar'}  Destinos Favoritos`}
      />
      <Formik
        initialValues={{
          consignee: consigneeInitialValues,
          shippingLocation: {
            name: '',
            code: '',
            address: '',
            postalCode: '',
            coordinates: {
              lat: 0,
              lng: 0,
            },
          },
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { resetForm }) => {
          setLoadingStatus(ConfirmModalStatus.PROCESSING);
          setLoadingTitle('Guardando...');
          setLoading(true);
          let account = {
            accountCustomerID: user.client!.id,
            accountID: values.consignee.id ?? undefined,
            buCode: values.consignee.businessUnit?.buCode,
            accountFullName: values.consignee.fullName,
            accountTaxIdentTypeID:
              values.consignee?.abbreviationName?.taxIdentificationTypeId,
            accountIdentificationNumber: values.consignee.identificationNumber,
            accountCountry: user.client!.accountCode !== '2500' ? 'VE' : 'USA',
            accountCountryID: user.client!.accountCode !== '2500' ? 236 : 232,
            accountAddressID: values.consignee?.addressId ?? undefined,
            deliveryTypeID: values.consignee?.ShipmentType,
            accountLocationID: values.consignee.locationId,
            accountAddress: values.consignee.address,
            accountAddressName: values.shippingLocation.name,
            accountPhoneID: values.consignee.phoneId,
            accountPhone: values.consignee.phoneNumber,
            accountPhoneCode: values.consignee?.codePhoneNumber,
            accountPostalCode: values.consignee?.postalCode,
            accountEmailID: values.consignee.emailId,
            accountEmail: values.consignee.email,
            accountLatitude: values.shippingLocation?.coordinates.lat,
            accountLongitude: values.shippingLocation?.coordinates.lng,
            favoriteDestinationID: values.consignee.favoriteId ?? undefined,
            statusID: 1,
            creationUser: user.user?.login ?? 'ccw',
          } as FavoriteDestinationsSpDto;

          let newShipment = await createFavoriteDestinationsApi(account);

          if (newShipment.didError === false) {
            setLoadingStatus(ConfirmModalStatus.SUCCESS);
            setLoadingTitle('Se registro correctamente');
            setTimeout(() => {
              navigate('/favoriteDestination/consult');
            }, 1500);
          } else {
            setLoadingStatus(ConfirmModalStatus.ERROR);
            setLoadingTitle('Error: ' + newShipment.errorMessage);
          }
        }}
      >
        {(formik) => (
          <form
            onSubmit={formik.handleSubmit}
            className="flex flex-1 flex-col gap-16 w-full"
          >
            <div className="flex flex-1 justify-between gap-2 md:gap-6 flex-col-reverse md:flex-row">
              <div className="flex flex-1" style={{ maxWidth: '45rem' }}>
                <NavLink to="/favoriteDestination/consult">
                  <PrimaryButton
                    type="button"
                    className="flex gap-1 items-center"
                  >
                    <ChevronLeftIcon
                      className="h-5 w-5 ml-0 mr-2"
                      aria-hidden="true"
                    />{' '}
                    Regresar
                  </PrimaryButton>
                </NavLink>
              </div>
              <div className="flex flex-1 flex-col gap-12 items-end">
                <PrimaryButton className="px-4" type="submit">
                  Guardar{' '}
                  <PaperAirplaneIcon
                    className="h-5 w-5 ml-2 mr-0"
                    aria-hidden="true"
                  />
                </PrimaryButton>
              </div>
            </div>
            <div className="flex flex-1 flex-col xl:flex-row gap-16 -mt-12">
              {/* Consigne */}

              <Card title="Libreta de Destinatarios:">
                <div className="flex flex-1 flex-col p-4 gap-12">
                  {/* Shipper details */}
                  <FavoriteDestinationForm formik={formik} />
                </div>
              </Card>
            </div>
            <ModalLoading
              open={loading}
              title={loadingTitle}
              status={loadingStatus}
              setOpen={setLoading}
              onPrint={() => {}}
            />
          </form>
        )}
      </Formik>
    </MainPage>
  );
};

export default CreateFavoriteDestination;
