import { randomDestination } from '../utils/random';
import { DestinationInterface } from '../interfaces';

export const getAccountDestinations = async (
  accountID: string
): Promise<DestinationInterface[]> => {
  //console.log(`[API CALL] GET Destinations from Account: ${accountID}`);

  return new Array(Math.floor(Math.random() * 100) + 20)
    .fill(0)
    .map(() => randomDestination());
};

export const getDestinationByCode = async (
  code: string
): Promise<DestinationInterface | undefined> => {
  //console.log(`[API CALL] GET Account Contact by code: ${code}`);
  return undefined;
};
