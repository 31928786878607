import { FC, useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '../../store/hooks';
import { PlusIcon } from '@heroicons/react/24/solid';
import { getUserPreShipments } from '../../services';
import { PreShipmentInterface } from '../../interfaces';
import { NavLink } from 'react-router-dom';

import {
  FormText,
  MainPage,
  PageTitle,
  FormSelect,
  PrimaryButton,
  PreShipmentField,
  PaginationFooter,
  ModalLoading,
  ConfirmModalStatus,
} from '../../components';
import { PreShipmentTable } from '../../components/Preshipment/PreShipmentTable';

const ConsultPreShipments: FC = () => {
  const user = useAppSelector((state) => state.user);

  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const [registersNumber, setRegistersNumber] = useState(10);
  const [preShipments, setPreShipments] = useState<PreShipmentInterface[]>([]);
  const [sortBy, setSortBy] = useState({
    field: PreShipmentField.PRE_SHIPMENT,
    asc: true,
  });

  let [loading, setLoading] = useState(false);
  let [loadingStatus, setLoadingStatus] = useState(
    ConfirmModalStatus.PROCESSING
  );
  let [loadingTitle, setLoadingTitle] = useState('Cargando...');

  const filteredPreShipments = useMemo(() => {
    setPage(0);
    const tokens = search.toLowerCase().split(' ');

    return preShipments.filter((preShipment) => {
      return tokens.every((token) => {
        return (
          preShipment?.shipmentNumber
            ?.toString()
            .toLowerCase()
            .includes(token) ||
          preShipment.consignee.accountFullName.toLowerCase().includes(token) ||
          (
            preShipment.consignee.abbreviationName +
            preShipment.consignee.identificationNumber
          )
            .toLowerCase()
            .includes(token) ||
          preShipment.totalPieces?.toString().toLowerCase().includes(token) ||
          preShipment.totalWeight?.toString().toLowerCase().includes(token)
        );
      });
    });
  }, [preShipments, search]);

  const paginatedPreShipments = useMemo(() => {
    const orderedPreShipments = filteredPreShipments.sort((a, b) => {
      if (sortBy.field === PreShipmentField.PRE_SHIPMENT) {
        return sortBy.asc
          ? a.shipmentNumber!.localeCompare(b!.shipmentNumber ?? '')
          : b.shipmentNumber!.localeCompare(a!.shipmentNumber ?? '');
      } else if (sortBy.field === PreShipmentField.CONSIGNEE_NAME) {
        return sortBy.asc
          ? a.consignee.accountFullName.localeCompare(
              b.consignee.accountFullName
            )
          : b.consignee.accountFullName.localeCompare(
              a.consignee.accountFullName
            );
      } else if (sortBy.field === PreShipmentField.CONSIGNEE_ID) {
        const aId =
          a.consignee.abbreviationName + a.consignee.identificationNumber;
        const bId =
          b.consignee.abbreviationName + b.consignee.identificationNumber;

        return sortBy.asc ? aId.localeCompare(bId) : bId.localeCompare(aId);
      } else if (sortBy.field === PreShipmentField.CITY) {
        return sortBy.asc
          ? a.shippingLocation.name.localeCompare(b!.shippingLocation!.name)
          : b.shippingLocation.name.localeCompare(a!.shippingLocation!.name);
      } else if (sortBy.field === PreShipmentField.ADDRESS) {
        return sortBy.asc
          ? a.shippingLocation!.address?.localeCompare(
              b!.shippingLocation!.address
            )
          : b.shippingLocation!.address?.localeCompare(
              a!.shippingLocation!.address
            );
      } else if (sortBy.field === PreShipmentField.PIECES) {
        return sortBy.asc
          ? (a.totalPieces ?? 0) - (b.totalPieces ?? 0)
          : (b.totalPieces ?? 0) - (a.totalPieces ?? 0);
      } else if (sortBy.field === PreShipmentField.WEIGHT) {
        return sortBy.asc
          ? (a.totalWeight ?? 0) - (b.totalWeight ?? 0)
          : (b.totalWeight ?? 0) - (a.totalWeight ?? 0);
      }
      return 0;
    });

    const start = page * registersNumber;
    const end = start + registersNumber;

    return orderedPreShipments.slice(start, end);
  }, [filteredPreShipments, sortBy, page, registersNumber]);

  const handleSortByChange = (field: PreShipmentField) => {
    if (sortBy.field === field) {
      setSortBy({ ...sortBy, asc: !sortBy.asc });
    } else {
      setSortBy({ field, asc: true });
    }
  };

  useEffect(() => {
    setLoadingStatus(ConfirmModalStatus.PROCESSING);
    setLoadingTitle('Cargando...');
    setLoading(true);

    const customercode = '' + user?.client?.accountCode;
    const customerId = '' + user?.client?.id;
    const fetchPreShipments = async () => {
      const preShipments = await getUserPreShipments(customercode, customerId);
      setLoading(false);
      setPreShipments(preShipments);
    };

    fetchPreShipments();
  }, [user?.client?.id]);

  return (
    <MainPage>
      <PageTitle title="Preguías" />

      <div className="flex flex-1 flex-col">
        <div className="flex flex-1 justify-between gap-2 md:gap-6 flex-col-reverse md:flex-row">
          <div className="flex flex-1" style={{ maxWidth: '45rem' }}>
            <FormText
              id="search"
              name="search"
              value={search}
              placeholder="Buscar preguía..."
              containerClassname="flex flex-1"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>

          <div className="flex flex-1 justify-between sm:items-center flex-col-reverse gap-6 sm:gap-2 sm:flex-row">
            <div className="flex items-center text-xs">
              <span>Mostrar</span>
              <div className="inline mx-1">
                <FormSelect
                  id="registersNumber"
                  name="registersNumber"
                  label=""
                  selected={registersNumber}
                  options={[10, 25, 50, 100]}
                  className="!w-20 !h-8 !px-2"
                  optionString={(option) => option.toString()}
                  onSelectOption={(option) => {
                    setPage(0);
                    setRegistersNumber(option);
                  }}
                />
              </div>
              <span>registros</span>
            </div>

            <NavLink to="/pre-shipments/create">
              <PrimaryButton className="flex gap-1 items-center">
                <PlusIcon className="h-4 w-4 shrink-0 text-white" />
                Nuevo
              </PrimaryButton>
            </NavLink>
          </div>
        </div>

        <div className="flex flex-1 flex-col mt-6">
          <PreShipmentTable
            sortBy={sortBy}
            preShipments={paginatedPreShipments}
            onChangeSortBy={handleSortByChange}
          />

          <PaginationFooter
            currentPage={page}
            itemsPerPage={registersNumber}
            totalItems={filteredPreShipments.length}
            onPageChange={setPage}
          />

          <ModalLoading
            open={loading}
            title={loadingTitle}
            status={loadingStatus}
            setOpen={setLoading}
            onPrint={() => {}}
          />
        </div>
      </div>
    </MainPage>
  );
};

export default ConsultPreShipments;
