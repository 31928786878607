import { FC, useMemo, useState } from "react";
import moment from "moment";
import classNames from "classnames";
import { LinkText, PrimaryButton } from "../Atoms/Buttons";
import { FormText } from "../FormFields/FormText";
import { XMarkIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { FormDatePicker } from "../FormFields/FormDatePicker";

export enum ShipmentFilterEnum {
  SHIPMENT,
  START_DATE,
  END_DATE,
  CONSIGNEE,
  CITY,
  TRACKING,
  MANIFEST,
  USER,
}

interface ShipmentFiltersProps {
  filters: Map<ShipmentFilterEnum, string>;
  setFilters: (filters: Map<ShipmentFilterEnum, string>) => void;
  setSearchFilter: (filter: boolean) => void;
}
export const ShipmentFilters: FC<ShipmentFiltersProps> = ({
  filters,
  setFilters,
  setSearchFilter,
}) => {
  const [currentFilter, setCurrentFilter] = useState(
    ShipmentFilterEnum.SHIPMENT
  );

  const startDate = useMemo(() => {
    if (!filters.has(ShipmentFilterEnum.START_DATE)) {
      return {
        startDate: null,
        endDate: null,
      };
    }

    const date = moment(filters.get(ShipmentFilterEnum.START_DATE)).toDate();
    return {
      startDate: date,
      endDate: date,
    };
  }, [filters]);

  const endDate = useMemo(() => {
    if (!filters.has(ShipmentFilterEnum.END_DATE)) {
      return {
        startDate: null,
        endDate: null,
      };
    }

    const date = moment(filters.get(ShipmentFilterEnum.END_DATE)).toDate();
    return {
      startDate: date,
      endDate: date,
    };
  }, [filters]);

  const handleFilterChange = (value: string, filter: ShipmentFilterEnum) => {
    const newFilters = new Map(filters);

    if (value == "") {
      newFilters.delete(filter);
    } else {
      newFilters.set(filter, value);
    }

    setFilters(newFilters);
  };

  return (
    <div className="flex flex-1 flex-col">
      <div className="flex flex-1 gap-2 px-2 py-2 text-xs text-gray-700 flex-wrap justify-evenly">
        <div
          style={{ minWidth: "6rem", maxWidth: "8rem" }}
          className={classNames(
            "flex items-center px-4 py-2 hover:bg-gray-200 cursor-pointer rounded-lg select-none justify-center text-center h-12",
            currentFilter == ShipmentFilterEnum.SHIPMENT && "!bg-blue-200"
          )}
          onClick={() => setCurrentFilter(ShipmentFilterEnum.SHIPMENT)}
        >
          <span>Guía</span>
          <span
            className={classNames(
              "ml-1.5 rounded bg-main-500 bg-opacity-50 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700",
              !filters.get(ShipmentFilterEnum.SHIPMENT) && "hidden"
            )}
          >
            1
          </span>
        </div>

        <div
          style={{ minWidth: "6rem", maxWidth: "8rem" }}
          className={classNames(
            "flex items-center px-4 py-2 hover:bg-gray-200 cursor-pointer rounded-lg select-none justify-center text-center h-12",
            currentFilter == ShipmentFilterEnum.START_DATE && "!bg-blue-200"
          )}
          onClick={() => setCurrentFilter(ShipmentFilterEnum.START_DATE)}
        >
          <span>Por Fechas</span>

          <span
            className={classNames(
              "ml-1.5 rounded bg-main-500 bg-opacity-50 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700",
              !filters.get(ShipmentFilterEnum.START_DATE) &&
                !filters.get(ShipmentFilterEnum.END_DATE) &&
                "hidden"
            )}
          >
            {+!!filters.get(ShipmentFilterEnum.START_DATE) +
              +!!filters.get(ShipmentFilterEnum.END_DATE)}
          </span>
        </div>

        <div
          style={{ minWidth: "6rem", maxWidth: "8rem" }}
          className={classNames(
            "flex items-center px-4 py-2 hover:bg-gray-200 cursor-pointer rounded-lg select-none justify-center text-center h-12",
            currentFilter == ShipmentFilterEnum.CONSIGNEE && "!bg-blue-200"
          )}
          onClick={() => setCurrentFilter(ShipmentFilterEnum.CONSIGNEE)}
        >
          <span>Destinatario</span>

          <span
            className={classNames(
              "ml-1.5 rounded bg-main-500 bg-opacity-50 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700",
              !filters.get(ShipmentFilterEnum.CONSIGNEE) && "hidden"
            )}
          >
            1
          </span>
        </div>

        <div
          style={{ minWidth: "6rem", maxWidth: "8rem" }}
          className={classNames(
            "flex items-center px-4 py-2 hover:bg-gray-200 cursor-pointer rounded-lg select-none justify-center text-center h-12",
            currentFilter == ShipmentFilterEnum.TRACKING && "!bg-blue-200"
          )}
          onClick={() => setCurrentFilter(ShipmentFilterEnum.TRACKING)}
        >
          <span>Nro. Tracking (Guía Aliado)</span>

          <span
            className={classNames(
              "ml-1.5 rounded bg-main-500 bg-opacity-50 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700",
              !filters.get(ShipmentFilterEnum.TRACKING) && "hidden"
            )}
          >
            1
          </span>
        </div>

        <div
          style={{ minWidth: "6rem", maxWidth: "8rem" }}
          className={classNames(
            "flex items-center px-4 py-2 hover:bg-gray-200 cursor-pointer rounded-lg select-none justify-center text-center h-12",
            currentFilter == ShipmentFilterEnum.MANIFEST && "!bg-blue-200"
          )}
          onClick={() => setCurrentFilter(ShipmentFilterEnum.MANIFEST)}
        >
          <span>Nro. Manifiesto</span>

          <span
            className={classNames(
              "ml-1.5 rounded bg-main-500 bg-opacity-50 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700",
              !filters.get(ShipmentFilterEnum.MANIFEST) && "hidden"
            )}
          >
            1
          </span>
        </div>

        <div
          style={{ minWidth: "6rem", maxWidth: "8rem" }}
          className={classNames(
            "flex items-center px-4 py-2 hover:bg-gray-200 cursor-pointer rounded-lg select-none justify-center text-center h-12",
            currentFilter == ShipmentFilterEnum.USER && "!bg-blue-200"
          )}
          onClick={() => setCurrentFilter(ShipmentFilterEnum.USER)}
        >
          <span>Usuario de Creación</span>

          <span
            className={classNames(
              "ml-1.5 rounded bg-main-500 bg-opacity-50 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700",
              !filters.get(ShipmentFilterEnum.USER) && "hidden"
            )}
          >
            1
          </span>
        </div>
      </div>

      <div className="flex w-full justify-center">
        <hr className="mb-6" style={{ width: "95%" }} />
      </div>

      <div
        className="flex flex-1 flex-col sm:flex-row sm:px-8 gap-4"
        style={{ minHeight: "5rem" }}
      >
        {currentFilter == ShipmentFilterEnum.SHIPMENT && (
          <div className="flex w-full justify-center gap-1">
            <div className="flex-none w-80">
              <FormText
                id="shipment-filter"
                name="shipment-filter"
                label="Ingrese el número de guía"
                value={filters.get(ShipmentFilterEnum.SHIPMENT) || ""}
                placeholder="Buscar guía..."
                className="!h-8"
                labelClassname="text-xs font-semibold"
                containerClassname="flex flex-1 flex-col"
                style={{ maxWidth: "40rem" }}
                onChange={(e) =>
                  handleFilterChange(
                    e.target.value,
                    ShipmentFilterEnum.SHIPMENT
                  )
                }
              />
            </div>
            <div className="flex-none w-20 pt-5">
              <PrimaryButton
                type="button"
                onClick={() => {
                  setSearchFilter(true);
                }}
                className="flex items-center gap-1"
              >
                <MagnifyingGlassIcon className="h-4 w-4" />
              </PrimaryButton>
            </div>
          </div>
        )}

        {currentFilter == ShipmentFilterEnum.START_DATE && (
          <>
            <div className="flex sm:flex-col h-full justify-evenly sm:justify-between mr-6 pt-2 gap-3 -mb-4 sm:mb-0">
              <LinkText
                text="Hoy"
                className="text-sm"
                onClick={() => {
                  const newFilters = new Map(filters);
                  newFilters.set(
                    ShipmentFilterEnum.START_DATE,
                    moment().format("YYYY-MM-DD")
                  );
                  newFilters.set(
                    ShipmentFilterEnum.END_DATE,
                    moment().format("YYYY-MM-DD")
                  );
                  setFilters(newFilters);
                }}
              />

              <LinkText
                text="Ayer"
                className="text-sm"
                onClick={() => {
                  const newFilters = new Map(filters);
                  newFilters.set(
                    ShipmentFilterEnum.START_DATE,
                    moment().subtract(1, "days").format("YYYY-MM-DD")
                  );
                  newFilters.set(
                    ShipmentFilterEnum.END_DATE,
                    moment().subtract(1, "days").format("YYYY-MM-DD")
                  );
                  setFilters(newFilters);
                }}
              />
            </div>

            <div className="sm:w-64">
              <FormDatePicker
                id="start-date-filter"
                name="start-date-filter"
                label="Desde"
                value={startDate}
                useRange={false}
                maxDate={endDate?.startDate}
                className="!h-8"
                toggleClassName={(oldClassname) =>
                  classNames(oldClassname, "text-main-500")
                }
                labelClassname="text-xs font-semibold"
                containerClassname="flex flex-1 flex-col"
                onChange={(e) => {
                  handleFilterChange(
                    e?.startDate?.toString() ?? "",
                    ShipmentFilterEnum.START_DATE
                  );
                }}
                configs={{
                  shortcuts: {
                    today: "Hoy",
                    yesterday: "Ayer",
                    currentMonth: "Mes actual",
                    pastMonth: "Mes anterior",
                    past: (period: number) => `Hace ${period} días`,
                  },
                }}
              />
            </div>

            <div className="sm:w-64">
              <FormDatePicker
                id="end-date-filter"
                name="end-date-filter"
                label="Hasta"
                value={endDate}
                useRange={false}
                minDate={startDate?.startDate}
                className="!h-8"
                toggleClassName={(oldClassname) =>
                  classNames(oldClassname, "text-main-500")
                }
                labelClassname="text-xs font-semibold"
                containerClassname="flex flex-1 flex-col"
                onChange={(e) => {
                  handleFilterChange(
                    e?.startDate?.toString() ?? "",
                    ShipmentFilterEnum.END_DATE
                  );
                }}
                configs={{
                  shortcuts: {
                    today: "Hoy",
                    yesterday: "Ayer",
                    currentMonth: "Mes actual",
                    pastMonth: "Mes anterior",
                    past: (period: number) => `Hace ${period} días`,
                  },
                }}
              />
            </div>
            <div className="flex sm:flex-col h-full justify-evenly sm:justify-between mr-6 pt-2 gap-3 -mb-4 sm:mb-0 mt-2">
              <PrimaryButton
                type="button"
                onClick={() => {
                  setSearchFilter(true);
                }}
                className="flex items-center gap-1"
              >
                <MagnifyingGlassIcon className="h-4 w-4" />
              </PrimaryButton>
            </div>
          </>
        )}

        {currentFilter == ShipmentFilterEnum.CONSIGNEE && (
          <div className="flex w-full justify-center gap-1">
            <div className="flex-none w-80">
              <FormText
                id="consignee-filter"
                name="consignee-filter"
                label="Ingrese el nombre del Destinatario"
                value={filters.get(ShipmentFilterEnum.CONSIGNEE) || ""}
                placeholder="Buscar destinatario..."
                className="!h-8"
                labelClassname="text-xs font-semibold"
                containerClassname="flex flex-1 flex-col"
                style={{ maxWidth: "40rem" }}
                onChange={(e) =>
                  handleFilterChange(
                    e.target.value,
                    ShipmentFilterEnum.CONSIGNEE
                  )
                }
              />
            </div>
            <div className="flex-none w-20">
              <PrimaryButton
                type="button"
                onClick={() => {
                  setSearchFilter(true);
                }}
                className="flex items-center gap-1 mt-5"
              >
                <MagnifyingGlassIcon className="h-4 w-4" />
              </PrimaryButton>
            </div>
          </div>
        )}

        {currentFilter == ShipmentFilterEnum.TRACKING && (
          <div className="flex w-full justify-center gap-1">
            <div className="flex-none w-80">
              <FormText
                id="tracking-filter"
                name="tracking-filter"
                label="Ingrese el número de Guía aliado"
                value={filters.get(ShipmentFilterEnum.TRACKING) || ""}
                placeholder="Buscar traking (guía aliada)..."
                className="!h-8"
                labelClassname="text-xs font-semibold"
                containerClassname="flex flex-1 flex-col"
                style={{ maxWidth: "40rem" }}
                onChange={(e) =>
                  handleFilterChange(
                    e.target.value,
                    ShipmentFilterEnum.TRACKING
                  )
                }
              />
            </div>
            <div className="flex-none w-20">
              <PrimaryButton
                type="button"
                onClick={() => {
                  setSearchFilter(true);
                }}
                className="flex items-center gap-1 mt-5"
              >
                <MagnifyingGlassIcon className="h-4 w-4" />
              </PrimaryButton>
            </div>
          </div>
        )}

        {currentFilter == ShipmentFilterEnum.MANIFEST && (
          <div className="flex w-full justify-center gap-1">
            <div className="flex-none w-80">
              <FormText
                id="manifest-filter"
                name="manifest-filter"
                label="Ingrese el número de Manifiesto aliado:"
                value={filters.get(ShipmentFilterEnum.MANIFEST) || ""}
                placeholder="Buscar manifiesto..."
                className="!h-8"
                labelClassname="text-xs font-semibold"
                containerClassname="flex flex-1 flex-col"
                style={{ maxWidth: "40rem" }}
                onChange={(e) =>
                  handleFilterChange(
                    e.target.value,
                    ShipmentFilterEnum.MANIFEST
                  )
                }
              />
            </div>
            <div className="flex-none w-20">
              <PrimaryButton
                type="button"
                onClick={() => {
                  setSearchFilter(true);
                }}
                className="flex items-center gap-1 mt-5"
              >
                <MagnifyingGlassIcon className="h-4 w-4" />
              </PrimaryButton>
            </div>
          </div>
        )}

        {currentFilter == ShipmentFilterEnum.USER && (
          <div className="flex w-full justify-center gap-1">
            <div className="flex-none w-80">
              <FormText
                id="user-filter"
                name="user-filter"
                label="Ingrese el nombre del usuario de creación:"
                value={filters.get(ShipmentFilterEnum.USER) || ""}
                placeholder="Buscar usuario..."
                className="!h-8"
                labelClassname="text-xs font-semibold"
                containerClassname="flex flex-1 flex-col"
                style={{ maxWidth: "40rem" }}
                onChange={(e) =>
                  handleFilterChange(e.target.value, ShipmentFilterEnum.USER)
                }
              />
            </div>
            <div className="flex-none w-20">
              <PrimaryButton
                type="button"
                onClick={() => {
                  setSearchFilter(true);
                }}
                className="flex items-center gap-1 mt-5"
              >
                <MagnifyingGlassIcon className="h-4 w-4" />
              </PrimaryButton>
            </div>
          </div>
        )}
      </div>

      <hr className="mb-6 mt-4 border-gray-300" />
    </div>
  );
};
