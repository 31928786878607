import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { FormikErrors } from 'formik';
import { FormLabel } from './FormLabel';
import { Combobox } from '@headlessui/react';
import { inputClassName } from './FormText';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';

interface FormSelectProps<T>
  extends React.InputHTMLAttributes<HTMLInputElement> {
  selected?: T;
  options: T[];
  id: string;
  name: string;
  label?: string;
  notFound?: string;
  isRequired?: boolean;
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[];
  labelClassname?: string;
  containerClassname?: string;
  labelContainerClassname?: string;
  widthClassName?: string;
  optionString: (opt: T) => string;
  onSelectOption?: (opt: T) => void;
  onSearch?: (opt: T, search: string) => boolean;
  isWidth?: boolean;
  RenderOption?: ({ option }: { option: T }) => JSX.Element;
}
export const FormSelect = <T extends any>({
  selected,
  options,
  label = '',
  id,
  name,
  error,
  isRequired,
  className,
  labelClassname,
  containerClassname,
  labelContainerClassname,
  widthClassName,
  disabled,
  placeholder = 'Buscar...',
  notFound = 'Elementos no encontrados',
  optionString,
  onSearch,
  onSelectOption = () => {},
  isWidth,
  RenderOption = ({ option }) => <p>{optionString(option)}</p>,
  ...props
}: FormSelectProps<T>) => {
  const ref = useRef<HTMLDivElement>(null);
  const [focused, setFocused] = useState(false);
  const [displayBottom, setDisplayBottom] = useState(true);

  const value = useMemo(() => {
    return selected ? optionString(selected) : '';
  }, [selected, optionString]);

  const searchFunction = useCallback(
    (option: T, search: string) => {
      if (!!onSearch) return onSearch(option, search);

      const str = optionString(option).toLowerCase();

      return (
        str.split(' ').some((s) => s.startsWith(search)) ||
        str.startsWith(search)
      );
    },
    [onSearch, optionString]
  );

  const Options = useMemo(() => {
    return (
      <div
        id={`form-select-${name}`}
        className={classNames(
          'absolute top-full w-full left-0 z-50 divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-black ring-opacity-5',
          !focused && 'hidden',
          displayBottom ? 'rounded-b-xl' : 'rounded-t-xl'
        )}
        style={{ zIndex: 100 }}
      >
        <Combobox.Options
          static
          style={{ width: `${ref.current?.clientWidth}px` }}
          className={classNames(
            (!isWidth || isWidth == undefined ? 'max-h-40 ' : 'max-h-20 ') +
              widthClassName +
              ' scroll-py-2 overflow-auto overflow  py-2 text-sm text-gray-800',
            (options.length == 0 || !focused) && 'hidden'
          )}
        >
          {options.map((option, index) => (
            <Combobox.Option
              key={index}
              id={`form-select-${name}-option-${index}`}
              value={option}
              className={({ active }) =>
                classNames(
                  'cursor-pointer px-4 py-2',
                  active && 'bg-main-500 text-white'
                )
              }
            >
              <RenderOption option={option} />
            </Combobox.Option>
          ))}
        </Combobox.Options>

        <p
          className={classNames(
            'p-4 text-sm text-gray-500',
            (!focused || options.length > 0) && 'hidden'
          )}
          style={{ width: `${ref.current?.clientWidth}px` }}
        >
          {notFound}
        </p>
      </div>
    );

    // Only update when or form name focused changes. The rest of the properties can
    // cause constant re-rendering
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focused, name]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setFocused(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, setFocused]);

  useEffect(() => {
    const dropdown = document.getElementById(`form-select-${name}`);

    if (!dropdown) return;

    const triggerRect = dropdown.getBoundingClientRect();
    const dropdownHeight = dropdown.offsetHeight;

    let dropdownTop = triggerRect.top;

    if (dropdownTop + dropdownHeight + 100 > window.innerHeight) {
      const parent = dropdown.parentElement;

      setDisplayBottom(false);
      dropdownTop = triggerRect.top - dropdownHeight;
      dropdown.style.marginTop = `calc(-${dropdownHeight}px - ${parent?.offsetHeight}px)`;
    } else {
      setDisplayBottom(true);
      dropdown.style.marginTop = '0';
    }
  }, [focused, name]);

  useEffect(() => {
    let search = '';

    const handleKeyDown = (event: KeyboardEvent) => {
      if (!focused) return;

      let index = -1;
      const key = event.key.toLowerCase();

      if (key.length == 1) {
        search += key;
        index = options.findIndex((option) => searchFunction(option, search));

        if (index == -1 && search.length > 1) {
          search = key;
          index = options.findIndex((option) => searchFunction(option, search));
        }
      }

      if (index !== -1) {
        const element = document.getElementById(
          `form-select-${name}-option-${index}`
        );
        element?.focus();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };

    // Only update when or form name focused changes. The rest of the properties can
    // cause constant re-rendering
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focused, name]);

  return (
    <div className={containerClassname}>
      <FormLabel
        label={label}
        htmlFor={name}
        isRequired={isRequired}
        className={labelClassname}
        containerClassname={labelContainerClassname}
      />

      <div className="flex flex-1 flex-col">
        <div
          className={classNames(
            'divide-y divide-gray-100 flex flex-1',
            focused && (displayBottom ? 'rounded-b-none' : 'rounded-t-none')
          )}
        >
          <Combobox onChange={(option: T) => onSelectOption(option)}>
            <div
              {...props}
              ref={ref}
              onClick={() => setFocused((f) => !f)}
              className={classNames(
                focused && 'ring-2 ring-inset ring-main-500',
                inputClassName,
                'relative bg-white w-full pr-11 pl-4 flex items-center',
                !!error && 'ring-red-500',
                focused &&
                  (displayBottom ? 'rounded-b-none' : 'rounded-t-none'),
                !!disabled && 'bg-gray-100 text-gray-500 pointer-events-none',
                className
              )}
            >
              {focused ? (
                <ChevronUpIcon
                  className="pointer-events-none absolute right-4 h-5 w-5 text-gray-400"
                  style={{ top: 'calc(50% - 0.5rem)' }}
                  aria-hidden="true"
                />
              ) : (
                <ChevronDownIcon
                  className="pointer-events-none absolute right-4 h-5 w-5 text-gray-400"
                  style={{ top: 'calc(50% - 0.5rem)' }}
                  aria-hidden="true"
                />
              )}

              <input
                id={id}
                name={name}
                type="select"
                autoComplete="off"
                onChange={() => {}}
                className="w-full bg-transparent focus:outline-none cursor-pointer"
                placeholder={placeholder}
                style={{ caretColor: 'transparent' }}
                value={value}
              />

              {Options}
            </div>
          </Combobox>
        </div>

        {typeof error == 'string' && !!error && (
          <span className="mt-2 text-sm text-red-500">{error}</span>
        )}
        {Array.isArray(error) && (
          <span className="mt-2 text-sm text-red-500">{error.join(', ')}</span>
        )}
      </div>
    </div>
  );
};
