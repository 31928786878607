import { FC } from "react";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";
import { DestinationInterface } from "../../interfaces";
import { DestinationForm, MainPage, PageTitle } from "../../components";

const EditDestination: FC = () => {
  const taxIdentificationTypes = useAppSelector(
    (state) => state.inmutable.taxIdentificationTypes
  );
  const destination = useLocation().state.destination as DestinationInterface;

  return (
    <MainPage>
      <PageTitle back title="Editar Destinatario" />

      <DestinationForm
        isUpdate
        initialValues={{
          code: destination.location.code ?? "",
          abbreviationName:
            taxIdentificationTypes.find(
              (t) => t.abbreviationName == destination.account.abbreviationName
            )?.abbreviationName ?? "V-",
          identificationNumber: destination.account.identificationNumber,
          name: destination.location.name,
          alias: destination.location.name,
          destination: "CCS",
          store: destination.businessUnit?.buCode,
          address: destination.location.address,
          contact: destination.account.accountFullName,
          phone1: destination.account.listAccountPhone[0]?.phoneNumber ?? "",
          phone2: destination.account.listAccountPhone[1]?.phoneNumber ?? "",
          email: destination.account.listAccountEmail[0]?.email ?? "",
        }}
      />
    </MainPage>
  );
};

export default EditDestination;
