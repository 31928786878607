import { FC, useEffect, useMemo, useState } from "react";
import moment from "moment";
import classNames from "classnames";
import { getUserShipments } from "../../services";
import { useAppSelector } from "../../store/hooks";
import { ShipmentInterface, ShipmentStatusEnum } from "../../interfaces";
import {
  MainPage,
  PageTitle,
  FormSelect,
  FormDatePicker,
  PaginationFooter,
  TraceabilityField,
  TraceabilityTable,
} from "../../components";

const AVAILABLE_STATUSES = [
  ShipmentStatusEnum.ACTIVE,
  ShipmentStatusEnum.PROCESSED,
  ShipmentStatusEnum.DELIVERED,
];

const Traceability: FC = () => {
  const user = useAppSelector((state) => state.user);

  const [page, setPage] = useState(0);
  const [to, setTo] = useState<Date>();
  const [from, setFrom] = useState<Date>();
  const [registersNumber] = useState(10);
  const [status, setStatus] = useState<ShipmentStatusEnum>();
  const [shipments, setShipments] = useState<ShipmentInterface[]>([]);
  const [sortBy, setSortBy] = useState({ field: TraceabilityField.CLIENT, asc: true });

  const toDate = useMemo(() => {
    return {
      startDate: to ?? null,
      endDate: to ?? null,
    };
  }, [to]);

  const fromDate = useMemo(() => {
    return {
      startDate: from ?? null,
      endDate: from ?? null,
    };
  }, [from]);

  const filteredShipments = useMemo(() => {
    setPage(0);

    return shipments
      .filter(
        (shipment) =>
          (!from || moment(shipment.date).isSameOrAfter(from)) &&
          (!to || moment(shipment.date).isSameOrBefore(moment(to).add(1, "days"))) &&
          (!status || shipment.status == status)
      )
      .sort((a, b) => {
        if (sortBy.field == TraceabilityField.CLIENT) {
          return sortBy.asc
            ? a.shipper.accountFullName.localeCompare(b.shipper.accountFullName)
            : b.shipper.accountFullName.localeCompare(a.shipper.accountFullName);
        } else if (sortBy.field == TraceabilityField.SHIPMENT) {
          return sortBy.asc ? a.number - b.number : b.number - a.number;
        } else if (sortBy.field == TraceabilityField.ORIGIN) {
          const aName =
            a.businessUnitOrigin.buCode + " - " + a.businessUnitOrigin.location.name;
          const bName =
            b.businessUnitOrigin.buCode + " - " + b.businessUnitOrigin.location.name;

          return sortBy.asc ? aName.localeCompare(bName) : bName.localeCompare(aName);
        } else if (sortBy.field == TraceabilityField.DESTINATION) {
          const aName =
            a.businessUnitConsignee.buCode +
            " - " +
            a.businessUnitConsignee.location.name;
          const bName =
            b.businessUnitConsignee.buCode +
            " - " +
            b.businessUnitConsignee.location.name;

          return sortBy.asc ? aName.localeCompare(bName) : bName.localeCompare(aName);
        } else if (sortBy.field == TraceabilityField.STATUS) {
          return sortBy.asc
            ? a.status.localeCompare(b.status)
            : b.status.localeCompare(a.status);
        } else if (sortBy.field == TraceabilityField.WEIGHT) {
          return sortBy.asc
            ? (a.totalWeight ?? 0) - (b.totalWeight ?? 0)
            : (b.totalWeight ?? 0) - (a.totalWeight ?? 0);
        } else if (sortBy.field == TraceabilityField.VOLUME) {
          return sortBy.asc
            ? (a.totalVolumeCm3 ?? 0) - (b.totalVolumeCm3 ?? 0)
            : (b.totalVolumeCm3 ?? 0) - (a.totalVolumeCm3 ?? 0);
        } else if (sortBy.field == TraceabilityField.PIECES) {
          return sortBy.asc
            ? (a.totalPieces ?? 0) - (b.totalPieces ?? 0)
            : (b.totalPieces ?? 0) - (a.totalPieces ?? 0);
        } else if (sortBy.field == TraceabilityField.CREATION_DATE) {
          return sortBy.asc
            ? moment(a.date).diff(moment(b.date))
            : moment(b.date).diff(moment(a.date));
        } else if (sortBy.field == TraceabilityField.PROCESSED_DATE) {
          return sortBy.asc
            ? moment(a.requestProcessedDate).diff(moment(b.requestProcessedDate))
            : moment(b.requestProcessedDate).diff(moment(a.requestProcessedDate));
        } else if (sortBy.field == TraceabilityField.DELIVERY_DATE) {
          return sortBy.asc
            ? moment(a.deliveredDate).diff(moment(b.deliveredDate))
            : moment(b.deliveredDate).diff(moment(a.deliveredDate));
        } else if (sortBy.field == TraceabilityField.CONSIGNEE) {
          return sortBy.asc
            ? a.consignee.accountFullName.localeCompare(b.consignee.accountFullName)
            : b.consignee.accountFullName.localeCompare(a.consignee.accountFullName);
        }

        return 0;
      });
  }, [shipments, from, to, status, sortBy]);

  const paginatedShipments = useMemo(() => {
    const start = page * registersNumber;
    const end = start + registersNumber;

    return filteredShipments.slice(start, end);
  }, [filteredShipments, sortBy, page, registersNumber]);

  const handleSortByChange = (field: TraceabilityField) => {
    if (sortBy.field == field) {
      setSortBy({ ...sortBy, asc: !sortBy.asc });
    } else {
      setSortBy({ field, asc: true });
    }
  };

  useEffect(() => {
    const id = user?.client?.id;
    if (!id) return;

    const fetchShipments = async () => {
      const shipments = await getUserShipments(id);
      setShipments(shipments);
    };

    fetchShipments();
  }, [user?.client?.id]);

  return (
    <MainPage>
      <PageTitle title="Trazabilidad" />

      <div className="flex flex-1 flex-col md:flex-row md:items-center justify-between mb-10">
        <div className="flex flex-1 items-start md:items-center gap-4 flex-col md:flex-row">
          <FormDatePicker
            id="from-date"
            name="from-date"
            label="Desde"
            value={fromDate}
            useRange={false}
            maxDate={to}
            className="!h-8"
            labelClassname="!text-xs"
            labelContainerClassname="flex md:!justify-end"
            toggleClassName={(oldClassname) => classNames(oldClassname, "text-main-500")}
            containerClassname="flex flex-1 w-full md:ml-0 md:gap-2 md:items-center flex-col md:flex-row"
            onChange={(e) =>
              setFrom(!!e?.startDate ? moment(e?.startDate).toDate() : undefined)
            }
            configs={{
              shortcuts: {
                today: "Hoy",
                yesterday: "Ayer",
                currentMonth: "Mes actual",
                pastMonth: "Mes anterior",
                past: (period: number) => `Hace ${period} días`,
              },
            }}
          />

          <FormDatePicker
            id="to-date"
            name="to-date"
            label="Hasta"
            value={toDate}
            minDate={from}
            useRange={false}
            className="!h-8"
            labelClassname="!text-xs"
            labelContainerClassname="flex md:!justify-end"
            toggleClassName={(oldClassname) => classNames(oldClassname, "text-main-500")}
            containerClassname="flex flex-1 w-full md:ml-0 md:gap-2 md:items-center flex-col md:flex-row"
            onChange={(e) =>
              setTo(!!e?.startDate ? moment(e?.startDate).toDate() : undefined)
            }
            configs={{
              shortcuts: {
                today: "Hoy",
                yesterday: "Ayer",
                currentMonth: "Mes actual",
                pastMonth: "Mes anterior",
                past: (period: number) => `Hace ${period} días`,
              },
            }}
          />

          <FormSelect
            id="status"
            name="status"
            label="Estatus"
            selected={status}
            options={AVAILABLE_STATUSES}
            className="!h-8"
            labelClassname="!text-xs"
            labelContainerClassname="flex md:!justify-end"
            containerClassname="flex flex-1 w-full md:ml-0 md:gap-2 md:items-center flex-col md:flex-row"
            optionString={(status) => status}
            onSelectOption={(status) => setStatus(status)}
          />
        </div>

        <div className="flex mt-2 md:mt-0 md:pl-12 w-full md:w-auto justify-end">
          <button
            onClick={() => {
              setTo(undefined);
              setFrom(undefined);
              setStatus(undefined);
            }}
            type="button"
            className="text-gray-500 hover:text-gray-800 p-2"
          >
            Limpiar
          </button>
        </div>
      </div>

      <TraceabilityTable
        sortBy={sortBy}
        shipments={paginatedShipments}
        onChangeSortBy={handleSortByChange}
      />

      <PaginationFooter
        currentPage={page}
        itemsPerPage={registersNumber}
        totalItems={filteredShipments.length}
        onPageChange={setPage}
      />
    </MainPage>
  );
};

export default Traceability;
