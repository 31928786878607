export interface RangeDeclareDto {
    rangeID: number;
    minWeight: number;
    maxWeight: number;
    minDeclareValue: number;
    maxDeclareValue: number;
  }
  

  export interface GeneralAppDataDto {
    applicationName: string;
    applicationID: number;
    applicationDescription: string;
    applicationCode: string;
    rangeDeclareList: RangeDeclareDto[];
    exchangeRate: ExchangeRateDto;
  }
  
  export interface ItemInterface {
    id: string;
    code: string;
    name: string;
    order: number;
    mandatory: boolean;
    rate: {
      value: number;
      iva: number;
      ipostel: number;
      isPercentage: boolean;
      distance: number;
      tierCode: string;
      tierName: string;
    };
  }
  
  export const discountItem: ItemInterface = {
    id: "31",
    code: "0310",
    name: "Bonificación a Cliente",
    order: 0,
    mandatory: false,
    rate: {
      value: 0,
      isPercentage: false,
      iva: 0,
      ipostel: 0,
      distance: 0,
      tierCode: "",
      tierName: "",
    },
  };
  
  export interface ExchangeRateDto {
    countryID: number;
    currencyLocalID: number;
    exchangeCurrencyID: number;
    valueDate: Date;
    exchangeRate: number;
  }
  