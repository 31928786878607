import { FC, useState } from "react";
import classNames from "classnames";
import { Popover } from "../Atoms/Popover";
import { DispatchGuideDto } from "../../interfaces";
import {
  PrinterIcon,
  ChevronDownIcon,
  EllipsisVerticalIcon,
} from "@heroicons/react/24/outline";
import moment from "moment";
import { FormCheckbox } from "../FormFields/FormCheckbox";

export enum GuideDispatchField {
  NUM_GUIDE,
  SERVICE,
  ORIGEN,
  PESO,
  VOLUMEN,
  PIECES,
  DATECREATE,
  SALE,
}

const TableOptionsButton = (
  <div className="flex w-8 h-8 items-center justify-center">
    <EllipsisVerticalIcon className="w-5 h-5" />
  </div>
);

interface ShipmentTablesProps {
  selectedAll: boolean;
  dispatchGuides: DispatchGuideDto[];
  selectedGuide: DispatchGuideDto[];
  sortBy: { field: GuideDispatchField; asc: boolean };
  onChangeSortBy: (field: GuideDispatchField) => void;
  onSelectGuide: (guide: DispatchGuideDto) => void;
  onSelectAll: () => void;
}

export const GuidesDispatchTables: FC<ShipmentTablesProps> = ({
  selectedAll,
  dispatchGuides,
  selectedGuide,
  sortBy,
  onChangeSortBy,
  onSelectGuide,
  onSelectAll,
}) => {

  const [openModal, setOpenModal] = useState(false);
  const [selected, setSelected] = useState<DispatchGuideDto>();

  
  return (
    <div className="relative w-full table-fix-head mt-4 overflow-auto">
      <table className="table-auto w-full text-gray-600 text-sm text-left border border-gray-300">
        <thead className="select-none font-semibold border-b border-gray-300 bg-gray-50">          
          <tr>
            
            <th className="pl-2 py-2">
              <FormCheckbox
                id="selectAll"
                name="selectAll"
                label=""
                onChange={onSelectAll}
                checked={selectedAll}
              />
            </th>


            <th
              className="px-4 py-2"
              onClick={() => onChangeSortBy(GuideDispatchField.NUM_GUIDE)}
              style={{ minWidth: "12rem" }}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>NUMERO DE GUIA</span>
                <div className="h-4 w-4">
                  {sortBy.field == GuideDispatchField.NUM_GUIDE && (
                    <ChevronDownIcon
                      className={classNames("w-4 h-4", sortBy.asc && "rotate-180")}
                    />
                  )}
                </div>
              </div>
            </th>

            {/* <th
              className="px-4 py-2"
              onClick={() => onChangeSortBy(GuideDispatchField.SERVICE)}
              style={{ minWidth: "2rem" }}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>SERVICIO</span>
                <div className="h-4 w-4">
                  {sortBy.field == GuideDispatchField.SERVICE && (
                    <ChevronDownIcon
                      className={classNames("w-4 h-4", sortBy.asc && "rotate-180")}
                    />
                  )}
                </div>
              </div>
            </th> */}

            <th
              className="px-4 py-2"
              onClick={() => onChangeSortBy(GuideDispatchField.ORIGEN)}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>ORIGEN</span>
                <div className="h-4 w-4">
                  {sortBy.field == GuideDispatchField.ORIGEN && (
                    <ChevronDownIcon
                      className={classNames("w-4 h-4", sortBy.asc && "rotate-180")}
                    />
                  )}
                </div>
              </div>
            </th>
            <th
              className="px-4 py-2"
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>DESTINO</span>
              </div>
            </th>

            <th
              className="px-4 py-2"
              onClick={() => onChangeSortBy(GuideDispatchField.PESO)}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>PESO</span>
                <div className="h-4 w-4">
                  {sortBy.field == GuideDispatchField.PESO && (
                    <ChevronDownIcon
                      className={classNames("w-4 h-4", sortBy.asc && "rotate-180")}
                    />
                  )}
                </div>
              </div>
            </th>

            <th
              className="px-4 py-2"
              onClick={() => onChangeSortBy(GuideDispatchField.VOLUMEN)}
              style={{ minWidth: "5rem" }}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>VOLUMEN</span>
                <div className="h-4 w-4">
                  {sortBy.field == GuideDispatchField.VOLUMEN && (
                    <ChevronDownIcon
                      className={classNames("w-4 h-4", sortBy.asc && "rotate-180")}
                    />
                  )}
                </div>
              </div>
            </th>

            <th
              className="px-4 py-2"
              onClick={() => onChangeSortBy(GuideDispatchField.PIECES)}
            >
              <div className="flex gap-2 items-center cursor-pointer truncate">
                <span>Total Piezas</span>
                <div className="h-4 w-4">
                  {sortBy.field == GuideDispatchField.PIECES && (
                    <ChevronDownIcon
                      className={classNames("w-4 h-4", sortBy.asc && "rotate-180")}
                    />
                  )}
                </div>
              </div>
            </th>

            <th
              className="px-4 py-2"
              onClick={() => onChangeSortBy(GuideDispatchField.DATECREATE)}
            >
              <div className="flex gap-2 items-center cursor-pointer truncate">
                <span>FECHA CREACION</span>
                <div className="h-4 w-4">
                  {sortBy.field == GuideDispatchField.DATECREATE && (
                    <ChevronDownIcon
                      className={classNames("w-4 h-4", sortBy.asc && "rotate-180")}
                    />
                  )}
                </div>
              </div>
            </th>
            <th
              className="px-4 py-2"
              onClick={() => onChangeSortBy(GuideDispatchField.SALE)}
            >
              <div className="flex gap-2 items-center cursor-pointer truncate">
                <span>TRAKING / PRECINTO</span>
                <div className="h-4 w-4">
                  {sortBy.field == GuideDispatchField.SALE && (
                    <ChevronDownIcon
                      className={classNames("w-4 h-4", sortBy.asc && "rotate-180")}
                    />
                  )}
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
        {dispatchGuides.map((dispatchGuide, index) => {
            const selected = selectedGuide.some((s) => s.numGuia == dispatchGuide.numGuia);

            return (
              <tr
                key={dispatchGuide.numGuia}
                className={classNames(
                  "hover:bg-gray-200",
                  index % 2 == 0 && "bg-gray-100",
                  selected && "!bg-blue-200"
                )}
              >
                <th className="pl-2 py-2">
                  <FormCheckbox
                    id={`select-${dispatchGuide.numGuia}-${index}`}
                    name={`select-${dispatchGuide.numGuia}-${index}`}
                    label=""
                    onChange={() => onSelectGuide(dispatchGuide)}
                    checked={selected}
                  />
                </th>
                <td className="px-4 py-1 font-semibold">{dispatchGuide.numGuia}</td>
                  {/* <td className="px-4 py-1">{dispatchGuide.servicioID}</td> */}
                  <td className="px-4 py-1">{dispatchGuide.origen} </td>
                  <td className="px-4 py-1">{dispatchGuide.ciudadDestino} </td>
                  <td className="px-4 py-1">{dispatchGuide.peso}</td>
                  <td className="px-4 py-1">{dispatchGuide.volumen ?? "-"}</td>
                  <td className="px-4 py-1">{dispatchGuide.totalPiezas} Pieza {((dispatchGuide.totalPiezas??0) >= 1?"s":"")}</td>
                  <td className="px-4 py-1">{moment(dispatchGuide.fechaCreacion).format("DD/MM/YYYY")}</td>
                  <td className="px-4 py-1">{dispatchGuide.sealNumber}</td>
              </tr>
            );
          })}



          {dispatchGuides.length == 0 && (
            <tr>
              <td colSpan={7} className="px-4 py-4 text-center">
                Parece que aún no tienes preguías.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>    
  );
};
