import { FC } from "react";
import { MainPage, PageTitle, PrimaryButton } from "../../components";
import { PencilSquareIcon } from "@heroicons/react/24/outline";

const RecievedMessages: FC = () => {
  return (
    <MainPage>
      <PageTitle title="Mensajes Recibidos" onReload={() => {}} />

      <PrimaryButton className="w-full sm:w-32">
        <PencilSquareIcon className="w-5 h-5 mr-2" />
        Nuevo
      </PrimaryButton>

      <div className="px-4 py-6 mt-4 bg-white rounded-lg border shadow-sm">
        <p className="text-center text-gray-600">
          Disculpe, no tiene notificaciones pendientes.
        </p>
      </div>
    </MainPage>
  );
};

export default RecievedMessages;
