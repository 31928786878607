import { FormikErrors, useFormikContext } from "formik"
import { useEffect, useMemo, useRef, useState } from "react"

export const ScrollToFieldError = ({
    scrollBehavior = { behavior: 'smooth', block: 'center' },
  }) => {
    const { submitCount, isValid, errors } = useFormikContext()
  
    useEffect(() => {
      if (isValid) return
  
      const fieldErrorNames = getFieldErrorNames(errors)
      if (fieldErrorNames.length <= 0) return
  
      const element = document.querySelector(
        `input[name='${fieldErrorNames[0]}']`
      )
      
      if (!element) return
  
      // Scroll to first known error into view
      element.scrollIntoView(scrollBehavior as any)
  
      // Formik doesn't (yet) provide a callback for a client-failed submission,
      // thus why this is implemented through a hook that listens to changes on
      // the submit count.
    }, [submitCount]) // eslint-disable-line react-hooks/exhaustive-deps
  
    return null
  }
  
  export const getFieldErrorNames = (formikErrors:any) => {
    const transformObjectToDotNotation = (obj:any, prefix = '', result : string[] = [] ) => {
      Object.keys(obj).forEach((key) => {
        const value = obj[key]
        if (!value) return
  
        const nextKey = (prefix ? `${prefix}.${key}` : key ) ?? ""
        if (typeof value == 'object') {
          transformObjectToDotNotation(value, nextKey, result)
        } else {
          result.push(nextKey)
        }
      })
  
      return result
    }
  
    return transformObjectToDotNotation(formikErrors)
  }


  export const ScrollToError = (formikCount?: any) => {
    const hasLoggedError = useRef(false);
    useMemo(() =>{
        if(formikCount.formikCount){
          if(formikCount.data.consignee != undefined && formikCount.data.consignee?.accountSelected != undefined){
            window.scrollTo(0, 0);
          }
          else
            if(formikCount.data?.consignee != undefined && Object.keys(formikCount.data?.consignee).length  > 0){
                for (let i = 0; i < Object.keys(formikCount.data?.consignee).length; i++)   {
                    let keys = Object.keys(formikCount.data?.consignee);
                    const element = document.querySelector(
                        `input[name='consignee.${keys[i]}']`
                      )
                      if (!element) return
                  
                      // Scroll to first known error into view
                      element.scrollIntoView({ behavior: 'smooth', block: 'center' })
                }
            } 
            else
            if(formikCount.data.shipment != undefined && formikCount.data.shipment.pieces != undefined){
                window.scrollTo(0, document.body.scrollHeight - 500);
            }
            else
            if(formikCount.data?.shipment != undefined && Object.keys(formikCount.data?.shipment).length  > 0){
                for (let i = 0; i < Object.keys(formikCount.data?.shipment).length; i++)   {
                    let keys = Object.keys(formikCount.data?.shipment);
                    const element = document.querySelector(
                        `input[name='shipment.${keys[i]}']`
                      )
                      if (!element) return
                  
                      // Scroll to first known error into view
                      element.scrollIntoView({ behavior: 'smooth', block: 'center' })
                }
            }
                       
            hasLoggedError.current = true;
        }

    },[formikCount.formikCount])
    return null
  }
  

  export const ScrollToErrorPreShipment = (formikCount?: any) => {
    const hasLoggedError = useRef(false);    
    useMemo(() =>{
        if(formikCount.formikCount){
          if(formikCount.data.consignee != undefined && formikCount.data.consignee?.accountSelected != undefined){
            window.scrollTo(0, 0);
          }
          else
            if(formikCount.data?.consignee != undefined && Object.keys(formikCount.data?.consignee).length  > 0){
                for (let i = 0; i < Object.keys(formikCount.data?.consignee).length; i++)   {
                    let keys = Object.keys(formikCount.data?.consignee);
                    const element = document.querySelector(
                        `input[name='consignee.${keys[i]}']`
                      )
                      if (!element) return
                  
                      // Scroll to first known error into view
                      element.scrollIntoView({ behavior: 'smooth', block: 'center' })
                }
            } 
            else
            if(formikCount.data.preShipment != undefined && formikCount.data.preShipment.pieces != undefined){
                window.scrollTo(0, document.body.scrollHeight - 500);
            }
            else
            if(formikCount.data?.preShipment != undefined && Object.keys(formikCount.data?.preShipment).length  > 0){
                for (let i = 0; i < Object.keys(formikCount.data?.preShipment).length; i++)   {
                    let keys = Object.keys(formikCount.data?.preShipment);
                    const element = document.querySelector(
                        `input[name='preShipment.${keys[i]}']`
                      )
                      if (!element) return
                  
                      // Scroll to first known error into view
                      element.scrollIntoView({ behavior: 'smooth', block: 'center' })
                }
            }
                       
            hasLoggedError.current = true;
        }

    },[formikCount.formikCount])
    return null
  }
  