import { FC, useMemo, useState } from "react";
import classNames from "classnames";
import { useLocation, useNavigate } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
export interface NavigationSubItem {
  name: string;
  href: string;
}
export interface NavigationItem {
  name: string;
  href?: string;
  icon?: string;
  iconRender: React.ElementType;
  children?: NavigationSubItem[];
}

interface NavSidebarProps {
  navigation: NavigationItem[];
}

export const NavSidebar: FC<NavSidebarProps> = ({ navigation }) => {
  const { pathname: path } = useLocation();
  const [hovered, setHovered] = useState(false);
  const navigate = useNavigate();

  const currentItem = useMemo(() => "/" + path.split("/")[1], [path]);

  return (
    <div
      style={{ width: "3.3rem" }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      className="flex flex-col gap-y-5 z-10 overflow-y-auto bg-white border border-r border-y-0 border-gray-200 p-6 overflow-x-hidden lg:!w-64 hover:!w-64 fixed lg:absolute h-full left-0"
    >
      <nav className="flex flex-1 flex-col">
        <ul className="-mx-4 lg:-mx-2 space-y-0.5">
          {navigation.map((item) => (
            <li key={item.name}>
              {!item.children ? (
                <a
                  onClick={() => navigate(item.href as string)}
                  className={classNames(
                    item.href == currentItem
                      ? "bg-gray-100"
                      : "hover:bg-gray-50",
                    "group flex items-center gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-gray-700",
                    !hovered && "pointer-events-none"
                  )}
                >
                  <item.iconRender
                    className="h-5 w-5 shrink-0 text-main-500"
                    aria-hidden="true"
                  />
                  <span className="ml-1">{item.name}</span>
                </a>
              ) : (
                <Disclosure as="div">
                  {({ open }) => (
                    <div
                      className={classNames(!hovered && "pointer-events-none")}
                    >
                      <Disclosure.Button className="flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold text-gray-700 hover:bg-gray-50">
                        <item.iconRender
                          className="h-5 w-5 shrink-0 text-main-500"
                          aria-hidden="true"
                        />

                        <span className="ml-1">{item.name}</span>

                        <ChevronRightIcon
                          className={classNames(
                            open && "rotate-90",
                            "ml-auto h-5 w-5 shrink-0 text-gray-600"
                          )}
                          aria-hidden="true"
                        />
                      </Disclosure.Button>

                      <Disclosure.Panel as="ul" className="mt-1 px-2">
                        {item.children!.map((subItem) => (
                          <li key={subItem.name}>
                            <Disclosure.Button
                              as="a"
                              onClick={() => navigate(subItem.href as string)}
                              className={classNames(
                                subItem.href == currentItem
                                  ? "bg-gray-100"
                                  : "hover:bg-gray-50",
                                "block rounded-md py-2 pr-2 pl-9 text-sm leading-6 text-gray-700",
                                !hovered && "pointer-events-none"
                              )}
                            >
                              {subItem.name}
                            </Disclosure.Button>
                          </li>
                        ))}
                      </Disclosure.Panel>
                    </div>
                  )}
                </Disclosure>
              )}
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};
