import { FC, useMemo } from 'react';
import { TrashIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { ItemInterface } from '../../interfaces';
import {
  currencyExchangeText,
  useCurrencyExchanges,
} from '../../interfaces/Shipment/currencyExchange';
import { formatAmount } from '../../utils/format';
import { FormSelect } from '../FormFields/FormSelect';

interface ShipmentItemTableProps {
  items: ItemInterface[];
  total?: number;
  distance?: number;
  declaredValue?: number;
  ipostel?: number;
  availableItems?: ItemInterface[];
  editable?: boolean;
  hideSelect?: boolean;
  showTaxes?: boolean;
  setItems?: (items: ItemInterface[]) => void;
}
const ShipmentItemTable: FC<ShipmentItemTableProps> = ({
  items,
  total,
  distance,
  declaredValue,
  ipostel = 0,
  availableItems = [],
  editable = false,
  hideSelect = false,
  showTaxes = false,
  setItems = () => {},
}) => {
  const exchanges = useCurrencyExchanges();
  const addItem = (item: ItemInterface) => {
    setItems([...items, item]);
  };

  const deleteItem = (item: ItemInterface) => {
    const newItems = [...items];
    newItems.splice(newItems.indexOf(item), 1);
    setItems(newItems);
  };

  const mandatoryItems = useMemo(() => {
    return items.filter((item) => item.mandatory);
  }, [items]);

  const optionalItems = useMemo(() => {
    return items.filter((item) => !item.mandatory);
  }, [items]);

  const absoluteTotal = useMemo(() => {
    return items
      .filter((item) => !item.rate.isPercentage)
      .reduce((acc, item) => acc + item.rate.value, 0);
  }, [items]);

  const totalToShow = useMemo(() => {
    if (total !== undefined) return total;

    const percentageTotal = items
      .filter((item) => item.rate.isPercentage)
      .reduce((acc, item) => acc + item.rate.value, 0);

    return absoluteTotal * (1 + percentageTotal / 100);
  }, [items, absoluteTotal, total]);

  const iva = useMemo(() => {
    return +items.reduce((acc, item) => acc + item.rate.iva, 0).toFixed(2);
  }, [items]);

  const totalWithTaxes = useMemo(() => {
    return totalToShow + iva + ipostel;
  }, [totalToShow, iva, ipostel]);
  return (
    <div className="flex flex-1 flex-col">
      <table className="table-auto">
        <thead>
          <tr>
            <th className="text-left px-4 py-2 font-semibold text-xs">
              SERVICIO
            </th>
            <th className="text-left px-4 py-2 font-semibold text-xs">
              DETALLES
            </th>
            <th className="text-right px-4 py-2 font-semibold text-xs">
              COSTO
            </th>
            {editable && (
              <th className="text-left font-semibold text-xs w-16" />
            )}
          </tr>
        </thead>
        <tbody>
          {mandatoryItems.map((item, i) => {
            const cost = item.rate.isPercentage
              ? (item.rate.value * absoluteTotal) / 100
              : item.rate.value;

            return (
              <tr key={i} className={`${i % 2 === 0 && 'bg-gray-50'}`}>
                <td className="text-xs px-4 py-2 font-semibold truncate">
                  {item.name}
                </td>
                <td className="text-xs px-4 py-2 font-semibold text-gray-600 truncate">
                  <p
                    className={classNames(
                      !item.name.includes('Flete') && 'hidden'
                    )}
                  >
                    Zona Tarifaria: {item.rate.tierName}
                  </p>
                  <p
                    className={classNames(
                      (distance === undefined || item.id !== '21') && 'hidden'
                    )}
                  >
                    Distancia: {Number(distance ?? 0).toFixed(2)} km
                  </p>
                  <p
                    className={classNames(
                      (declaredValue === 0 ||
                        declaredValue === undefined ||
                        item.id !== '20') &&
                        'hidden'
                    )}
                  >
                    Valor declarado: {formatAmount(declaredValue ?? 0)} $
                  </p>
                </td>
                <td className="text-right text-xs px-4 py-2 font-semibold truncate">
                  <div className="flex flex-col items-end">
                    <p className="text-xs font-semibold text-gray-600">
                      {currencyExchangeText(cost, exchanges, 'USD')}
                    </p>
                    <p className="text-xs text-gray-400 ">
                      {currencyExchangeText(cost, exchanges, 'BS')}
                    </p>
                  </div>
                </td>
                {editable && (
                  <td
                    className="py-2 flex flex-1 h-full gap-1 justify-end items-center"
                    style={{ width: 'fit-content ' }}
                  />
                )}
              </tr>
            );
          })}

          {optionalItems.map((item, i) => {
            const cost = item.rate.isPercentage
              ? (item.rate.value * absoluteTotal) / 100
              : item.rate.value;

            return (
              <tr
                key={i}
                className={`${
                  (mandatoryItems.length + i) % 2 === 0 && 'bg-gray-50'
                }`}
              >
                <td className="text-xs px-4 py-2 font-semibold truncate">
                  {item.name}
                </td>
                <td className="text-xs px-4 py-2 font-semibold truncate"></td>
                <td className="text-right text-xs px-4 py-2 font-semibold truncate">
                  <div className="flex flex-col items-end">
                    <p className="text-xs font-semibold text-gray-600">
                      {currencyExchangeText(cost, exchanges, 'USD')}
                    </p>
                    <p className="text-xs text-gray-400 ">
                      {currencyExchangeText(cost, exchanges, 'BS')}
                    </p>
                  </div>
                </td>
                {editable && (
                  <td className="justify-center items-center">
                    <div
                      onClick={() => deleteItem(item)}
                      className="rounded-full bg-red-100 hover:bg-red-200 hover:cursor-pointer p-2"
                      style={{ width: 'fit-content ' }}
                    >
                      <TrashIcon
                        className="h-5 w-5 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                  </td>
                )}
              </tr>
            );
          })}

          <tr
            className={`${
              (mandatoryItems.length + optionalItems.length) % 2 === 0 &&
              'bg-gray-50'
            }`}
          >
            <td className="text-xs px-4 py-2 font-semibold truncate">
              SUBTOTAL GUIA
            </td>

            <td></td>

            <td className="text-right text-xs px-4 py-2 font-semibold truncate">
              <div className="flex flex-col items-end">
                <p className="text-xs font-semibold text-gray-600">
                  {currencyExchangeText(totalToShow, exchanges, 'USD')}
                </p>
                <p className="text-xs text-gray-400 ">
                  {currencyExchangeText(totalToShow, exchanges, 'BS')}
                </p>
              </div>
            </td>

            {editable && <td className="justify-center items-center" />}
          </tr>

          <tr
            className={classNames(
              (mandatoryItems.length + optionalItems.length) % 2 === 1 &&
                'bg-gray-50',
              !showTaxes && 'hidden',
              'border-t-2 border-gray-400'
            )}
          >
            <td className="text-xs px-4 py-2 font-semibold truncate">
              IVA (16%)
            </td>

            <td></td>

            <td className="text-right text-xs px-4 py-2 font-semibold truncate">
              <div className="flex flex-col items-end">
                <p className="text-xs font-semibold text-gray-600">
                  {currencyExchangeText(iva, exchanges, 'USD')}
                </p>
                <p className="text-xs text-gray-400 ">
                  {currencyExchangeText(iva, exchanges, 'BS')}
                </p>
              </div>
            </td>
          </tr>

          <tr
            className={classNames(
              (mandatoryItems.length + optionalItems.length) % 2 === 0 &&
                'bg-gray-50',
              !showTaxes && 'hidden'
            )}
          >
            <td className="text-xs px-4 py-2 font-semibold truncate">
              Franqueo Postal
            </td>

            <td></td>

            <td className="text-right text-xs px-4 py-2 font-semibold truncate">
              <div className="flex flex-col items-end">
                <p className="text-xs font-semibold text-gray-600">
                  {currencyExchangeText(ipostel, exchanges, 'USD')}
                </p>
                <p className="text-xs text-gray-400 ">
                  {currencyExchangeText(ipostel, exchanges, 'BS')}
                </p>
              </div>
            </td>
          </tr>

          <tr
            className={classNames(
              (mandatoryItems.length + optionalItems.length) % 2 === 1 &&
                'bg-gray-50',
              !showTaxes && 'hidden'
            )}
          >
            <td className="text-xs px-4 py-2 font-semibold truncate">
              SUBTOTAL CON IMPUESTOS
            </td>

            <td></td>

            <td className="text-right text-xs px-4 py-2 font-semibold truncate">
              <div className="flex flex-col items-end">
                <p className="text-xs font-semibold text-gray-600">
                  {currencyExchangeText(totalWithTaxes, exchanges, 'USD')}
                </p>
                <p className="text-xs text-gray-400 ">
                  {currencyExchangeText(totalWithTaxes, exchanges, 'BS')}
                </p>
              </div>
            </td>
          </tr>

          <tr
            className={classNames(
              (mandatoryItems.length + optionalItems.length) % 2 === 1 &&
                'bg-gray-50'
            )}
          >
            <td className="text-xs px-4 py-0 font-semibold truncate"></td>
            <td></td>
            <td className="text-right text-xs px-4 py-2 font-semibold truncate">
              <div className="flex flex-col items-end">
                <p className="text-xs font-semibold text-gray-600">
                  <span style={{ color: 'red' }}>*</span>Estos precios son
                  estimados
                </p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      {editable && !hideSelect && (
        <div className="flex w-full items-center mt-4 gap-2 hidden">
          <div className="flex flex-1">
            <FormSelect
              id="ServicesSelect"
              label=""
              selected={undefined}
              name="shipment-items"
              placeholder="Agregar servicio..."
              options={availableItems}
              optionString={(item: any) => item.name}
              onSelectOption={(item: any) => addItem(item)}
              RenderOption={({ option }: any) => {
                const cost = option.rate.isPercentage
                  ? option.rate.value * absoluteTotal
                  : option.rate.value;

                return (
                  <div className="flex flex-1 items-center justify-between gap-2">
                    <p className="text-xs font-semibold truncate">
                      {option.name}
                    </p>
                    <div className="flex flex-col items-end">
                      <p className="text-xs ">
                        {currencyExchangeText(cost, exchanges, 'USD')}
                      </p>
                      <p className="text-xs ">
                        {currencyExchangeText(cost, exchanges, 'BS')}
                      </p>
                    </div>
                  </div>
                );
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ShipmentItemTable;
