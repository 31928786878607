import { GeneralTypeInterface } from "../interfaces/Dto/GeneralTypeInterface";
import { AuthenticationServices } from "./AuthenticationServices";
import axios from "axios";
import { ListResponse } from "../interfaces/Shipment/ListResponseDto";
const {REACT_APP_URL_PATH_API} = process.env;

export const GeneralTypePhoneServices = async (
  CountryId: string
): Promise<ListResponse<GeneralTypeInterface>> => {
    try {
        const token = await AuthenticationServices();  
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
        const response = await axios.get(`${REACT_APP_URL_PATH_API}/ListofType/listCodePhone?CountryId=${CountryId}`);
        if (response.status === 200) {
          var datamap = response.data;
          return datamap;
        }    
      } catch (error) {}

      return [] as unknown as ListResponse<GeneralTypeInterface>;
};
