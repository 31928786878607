import Papa from "papaparse";
import * as XLSX from "xlsx";
import { ImportInterface } from "../interfaces/Import/importInterfase";
import { v4 } from "uuid";

export const importShipmentsFromCSV = async (file: File) => {
  const result: ImportInterface[] = await new Promise((resolve, reject) => {
    Papa.parse<ImportInterface>(file, {
      header: true,
      complete: (results) => {
        resolve(results.data);
      },
      error: () => {
        reject([]);
      },
    });
  });

  return result;
};

export const importShipmentsFromXLSX = async (file: File) => {
  const result: ImportInterface[] = await new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const bufferArray = e.target?.result;
      const wb = XLSX.read(bufferArray, { type: "buffer" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json<ImportInterface>(ws, {
        blankrows: false,
        header: 'A',
        raw: true,
        rawNumbers: true
      });
      data.forEach((row) => { 
          row.id= v4()
      });
      resolve(data);
    };
    reader.readAsArrayBuffer(file);
  });
  return result;
};
