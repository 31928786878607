import { useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import { FormikErrors } from "formik";
import { FormLabel } from "./FormLabel";
import { Spinner } from "../Atoms/Spinner";
import { inputClassName } from "./FormText";
import { Combobox } from "@headlessui/react";
import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/outline";

interface FormSearchProps<T>
  extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  name: string;
  options: T[];
  value: string;
  label?: string;
  spinner?: boolean;
  notFound?: string;
  hideGlass?: boolean;
  unselect?: boolean;
  isRequired?: boolean;
  labelClassname?: string;
  containerClassname?: string;
  widthClassName?: string;
  labelContainerClassname?: string;
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[];
  onSelectOption?: (opt?: T) => void;
  onChangeFocus?: (focus: boolean) => void;
  onFilter?: (opt: T, value: string) => boolean;
  LastOption?: () => JSX.Element;
  RenderOption: ({ option }: { option: T }) => JSX.Element;
  isTabled?: boolean;
}
export const FormSearch = <T extends any>({
  label = "",
  isRequired,
  name,
  value,
  options,
  error,
  disabled,
  style,
  className,
  placeholder,
  labelClassname,
  containerClassname,
  labelContainerClassname,
  widthClassName,
  spinner = false,
  unselect = false,
  hideGlass = false,
  notFound = "Elementos no encontrados",
  onChange = () => {},
  onSelectOption = () => {},
  onFilter = () => true,
  onChangeFocus = () => {},
  RenderOption,
  LastOption = undefined,
  isTabled,
  ...props
}: FormSearchProps<T>) => {
  const ref = useRef<HTMLDivElement>(null);
  const [focused, setFocused] = useState(false);

  const filteredOptions = useMemo(() => {
    return options.filter((option) => onFilter(option, value));
  }, [options, value, onFilter]);

  const displayDropdown = useMemo(() => {
    return (
      (filteredOptions.length > 0 && focused) ||
      (focused && value !== "" && filteredOptions.length == 0) ||
      (value !== "" && focused && !!LastOption)
    );
  }, [filteredOptions, value, focused, LastOption]);

  const Options = useMemo(() => {
    return (
      <div
        className={classNames(
          "w-full absolute top-full z-40 divide-y divide-gray-100 overflow-hidden rounded-b-xl bg-white shadow-sm ring-1 ring-black ring-opacity-5",
          !displayDropdown && "hidden"
        )}
      >
        {spinner && (
          <div className="flex flex-1 items-center p-4">
            <Spinner size="2rem" />
          </div>
        )}

        {!spinner && focused && filteredOptions.length > 0 && (
          <Combobox.Options
            static
            className={classNames(
              "max-h-40 " +
                widthClassName +
                "max-h-40 scroll-py-2 overflow-y-auto overflow-x-hidden py-2 text-sm text-gray-800",
              ""
            )}
          >
            {filteredOptions.map((option, index) => (
              <Combobox.Option
                key={index}
                value={option}
                className={({ active }) =>
                  classNames(
                    "cursor-pointer px-4 py-2",
                    active && "bg-main-500 text-white"
                  )
                }
              >
                <RenderOption option={option} />
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}

        {!spinner && focused && value !== "" && filteredOptions.length == 0 && (
          <p className="p-4 text-sm text-gray-500">{notFound}</p>
        )}

        {focused && !!LastOption && (
          <Combobox.Options
            static
            className="max-h-72 scroll-py-2 overflow-y-auto text-sm text-gray-800"
          >
            <Combobox.Option
              value={undefined}
              className={({ active }) =>
                classNames(
                  "cursor-pointer px-4",
                  active && "bg-main-500 text-white"
                )
              }
            >
              <LastOption />
            </Combobox.Option>
          </Combobox.Options>
        )}
      </div>
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spinner, focused, filteredOptions, value]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setFocused(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, setFocused]);

  useEffect(() => {
    onChangeFocus(focused);
  }, [focused, onChangeFocus]);

  return (
    <div className={containerClassname}>
      <FormLabel
        label={label}
        htmlFor={name}
        isRequired={isRequired}
        className={labelClassname}
        containerClassname={labelContainerClassname}
      />

      <div ref={ref} className="relative flex flex-1 flex-col" style={style}>
        <Combobox
          onChange={(option: T) => {
            onSelectOption(option);
            setFocused(false);
          }}
        >
          <div className="relative w-full">
            {!unselect && !disabled && !hideGlass && (
              <MagnifyingGlassIcon
                className="cursor-pointer absolute right-3 h-5 w-5 text-gray-400 hover:text-main-500"
                aria-hidden="true"
                onClick={() => setFocused(true)}
                style={{ top: "calc(50% - 0.6rem)" }}
              />
            )}

            {unselect && !disabled && (
              <XMarkIcon
                className="cursor-pointer absolute right-3 h-5 w-5 text-gray-400 hover:text-main-500"
                aria-hidden="true"
                onClick={() => {
                  onSelectOption(undefined);
                  setFocused(false);
                }}
                style={{ top: "calc(50% - 0.6rem)" }}
              />
            )}

            <input
              {...props}
              name={name}
              value={value}
              disabled={disabled}
              autoComplete="off"
              placeholder={disabled ? "" : placeholder}
              onChange={onChange}
              onFocus={() => setFocused(true)}
              className={classNames(
                inputClassName,
                "pr-11 placeholder:text-gray-400 ring-0 focus:ring-0",
                displayDropdown && "rounded-b-none",
                disabled && "bg-gray-100 text-gray-500",
                !!error && "ring-red-500",
                className
              )}
            />
          </div>

          {Options}
        </Combobox>

        {typeof error == "string" &&
          !!error &&
          (isTabled == false || isTabled == undefined) && (
            <span className="mt-2 text-sm text-red-500">{error}</span>
          )}
        {Array.isArray(error) &&
          (isTabled == false || isTabled == undefined) && (
            <span className="mt-2 text-sm text-red-500">
              {error.join(", ")}
            </span>
          )}
      </div>

      {typeof error == "string" && !!error && isTabled && (
        <span className="mt-2 text-sm text-red-500">{error}</span>
      )}
      {Array.isArray(error) && isTabled && (
        <span className="mt-2 text-sm text-red-500">{error.join(", ")}</span>
      )}
    </div>
  );
};
