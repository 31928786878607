import { FormText } from "../FormFields/FormText";
import { SenderInterface, TaxIdentificationTypeInterface } from "../../interfaces";
import { useAppSelector } from "../../store/hooks";
import { FormSelect } from "../FormFields/FormSelect";
import { FormikErrors, FormikProps, FormikTouched } from "formik";
import classNames from "classnames";

interface SenderField {
  sender: {
    fullName: string;
    abbreviationName?: TaxIdentificationTypeInterface;
    identificationNumber?: string;
    valueDeclarate ?:string;
  };
}
interface SenderFormProps<T> {
  formik: FormikProps<T & SenderField>;
  hiddeIdentification?: boolean;
}
export const SenderForm = <T extends any>({
  formik,
  hiddeIdentification = false,
}: SenderFormProps<T>) => {
  const identificationTypes = useAppSelector(
    (state) => state.inmutable.taxIdentificationTypes
  );
  const errors = formik.errors.sender as FormikErrors<SenderInterface> | undefined;
  const touched = formik.touched.sender as FormikTouched<SenderInterface> | undefined;

  return (
    <div className="flex h-full flex-col gap-4">
      <div className={classNames(hiddeIdentification && "hidden")}>
        <FormSelect
          id="sender.abbreviationName"
          name="sender.abbreviationName"
          label="Tipo de Documento"
          selected={formik.values.sender.abbreviationName}
          options={identificationTypes}
          error={
            touched?.abbreviationName && errors?.abbreviationName
              ? errors?.abbreviationName
              : undefined
          }
          onBlur={formik.handleBlur}
          className="!h-8"
          labelClassname="!text-xs"
          labelContainerClassname="flex sm:!justify-end sm:w-32"
          containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
          style={{ maxWidth: "10rem" }}
          optionString={(option) => option.abbreviationName.slice(0, 1)}
          onSelectOption={(option) =>
            formik.setFieldValue("sender.abbreviationName", option)
          }
          disabled={true}
        />
      </div>

      <div className={classNames(hiddeIdentification && "hidden")}>
        <FormText
          id="sender.identificationNumber"
          name="sender.identificationNumber"
          label="Nro. Documento"
          value={formik.values.sender.identificationNumber}
          error={
            touched?.identificationNumber && errors?.identificationNumber
              ? errors?.identificationNumber
              : undefined
          }
          onChange={(e) => {
            let re: RegExp;
            if (formik.values.sender.abbreviationName?.abbreviationName !== "P-") {
              // Only numbers
              re = /^[0-9\b]{0,10}$/;
            } else {
              // Only numbers and letters
              re = /^[a-zA-Z0-9\b]{0,10}$/;
            }

            if (e.target.value == "" || re.test(e.target.value)) {
              formik.handleChange(e);
            }
          }}
          onBlur={formik.handleBlur}
          className="!h-8"
          labelClassname="!text-xs"
          labelContainerClassname="flex sm:!justify-end sm:w-32"
          containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
          style={{ maxWidth: "10rem" }}
          disabled={true}
        />
      </div>

      <FormText
        id="sender.fullName"
        name="sender.fullName"
        label="Persona que Remite"
        value={formik.values.sender.fullName}
        error={touched?.fullName && errors?.fullName ? errors?.fullName : undefined}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        className="!h-8"
        labelClassname="!text-xs"
        labelContainerClassname="flex sm:!justify-end sm:w-32"
        containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
        style={{ maxWidth: "15rem" }}
      />
    </div>
  );
};
