import { FC, useState } from 'react';
import {
  GetPaymentMethodAccount,
  forgotPassword,
  getAccounSiteByUser,
  getAccount,
  getBusinessUnits,
  loginService,
} from '../../services';
import { useAppDispatch } from '../../store/hooks';
import { login, setClient, setPaymentMethod } from '../../store/slices';
import logo from '../../assets/images/TEALCA-logo.jpg';

import {
  AccountSiteOption,
  ConfirmModalStatus,
  FormSearch,
  LoginForm,
  LoginFormValues,
  ModalLoading,
  PrimaryButton,
  SecondaryButton,
} from '../../components';
import {
  AccountInterface,
  PaymentMethodAccountDto,
  UserInterface,
} from '../../interfaces';
import { filterAccountBusinessUnit } from '../../utils/filters';

enum LoginPhase {
  LOGIN,
  ACCOUNT_SITE_SELECTION,
  NOT_AVAILABLE_ACCOUNT_SITE,
}

const Login: FC = () => {
  const [userData, setUserData] = useState<UserInterface>({
    id: '',
    number: 0,
    abbreviationName: '',
    identificationNumber: '',
    name: '',
    lastName: '',
    email: '',
    phone: '',
    login: '',
    profileName: '',
    profileID: 0,
    businessUnits: [],
  });
  const [accountSiteList, setAccountSiteList] = useState<AccountInterface[]>(
    []
  );
  const [accountSiteSelected, setAccountSiteSelected] =
    useState<AccountInterface>();
  const [loginPhase, setLoginPhase] = useState(LoginPhase.LOGIN);
  const dispatch = useAppDispatch();
  let [loading, setLoading] = useState(false);
  let [loadingStatus, setLoadingStatus] = useState(
    ConfirmModalStatus.PROCESSING
  );
  let [loadingTitle, setLoadingTitle] = useState('Cargando...');

  const [businessUnitSearch, setBusinessUnitSearch] = useState('');

  const onLogin = async (user: LoginFormValues) => {
    setLoadingStatus(ConfirmModalStatus.PROCESSING);
    setLoadingTitle('Autenticando...');
    setLoading(true);

    const response = await loginService(
      user.id,
      user.password,
      setLoadingTitle
    );
    if (!!response) {
      let AccounstSite = await getAccounSiteByUser(response.id);
      const businessUnits = await getBusinessUnits();
      setUserData(response);

      if (AccounstSite.length === 0) {
        setLoginPhase(LoginPhase.LOGIN);
        setLoadingStatus(ConfirmModalStatus.ERROR);
        setLoadingTitle(
          'Usuario Inactivo. Contacte al equipo de Finanzas o Soporte Técnico de Tealca'
        );
      }

      if (AccounstSite.length === 1) {
        setLoginPhase(LoginPhase.LOGIN);
        let accountSite = AccounstSite[0];

        let businessUnitsSelected = businessUnits!.find(
          (t) => t.buCode === accountSite?.BuCodeSource
        )!;
        let accountSiteInfo = await getAccount(accountSite.id);

        accountSite = {
          ...accountSite,
          businessUnit: businessUnitsSelected,
          cityCode: accountSite.cityCode,
          abbreviationName: accountSiteInfo?.abbreviationName ?? '',
          identificationNumber: accountSiteInfo?.identificationNumber ?? '',
          listAccountPhone: accountSiteInfo?.listAccountPhone ?? [],
          listAccountEmail: accountSiteInfo?.listAccountEmail ?? [],
        };

        await saveSession(response, accountSite);
        setLoadingStatus(ConfirmModalStatus.PROCESSING);
        setLoadingTitle('Accediendo...');
      }

      if (AccounstSite.length > 1) {
        setLoadingStatus(ConfirmModalStatus.SUCCESS);
        setLoadingTitle('¡Accediendo! Seleccione una tienda...');
        setAccountSiteList(AccounstSite);
        setLoginPhase(LoginPhase.ACCOUNT_SITE_SELECTION);
      }
    } else {
      setLoadingStatus(ConfirmModalStatus.ERROR);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  };

  const onRest = async (id: string) => {
    setLoadingStatus(ConfirmModalStatus.PROCESSING);
    setLoadingTitle('Validando información...');
    setLoading(true);

    if (id !== undefined && id.length > 0) {
      var userReset = await forgotPassword(id, '020');
      if (userReset.didError) {
        setLoadingStatus(ConfirmModalStatus.ERROR);
        setLoadingTitle(userReset.errorMessage);
        setTimeout(() => {
          setLoading(false);
        }, 3000);
      } else {
        setLoadingStatus(ConfirmModalStatus.SUCCESS);
        setLoadingTitle(userReset.message);
      }
    }
  };

  const saveSession = async (
    user: UserInterface,
    accountSite: AccountInterface
  ) => {
    let accountSiteInfo = await getAccount(accountSite.id);
    const businessUnits = await getBusinessUnits();
    let businessUnitsSelected = businessUnits!.find(
      (t) => t.buCode === accountSite?.BuCodeSource
    )!;

    let paymentMetod = await GetPaymentMethodAccount(accountSite.id);
    if (paymentMetod.model === undefined || paymentMetod.model?.length === 0) {
      setLoginPhase(LoginPhase.LOGIN);
      setLoadingStatus(ConfirmModalStatus.ERROR);
      setLoadingTitle('Por favor. Contacte al equipo de Tealca');
    }
    setLoadingStatus(ConfirmModalStatus.SUCCESS);
    setLoadingTitle('¡Bienvenidos!');
    setLoading(true);
    accountSite = {
      ...accountSite,
      businessUnit: businessUnitsSelected,
      abbreviationName: accountSiteInfo?.abbreviationName ?? '',
      identificationNumber: accountSiteInfo?.identificationNumber ?? '',
      listAccountPhone: accountSiteInfo?.listAccountPhone ?? [],
      listAccountEmail: accountSiteInfo?.listAccountEmail ?? [],
      fiscalAddress: accountSiteInfo?.fiscalAddress,
    };
    dispatch(login(user));
    dispatch(
      setClient({
        ...accountSite,
        listAuthorizingAccount: accountSiteList,
      })
    );

    dispatch(setPaymentMethod(paymentMetod.model as PaymentMethodAccountDto[]));

    setLoadingStatus(ConfirmModalStatus.SUCCESS);
    setLoadingTitle('¡Bienvenidos!');

    //navigate("/dashboard");
  };

  return (
    <main
      id="auth-main"
      className="flex items-center justify-center absolute inset-0 w-full h-full"
    >
      <div
        className="flex flex-col bg-white rounded m-4 sm:m-8"
        style={{ maxWidth: '37rem' }}
      >
        {/* Header */}
        <div className="py-1.5 flex flex-1 justify-center">
          <h1 className="text-lg text-center font-bold text-main-500">
            Bienvenido a MiTealca
          </h1>
        </div>
        <hr />

        {/* Body */}

        {loginPhase === LoginPhase.LOGIN && (
          <div className="flex flex-1 px-2 mt-4">
            <div className="flex-1 items-center justify-center p-2 hidden sm:flex">
              <img src={logo} alt="Tealca logo" width="230" height="230" />
            </div>

            <div className="flex flex-1 flex-col px-2 py-5 bg-gray-100 border rounded shadow-inner">
              <LoginForm onSubmit={onLogin} onRecoversPassword={onRest} />
            </div>
          </div>
        )}

        {loginPhase === LoginPhase.ACCOUNT_SITE_SELECTION && (
          <>
            <div className="flex flex-1 px-2 mt-4">
              <div className="flex flex-1 flex-col px-2 py-5 bg-gray-100 border rounded shadow-inner">
                <FormSearch
                  id="businessUnit"
                  name="businessUnit"
                  placeholder="Seleccione la cuenta de un cliente"
                  value={businessUnitSearch}
                  options={accountSiteList}
                  unselect={!!accountSiteSelected}
                  labelClassname="!text-xs"
                  onChange={(e) => setBusinessUnitSearch(e.target.value)}
                  onSelectOption={(option) => {
                    setBusinessUnitSearch(option?.accountFullName ?? '');
                    setAccountSiteSelected(option);
                  }}
                  onFilter={filterAccountBusinessUnit}
                  RenderOption={({ option }) => (
                    <AccountSiteOption {...option} />
                  )}
                />
                <div className="flex flex-col gap-12">
                  <div className="flex flex-col w-full gap-4">
                    <PrimaryButton
                      className="w-full mt-12"
                      onClick={() => {
                        if (!!accountSiteSelected) {
                          saveSession(userData, accountSiteSelected);
                        }
                      }}
                    >
                      Iniciar sesión
                    </PrimaryButton>

                    <SecondaryButton
                      className="w-full"
                      onClick={() => setLoginPhase(LoginPhase.LOGIN)}
                    >
                      Volver
                    </SecondaryButton>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {/* Footer */}

        <div className="p-4">
          <span
            className="block text-gray-900 leading-1"
            style={{ fontSize: '0.65rem' }}
          >
            “…Los derechos de la propiedad intelectual y/o industrial sobre la
            información contenida en este software (textos, diseños, gráficos,
            programación...) son de titularidad de Transferencias y Encomiendas
            Angulo Lopez C.A, (TEALCA), la cual se reserva todos los derechos.
            Está autorizado el uso personal, no comercial del mismo; Se prohíbe
            expresamente todo uso o reproducción comercial de cualquiera de los
            textos, imágenes o materiales de cualquier tipo que se encuentren en
            este software, sin autorización expresa y por escrito de (TEALCA),
            Las marcas o signos distintivos, logotipos y en general símbolos
            distintivos de cualquier naturaleza que aparezcan en este software
            son propiedad de (TEALCA) y están protegidos por la legislación
            vigente aplicable...”
          </span>
        </div>
        <ModalLoading
          open={loading}
          title={loadingTitle}
          status={loadingStatus}
          setOpen={setLoading}
          onPrint={() => {}}
          timeOut={3000}
        />
      </div>
    </main>
  );
};

export default Login;
