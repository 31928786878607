import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  BUByLocationDto,
  BusinessUnitInterface,
  ExchangeRateDto,
  PieceCategoryInterface,
  RangeDeclareDto,
  TaxIdentificationTypeInterface,
} from "../../interfaces";
import { LocationDto } from "../../interfaces/Dto/LocationDto";
import { NavigationItem } from "../../components";

interface InmutableState {
  cities: LocationDto[];
  businessUnits: BusinessUnitInterface[];
  PieceCategories: PieceCategoryInterface[];
  taxIdentificationTypes: TaxIdentificationTypeInterface[];
  citiesByBu: BUByLocationDto[];
  rangeDeclareList: RangeDeclareDto[];
  exchangeRate: ExchangeRateDto;
  navSidebar: NavigationItem[];
}

const initialState: InmutableState = {
  cities: [],
  businessUnits: [],
  PieceCategories: [],
  taxIdentificationTypes: [],
  citiesByBu: [],
  rangeDeclareList: [],
  exchangeRate: {} as ExchangeRateDto,
  navSidebar: [],
};

export const inmutableSlice = createSlice({
  name: "inmutable",
  initialState,
  reducers: {
    setInmutableTaxIdentificationTypes: (
      state,
      action: PayloadAction<TaxIdentificationTypeInterface[]>
    ) => {
      state.taxIdentificationTypes = action.payload;
    },
    setInmutablePieceCategories: (
      state,
      action: PayloadAction<PieceCategoryInterface[]>
    ) => {
      state.PieceCategories = action.payload;
    },
    setInmutableBusinessUnits: (
      state,
      action: PayloadAction<BusinessUnitInterface[]>
    ) => {
      state.businessUnits = action.payload;
    },
    setInmutableCities: (state, action: PayloadAction<LocationDto[]>) => {
      state.cities = action.payload;
    },
    setInmutableCitiesByBu: (
      state,
      action: PayloadAction<BUByLocationDto[]>
    ) => {
      state.citiesByBu = action.payload;
    },
    setInmutableRangeDeclareList: (
      state,
      action: PayloadAction<RangeDeclareDto[]>
    ) => {
      state.rangeDeclareList = action.payload;
    },
    setInmutableExchangeRate: (
      state,
      action: PayloadAction<ExchangeRateDto>
    ) => {
      state.exchangeRate = action.payload;
    },
    setNavigationItemList: (state, action: PayloadAction<NavigationItem[]>) => {
      state.navSidebar = action.payload;
    },
  },
});

export const {
  setInmutableCities,
  setInmutableBusinessUnits,
  setInmutablePieceCategories,
  setInmutableTaxIdentificationTypes,
  setInmutableCitiesByBu,
  setInmutableRangeDeclareList,
  setInmutableExchangeRate,
  setNavigationItemList,
} = inmutableSlice.actions;

export default inmutableSlice.reducer;
