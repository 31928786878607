import { FC } from "react";
import { LinkText } from "..";
import { DestinationInterface } from "../../interfaces";

export const DestinationDetails: FC<DestinationInterface> = ({
  account,
  location,
  businessUnit,
  isHomeDelivery,
}) => {
  return (
    <div className="flex flex-1 flex-col text-xs gap-0.5">
      <div className="flex flex-1 gap-4">
        <div className="flex justify-end" style={{ minWidth: "6.5rem" }}>
          <span className="font-bold text-gray-600 text-right">Cliente:</span>
        </div>
        <span className="text-gray-800">{account.accountFullName}</span>
      </div>

      <div className="flex flex-1 gap-4">
        <div className="flex justify-end" style={{ minWidth: "6.5rem" }}>
          <span className="font-bold text-gray-600 text-right">Documento:</span>
        </div>
        <span className="text-gray-800">
          {account.abbreviationName}
          {account.identificationNumber}
        </span>
      </div>

      <div className="flex flex-1 gap-4">
        <div className="flex justify-end" style={{ minWidth: "6.5rem" }}>
          <span className="font-bold text-gray-600 text-right">Dirección:</span>
        </div>
        <span className="text-gray-800">{location.address}</span>
      </div>

      <div className="flex flex-1 gap-4">
        <div className="flex justify-end" style={{ minWidth: "6.5rem" }}>
          <span className="font-bold text-gray-600 text-right">Ciudad:</span>
        </div>
        <span className="text-gray-800">Caracas</span>
      </div>

      <div className="flex flex-1 gap-4">
        <div className="flex justify-end" style={{ minWidth: "6.5rem" }}>
          <span className="font-bold text-gray-600 text-right">Teléfono:</span>
        </div>
        <span className="text-gray-800">{account.listAccountPhone[0]?.phoneNumber}</span>
      </div>

      <div className="flex flex-1 gap-4">
        <div className="flex justify-end" style={{ minWidth: "6.5rem" }}>
          <span className="font-bold text-gray-600 text-right">Tienda receptora:</span>
        </div>
        <span className="text-gray-800">{businessUnit?.buCode}</span>
      </div>

      <div className="flex flex-1 gap-4">
        <div className="flex justify-end" style={{ minWidth: "6.5rem" }}>
          <span className="font-bold text-gray-600 text-right">Retiro en tienda:</span>
        </div>
        <span className="text-gray-800">{isHomeDelivery ? "No" : "Si"}</span>
        <div className="hidden sm:flex flex-1 justify-end">
          <LinkText text="Más detalles" />
        </div>
      </div>

      <div className="flex flex-1 mt-4 sm:hidden">
        <LinkText text="Más detalles" />
      </div>
    </div>
  );
};
