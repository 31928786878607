import axios from 'axios';
import { AuthenticationServices } from "./AuthenticationServices";
import { ListResponse } from "../interfaces/Shipment/ListResponseDto";
import { DriversInterface } from '../interfaces';
const {REACT_APP_URL_PATH_API} = process.env;

export const getDrivers = async (
): Promise<ListResponse<DriversInterface[]> | undefined> => {
  const token = await AuthenticationServices();    
  axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
  var response = await axios.post(`${REACT_APP_URL_PATH_API}/Account/GetAll`,{AccountTypeID : 9}) 
  .then(function (response) {
    var responsedto = response.data ;
    return responsedto as ListResponse<DriversInterface[]>;
  })
  .catch(function (error) {
    if (error.response) {
      console.log(error.message ?? "")
      return error.response as ListResponse<DriversInterface[]>;
    } else {
      console.log(error.message ?? "")
    }
    return [] as unknown as ListResponse<DriversInterface[]>;
  });

  return response;
};