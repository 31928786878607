import { FC, useState } from "react";
import {
  AccountAuthForm,
  Card,
  ConfirmModalStatus,
  MainPage,
  ModalLoading,
  PageTitle,
  PrimaryButton,
  UserForm,
} from "../../components";
import { useAppSelector } from "../../store/hooks";
import {
  AccountAgreementSpInterfase,
  AccountInterface,
  BusinessUnitInterface,
  DeliveryEnum,
  UserAccountsExitsInterfase,
  UserAccountsInterfase,
} from "../../interfaces";
import { Formik } from "formik";
import * as Yup from "yup";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import { PaperAirplaneIcon } from "@heroicons/react/24/outline";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { createAccountAuthApi } from "../../services/AccountAuthServices";
import { createUserApi, getExitsUser } from "../../services/UserServices";
import { SimpleResponse } from "../../interfaces/Shipment/ListResponseDto";

const CreationUserAdministratos: FC = () => {
  let { id, accountId, accountSiteID } = useParams<{
    id?: string;
    accountId?: string;
    accountSiteID?: string;
  }>();

  const user = useAppSelector((state) => state.user)!;
  const identificationTypes = useAppSelector(
    (state) => state.inmutable.taxIdentificationTypes
  );

  const fetchExitsUsers = async (
    userLogin?: string,
    userId?: string
  ): Promise<SimpleResponse<UserAccountsExitsInterfase> | undefined> => {
    if (userLogin!.length <= 2) return;

    const exits = await getExitsUser(userId, userLogin, undefined);
    if (!!exits?.didError || !exits?.model) return;
    return exits;
  };
  const userInitialValues = {
    id: id ?? undefined,
    userID: undefined,
    accountID: accountId ?? user.client!.id,
    accountSiteID: parseInt(accountSiteID ?? "") ?? user.client!.accountSiteId,
    isValidatonLogin: undefined,
    userLogin: "",
    userName: "",
    userLastName: "",
    userIdentificationTypeID: identificationTypes.find(
      (t) => t.abbreviationName === "V-"
    )!,
    isValidatonIdenti: undefined,
    userIdentificationNumber: "",
    profileID: undefined,
    departamentID: undefined,
    statusID: 1,
    userPhone: "",
    isValidatonEmail: undefined,
    userEmail: "",
    codePhoneNumber: "",
    accountSelected: undefined as UserAccountsInterfase | undefined,
  };

  let [loading, setLoading] = useState(false);
  let [loadingStatus, setLoadingStatus] = useState(
    ConfirmModalStatus.PROCESSING
  );
  let [loadingTitle, setLoadingTitle] = useState("Cargando...");
  let [loadingUser, setLoadingUser] = useState<number | undefined>(undefined);

  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    user: Yup.object().shape({
      userLogin: Yup.string()
        .required("Este campo es requerido")
        .min(3, "Debe ingresar minimo 3 caracteres")
        .test({
          name: "is-sku",
          skipAbsent: true,
          async test(value, ctx) {
            setLoadingUser(0);
            let isExit = await fetchExitsUsers(value, ctx.parent.userID);
            if (!!isExit?.didError) {
              setLoadingUser(2);
              return ctx.createError({
                message: "Error cargando la informacion...",
              });
            }

            if (!!isExit?.model?.existUser) {
              setLoadingUser(2);
              return ctx.createError({ message: "El usuario ya existe" });
            }

            if (value.length < 3) {
              setLoadingUser(2);
              return ctx.createError({
                message: "Debe ingresar minimo 3 caracteres",
              });
            }
            setLoadingUser(1);
            return true;
          },
        }),
      userName: Yup.string().required("Este campo es requerido"),
      userLastName: Yup.string().required("Este campo es requerido"),
      codePhoneNumber: Yup.string().required("Este campo es requerido"),
      userIdentificationTypeID: Yup.object().required(
        "Este campo es requerido"
      ),
      userIdentificationNumber: Yup.string().required(
        "Este campo es requerido"
      ),
      profileID: Yup.string().required("Este campo es requerido"),
      departamentID: Yup.string().required("Este campo es requerido"),
      userPhone: Yup.string().required("Este campo es requerido"),

      userEmail: Yup.string()
        .email("Correo invalido. Ejemplo: tealca@tealca.com")
        .required("Este campo es requerido"),
      accountSelected: Yup.object().required("Este campo es requerido"),
    }),
  });
  return (
    <MainPage>
      <PageTitle
        title={`${id === undefined ? "Crear" : "Editar"} usuario al cliente`}
      />
      <Formik
        initialValues={{
          user: userInitialValues,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { resetForm }) => {
          setLoadingStatus(ConfirmModalStatus.PROCESSING);
          setLoadingTitle("Guardando...");
          setLoading(true);

          let account = [
            {
              id: values.user.id,
              accountID: accountId ?? user.client!.id,
              accountSiteID:
                accountSiteID === undefined
                  ? user.client!.accountSiteId
                  : accountSiteID === "0"
                  ? undefined
                  : accountSiteID,
              userID: values.user.userID,
              userLogin: values.user.userLogin,
              userName: values.user.userName,
              userLastName: values.user.userLastName,
              userIdentificationTypeID:
                values.user.userIdentificationTypeID?.taxIdentificationTypeId ??
                0,
              userIdentificationNumber: values.user.userIdentificationNumber,
              profileID: values.user.profileID,
              applicationID: 2,
              applicationCode: "020",
              departamentID: values.user.departamentID ?? 0,
              statusID: 1,
              userPhone:
                (values.user.codePhoneNumber === "FIJO"
                  ? ""
                  : values.user.codePhoneNumber) +
                "" +
                values.user.userPhone,
              userEmail: values.user.userEmail,
              createUser: user.user?.login ?? "ccw",
              profileName: "",
              statusName: "",
              departamentName: "",
            },
          ] as UserAccountsInterfase[];
          const newUserAccount = await createUserApi(account);
          if (!newUserAccount.didError) {
            setLoadingStatus(ConfirmModalStatus.SUCCESS);
            setLoadingTitle("Se registro el usuario");
            setTimeout(() => {
              navigate(
                `/user${accountId !== undefined ? `/${accountId}` : ""}${
                  accountSiteID !== undefined ? `/${accountSiteID}` : ""
                }`
              );
            }, 2000);
          } else {
            setLoadingStatus(ConfirmModalStatus.ERROR);
            setLoadingTitle("Error: " + newUserAccount.errorMessage);
          }
        }}
      >
        {(formik) => (
          <form
            onSubmit={formik.handleSubmit}
            className="flex flex-1 flex-col gap-16 w-full"
          >
            <div className="flex flex-1 justify-between gap-2 md:gap-6 flex-col-reverse md:flex-row">
              <div className="flex flex-1" style={{ maxWidth: "45rem" }}>
                <NavLink
                  to={`/user${accountId !== undefined ? `/${accountId}` : ""}${
                    accountSiteID !== undefined ? `/${accountSiteID}` : ""
                  }`}
                >
                  <PrimaryButton
                    type="button"
                    className="flex gap-1 items-center"
                  >
                    <ChevronLeftIcon
                      className="h-5 w-5 ml-0 mr-2"
                      aria-hidden="true"
                    />{" "}
                    Regresar
                  </PrimaryButton>
                </NavLink>
              </div>
              <div className="flex flex-1 flex-col gap-12 items-end">
                <PrimaryButton className="px-4" type="submit">
                  Guardar{" "}
                  <PaperAirplaneIcon
                    className="h-5 w-5 ml-2 mr-0"
                    aria-hidden="true"
                  />
                </PrimaryButton>
              </div>
            </div>
            <div className="flex flex-1 flex-col xl:flex-row gap-16 -mt-12">
              {/* Consigne */}

              <Card title="Libreta de Usuarios:">
                <div className="flex flex-1 flex-col p-4 gap-12">
                  {/* Shipper details */}
                  <UserForm formik={formik} loadingUser={loadingUser} />
                </div>
              </Card>
            </div>
            <ModalLoading
              open={loading}
              title={loadingTitle}
              status={loadingStatus}
              setOpen={setLoading}
              onPrint={() => {}}
            />
          </form>
        )}
      </Formik>
    </MainPage>
  );
};

export default CreationUserAdministratos;
