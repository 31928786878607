import { FC } from "react";
import { Modal } from "../Atoms/Modal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { ShipmentInterface } from "../../interfaces";
import { PrimaryButton, SecondaryButton } from "../Atoms/Buttons";
import moment from "moment";

interface ShipmentDetailsModalProps {
  open: boolean;
  shipment: ShipmentInterface;
  onPrint: () => void;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
export const ShipmentDetailsModal: FC<ShipmentDetailsModalProps> = ({
  open,
  shipment,
  onPrint,
  setOpen,
}) => {
  return (
    <Modal
      open={open}
      setOpen={() => {}}
      className="flex flex-1 mx-4 my-8"
      style={{ maxWidth: "55rem" }}
    >
      <div className="flex flex-1 flex-col">
        {/* Header */}
        <div className="flex flex-1 items-center justify-between">
          <h2 className="text-xl font-bold">
            <span className="text-gray-700 hidden sm:inline">
              Número de Guía :{" "}
            </span>
            <span className="text-main-500">
              {shipment.number} | {shipment.paymentMode?.toUpperCase()}
            </span>
          </h2>

          <div className="ml-3 flex h-7 items-center">
            <button
              type="button"
              className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-main-500"
              onClick={() => setOpen(false)}
            >
              <span className="absolute -inset-2.5" />
              <span className="sr-only">Close panel</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>

        <hr className="border-gray-300 my-3" />

        {/* Shipment Data */}
        <div className="flex flex-1 flex-col text-gray-800 leading-0 text-xs sm:px-8 gap-1 sm:gap-0">
          <div className="flex flex-1 flex-col">
            <div className="flex flex-1 items-center gap-2">
              <h3 className="text-md text-main-500 font-bold">
                Detalles de la Guía
              </h3>
            </div>

            <hr className="border-1.5 border-main-500 mb-3 mt-1" />
          </div>

          <div className="flex flex-1 gap-6">
            <span className="flex flex-1 flex-col text-right font-semibold truncate">
              Piezas
            </span>

            <span className="flex-1 sm:flex-3">{shipment.totalPieces}</span>
          </div>

          <div className="flex flex-1 gap-6">
            <span className="flex flex-1 flex-col text-right font-semibold truncate">
              Peso Kg
            </span>

            <span className="flex-1 sm:flex-3">
              {shipment.totalWeight?.toFixed(2)}
            </span>
          </div>
          <div className="flex flex-1 gap-6">
            <span className="flex flex-1 flex-col text-right font-semibold truncate">
              Volumen (cm3)
            </span>

            <span className="flex-1 sm:flex-3">
              {shipment.totalDimensionalWeight?.toFixed(2)}
            </span>
          </div>
          <div className="flex flex-1 gap-6">
            <span className="flex flex-1 flex-col text-right font-semibold truncate">
              Tienda de Origen
            </span>

            <span className="flex-1 sm:flex-3">
              {shipment.businessUnitOrigin.buCode}
            </span>
          </div>

          <div className="flex flex-1 gap-6">
            <span className="flex flex-1 flex-col text-right font-semibold truncate">
              Estatus
            </span>

            <span className="flex-1 sm:flex-3">{shipment.status}</span>
          </div>
          <div className="flex flex-1 gap-6">
            <span className="flex flex-1 flex-col text-right font-semibold truncate">
              ¿Viaja Asegurada?
            </span>
            <span className="flex-1 sm:flex-3">
              {shipment.isSafeKeeping == true ? "Si" : "No"}
            </span>
          </div>
          <div className="flex flex-1 gap-6">
            <span className="flex flex-1 flex-col text-right font-semibold truncate">
              Ciudad del Remitente
            </span>

            <span className="flex-1 sm:flex-3">
              {shipment.businessUnitOrigin.location?.name}
            </span>
          </div>

          <div className="flex flex-1 gap-6">
            <span className="flex flex-1 flex-col text-right font-semibold truncate">
              Fecha de creación
            </span>

            <span className="flex-1 sm:flex-3">
              {!!shipment.creationDate &&
                moment(shipment.creationDate).format("DD/MM/YYYY")}
            </span>
          </div>

          <div className="flex flex-1 gap-6">
            <span className="flex flex-1 flex-col text-right font-semibold truncate">
              Usuario
            </span>

            <span className="flex-1 sm:flex-3">{shipment.creationUser}</span>
          </div>
          <div className="flex flex-1 gap-6">
            <span className="flex flex-1 flex-col text-right font-semibold truncate">
              Observaciones
            </span>

            <span className="flex-1 sm:flex-3">{shipment.observations}</span>
          </div>

          {shipment.trackingDetails !== null && (
            <div className="flex flex-1 gap-6">
              <span className="flex flex-1 flex-col text-right font-semibold truncate">
                Guía aliado / Tracking
              </span>

              <span className="flex-1 sm:flex-3">
                {shipment.trackingDetails}
              </span>
            </div>
          )}

          {shipment.externalManfiest !== undefined && shipment.externalManfiest?.length > 0 && (
            <div className="flex flex-1 gap-6">
              <span className="flex flex-1 flex-col text-right font-semibold truncate">
                Manifiesto Externo:
              </span>

              <span className="flex-1 sm:flex-3">
                {shipment.externalManfiest}
              </span>
            </div>
          )}
        </div>

        {/* Shipper Data */}
        <div className="flex flex-1 flex-col text-gray-800 leading-0 text-xs sm:px-8 mt-6 gap-1 sm:gap-0">
          <div className="flex flex-1 flex-col">
            <div className="flex flex-1 items-center gap-2">
              <h3 className="text-md text-main-500 font-bold">
                Detalles del Remitente
              </h3>
            </div>

            <hr className="border-1.5 border-main-500 mb-3 mt-1" />
          </div>

          <div className="flex flex-1 gap-6">
            <span className="flex flex-1 flex-col text-right font-semibold truncate">
              Alias
            </span>

            <span className="flex-1 sm:flex-3">
              {shipment.shipper.accountFullName}
            </span>
          </div>

          <div className="flex flex-1 gap-6">
            <span className="flex flex-1 flex-col text-right font-semibold truncate">
              Documento
            </span>
            <span className="flex-1 sm:flex-3">
              {shipment.shipper.abbreviationName}
              {shipment.shipper.identificationNumber}
            </span>
          </div>

          <div className="flex flex-1 gap-6">
            <span className="flex flex-1 flex-col text-right font-semibold truncate">
              Persona de Contacto
            </span>
            <span className="flex-1 sm:flex-3">
              {shipment.shipper.contactPerson}
            </span>
          </div>

          <div className="flex flex-1 gap-6">
            <span className="flex flex-1 flex-col text-right font-semibold truncate">
              Ciudad
            </span>
            <span className="flex-1 sm:flex-3">
              {shipment.businessUnitOrigin?.location?.name}
            </span>
          </div>
          <div className="flex flex-1 gap-6">
            <span className="flex flex-1 flex-col text-right font-semibold truncate">
              Tienda Origen
            </span>
            <span className="flex-1 sm:flex-3">
              {shipment.businessUnitOrigin?.buName}
            </span>
          </div>
        </div>

        {/* Consignee Data */}
        <div className="flex flex-1 flex-col text-gray-800 leading-0 text-xs sm:px-8 mt-6 gap-1 sm:gap-0">
          <div className="flex flex-1 flex-col">
            <div className="flex flex-1 items-center gap-2">
              <h3 className="text-md text-main-500 font-bold">
                Detalles del destinatario
              </h3>
            </div>

            <hr className="border-1.5 border-main-500 mb-3 mt-1" />
          </div>
          <div className="flex flex-1 gap-6">
            <span className="flex flex-1 flex-col text-right font-semibold truncate">
              Documento
            </span>

            <span className="flex-1 sm:flex-3">
              {shipment.consignee.abbreviationName}
              {shipment.consignee.identificationNumber}
            </span>
          </div>

          <div className="flex flex-1 gap-6">
            <span className="flex flex-1 flex-col text-right font-semibold truncate">
              Nombre y Apellido
            </span>

            <span className="flex-1 sm:flex-3">
              {shipment.consignee.accountFullName}
            </span>
          </div>
          <div className="flex flex-1 gap-6">
            <span className="flex flex-1 flex-col text-right font-semibold truncate">
              Dirección
            </span>
            <span className="flex-1 sm:flex-3">
              {shipment.consignee.fiscalAddress}
            </span>
          </div>
          <div className="flex flex-1 gap-6">
            <span className="flex flex-1 flex-col text-right font-semibold truncate">
              Pais
            </span>
            <span className="flex-1 sm:flex-3">
              {shipment.consignee.countryName}
            </span>
          </div>

          <div className="flex flex-1 gap-6">
            <span className="flex flex-1 flex-col text-right font-semibold truncate">
              Ciudad
            </span>
            <span className="flex-1 sm:flex-3">
              {shipment.businessUnitConsignee.location.name}
            </span>
          </div>
          <div className="flex flex-1 gap-6">
            <span className="flex flex-1 flex-col text-right font-semibold truncate">
              Correo
            </span>
            <span className="flex-1 sm:flex-3">
              {shipment.consignee.listAccountEmail[0]?.email}
            </span>
          </div>
          <div className="flex flex-1 gap-6">
            <span className="flex flex-1 flex-col text-right font-semibold truncate">
              Teléfono
            </span>
            <span className="flex-1 sm:flex-3">
              {shipment.consignee.listAccountPhone[0]?.phoneNumber}
            </span>
          </div>
          <div className="flex flex-1 gap-6">
            <span className="flex flex-1 flex-col text-right font-semibold truncate">
              Oficina de entrega
            </span>
            <span className="flex-1 sm:flex-3">
              {shipment.businessUnitConsignee.buName}
            </span>
          </div>
        </div>

        <hr className="border-gray-300 my-6" />

        {/* Footer */}
        <div className="flex flex-1 justify-between items-center">
          <SecondaryButton onClick={onPrint}>
            <span className="w-20">Imprimir</span>
          </SecondaryButton>

          <PrimaryButton onClick={() => setOpen(false)}>
            <span className="w-20">Cerrar</span>
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  );
};
