import classNames from "classnames";
import { FC } from "react";
import { FormLabel } from "./FormLabel";

interface FormCheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  ref?: React.Ref<HTMLInputElement>;
  containerClassname?: string;
  inputClassname?: string;
  labelContainerClassname?: string;
  labelClassname?: string;
}
export const FormCheckbox: FC<FormCheckboxProps> = ({
  label = "",
  name,
  className,
  labelClassname,
  inputClassname,
  containerClassname,
  labelContainerClassname,
  ref,
  ...props
}) => {
  return (
    <div className={classNames("flex items-center", containerClassname)}>
      <input
        {...props}
        ref={ref}
        name={name}
        type="checkbox"
        className={classNames(
          "peer h-4 w-4 text-main-500 focus:ring-main-500 border-gray-300 shadow rounded",
          inputClassname
        )}
      />

      <FormLabel
        label={label}
        htmlFor={name}
        className={classNames("ml-2 text-sm", labelClassname)}
        containerClassname={labelContainerClassname}
      />
    </div>
  );
};
