import { FC, useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '../../store/hooks';
import {
  MainPage,
  PageTitle,
  ConfirmModalStatus,
  PaginationFooter,
  FormSelect,
  FormText,
  ModalLoading,
  PrimaryButton,
  UserAdminField,
  UserAdminTable,
} from '../../components';
import { UserAccountsInterfase } from '../../interfaces';
import { getAllUser } from '../../services/UserServices';
import { string } from 'yup';
import { Link, useParams } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/24/outline';

const ConsultUserAdministrator: FC<{
  accountId?: string;
  accountSiteID?: string;
}> = () => {
  let { accountId, accountSiteID } = useParams<{
    accountId?: string;
    accountSiteID?: string;
  }>();

  const user = useAppSelector((state) => state.user);
  let [loading, setLoading] = useState(false);
  let [loadingStatus, setLoadingStatus] = useState(
    ConfirmModalStatus.PROCESSING
  );
  let [loadingTitle, setLoadingTitle] = useState('Cargando...');

  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const [registersNumber, setRegistersNumber] = useState(10);
  const [userAdmin, setuserAdmin] = useState<UserAccountsInterfase[]>([]);
  const [selecteds, setSelecteds] = useState<UserAccountsInterfase[]>([]);
  let [reloading, setReloading] = useState(false);

  const [sortBy, setSortBy] = useState({
    field: UserAdminField.NAME,
    asc: true,
  });

  const filtereduserAdmin = useMemo(() => {
    setPage(0);
    setSelecteds([]);
    const tokens = search?.toLowerCase().split(' ');
    return userAdmin.filter((disptach) => {
      return tokens.every((token) => {
        return (
          disptach.userLogin?.toString().toLowerCase().includes(token) ||
          disptach.userName?.toLowerCase().includes(token) ||
          disptach.userLastName?.toString()?.toLowerCase().includes(token) ||
          disptach.profileName?.toString()?.toLowerCase().includes(token) ||
          disptach.departamentName?.toString()?.toLowerCase().includes(token) ||
          disptach.userIdentificationNumber
            ?.toString()
            ?.toLowerCase()
            .includes(token) ||
          disptach.statusName?.toString()?.toLowerCase().includes(token) ||
          disptach.userPhone?.toString()?.toLowerCase().includes(token) ||
          disptach.userEmail?.toString()?.toLowerCase().includes(token)
        );
      });
    });
  }, [userAdmin, search]);

  const paginateduserAdmin = useMemo(() => {
    const ordereduserAdmin = filtereduserAdmin.sort((a, b) => {
      if (sortBy.field === UserAdminField.LOGIN) {
        return sortBy.asc
          ? a.userLogin.localeCompare(b.userLogin)
          : b.userLogin.localeCompare(a.userLogin);
      } else if (sortBy.field === UserAdminField.NAME) {
        return sortBy.asc
          ? a.userName.localeCompare(b.userName)
          : b.userName.localeCompare(a.userName);
      } else if (sortBy.field === UserAdminField.FULLNAME) {
        return sortBy.asc
          ? a.userLastName.localeCompare(b.userLastName)
          : b.userLastName.localeCompare(a.userLastName);
      } else if (sortBy.field === UserAdminField.PROFILE) {
        return sortBy.asc
          ? a?.profileName?.localeCompare(b?.profileName)
          : b?.profileName?.localeCompare(a?.profileName);
      } else if (sortBy.field === UserAdminField.DEPARTAMENT) {
        return sortBy.asc
          ? a.departamentName?.localeCompare(b.departamentName)
          : b.departamentName?.localeCompare(a.departamentName);
      } else if (sortBy.field === UserAdminField.IDENTIFICATION) {
        return sortBy.asc
          ? a.userIdentificationNumber.localeCompare(b.userIdentificationNumber)
          : b.userIdentificationNumber.localeCompare(
              a.userIdentificationNumber
            );
      } else if (sortBy.field === UserAdminField.STATUS) {
        return sortBy.asc
          ? a.statusName.localeCompare(b.statusName)
          : b.statusName.localeCompare(a.statusName);
      } else if (sortBy.field === UserAdminField.PHONE) {
        return sortBy.asc
          ? a.userPhone.localeCompare(b.userPhone)
          : b.userPhone.localeCompare(a.userPhone);
      } else if (sortBy.field === UserAdminField.EMAIL) {
        return sortBy.asc
          ? a.userEmail.localeCompare(b.userEmail)
          : b.userEmail.localeCompare(a.userEmail);
      } else if (sortBy.field === UserAdminField.DATE) {
        return (
          (sortBy.asc
            ? a.createDate?.localeCompare(b.createDate ?? '')
            : b.createDate?.localeCompare(a.createDate ?? '')) ?? 0
        );
      }
      return 0;
    });

    const start = page * registersNumber;
    const end = start + registersNumber;

    return ordereduserAdmin.slice(start, end);
  }, [filtereduserAdmin, sortBy, page, registersNumber]);

  const handleSortByChange = (field: UserAdminField) => {
    if (sortBy.field === field) {
      setSortBy({ ...sortBy, asc: !sortBy.asc });
    } else {
      setSortBy({ field, asc: true });
    }
  };

  useEffect(() => {
    const accountID = accountId ?? '' + user?.client?.id;
    if (!accountID) return;
    const accountSiteId = accountSiteID ?? '' + user?.client?.accountSiteId;

    const fetchUser = async () => {
      setLoadingStatus(ConfirmModalStatus.PROCESSING);
      setLoadingTitle('Cargando...');
      setLoading(true);
      const userAdmin = await getAllUser(accountID, accountSiteId);
      var listuserAdmin = userAdmin?.model as UserAccountsInterfase[];
      if (!userAdmin?.didError) {
        listuserAdmin.forEach((x) => {
          x.accountID = accountId ?? '' + user?.client?.id;
          x.accountSiteID =
            accountSiteID !== undefined
              ? parseInt(accountSiteID)
              : user?.client?.accountSiteId;
        });
        setuserAdmin(listuserAdmin);
        setLoading(false);
      } else {
        setLoadingStatus(ConfirmModalStatus.ERROR);
        setLoadingTitle('Error: ' + userAdmin?.errorMessage);
        setLoading(true);
      }
    };
    fetchUser();
  }, [user?.client?.id, reloading]);

  return (
    <MainPage>
      <PageTitle title="Listado de Usuarios" />
      <div className="flex flex-1 flex-col">
        <div className="flex flex-1 justify-between gap-2 md:gap-6 flex-col-reverse md:flex-row">
          <div className="flex flex-1" style={{ maxWidth: '45rem' }}>
            <FormText
              id="search"
              name="search"
              value={search}
              placeholder="Busqueda rapida..."
              containerClassname="flex flex-1"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>

          <div className="flex flex-1 justify-between sm:items-center flex-col-reverse gap-6 sm:gap-2 sm:flex-row">
            <div className="flex items-center text-xs">
              <span>Mostrar</span>
              <div className="inline mx-1">
                <FormSelect
                  id="registersNumber"
                  name="registersNumber"
                  label=""
                  selected={registersNumber}
                  options={[10, 25, 50, 100]}
                  className="!w-20 !h-8 !px-2"
                  optionString={(option) => option.toString()}
                  onSelectOption={(option) => setRegistersNumber(option)}
                />
              </div>
              <span>registros</span>
            </div>

            <Link
              to={`/user/${
                accountId !== undefined ? `createAccount` : 'create'
              }${accountId !== undefined ? `/${accountId}` : ''}${
                accountSiteID !== undefined ? `/${accountSiteID}` : ''
              }`}
            >
              <PrimaryButton className="flex gap-1 items-center">
                <PlusIcon className="h-4 w-4 shrink-0 text-white" />
                Nuevo
              </PrimaryButton>
            </Link>
          </div>
        </div>

        <div className="flex flex-1 flex-col">
          <UserAdminTable
            sortBy={sortBy}
            userAdminData={paginateduserAdmin}
            onChangeSortBy={handleSortByChange}
            setReload={setReloading}
          />

          <PaginationFooter
            currentPage={page}
            itemsPerPage={registersNumber}
            totalItems={filtereduserAdmin.length}
            onPageChange={setPage}
          />
        </div>
      </div>
      <ModalLoading
        open={loading}
        title={loadingTitle}
        status={loadingStatus}
        setOpen={setLoading}
        onPrint={() => {}}
      />
    </MainPage>
  );
};

export default ConsultUserAdministrator;
