import { useCallback, useEffect, useState } from "react";
import { FormText } from "../FormFields/FormText";
import { useAppSelector } from "../../store/hooks";
import { AccountInterface } from "../../interfaces";
import { FormSearch } from "../FormFields/FormSearch";
import { FormSelect } from "../FormFields/FormSelect";
import { FormTextArea } from "../FormFields/FormTextArea";
import { FormikErrors, FormikProps, FormikTouched } from "formik";
import { filterAccountUnit } from "../../utils/filters";
import { AccountOption } from "../FormFields/FormSearchOptions";
import { getAccounts } from "../../services";

export interface ExpressConsigneeFormValues {
  abbreviationName: string;
  identificationNumber: string;
  fullName: string;
  phoneNumber: string;
  belongsTo: AccountInterface | undefined;
  email: string;
  city: string;
  address: string;
}
interface ExpressConsigneeField {
  express: ExpressConsigneeFormValues;
}
interface ExpressConsigneeFormProps<T> {
  express?: boolean;
  formik: FormikProps<T & ExpressConsigneeField>;
}

export const ExpressConsigneeForm = <T extends any>({
  formik,
}: ExpressConsigneeFormProps<T>) => {
  const errors = formik.errors.express as
    | FormikErrors<ExpressConsigneeFormValues>
    | undefined;
  const touched = formik.touched.express as
    | FormikTouched<ExpressConsigneeFormValues>
    | undefined;

  const identificationTypes = useAppSelector(
    (state) => state.inmutable.taxIdentificationTypes
  );

  const [belongsToSearch, setBelongsToSearch] = useState("");
  const [accounts, setAccounts] = useState<AccountInterface[]>([]);

  const updateDestinationSearch = useCallback(() => {
    if (!!formik.values.express.belongsTo) {
      setBelongsToSearch(
        formik.values.express.belongsTo.accountCode +
          " - " +
          formik.values.express.belongsTo.accountFullName
      );
    } else {
      setBelongsToSearch("");
    }
  }, [formik.values.express.belongsTo]);

  useEffect(() => {
    const getAccounts_ = async () => {
      const accounts = await getAccounts();
      setAccounts(accounts);
    };

    getAccounts_();
  }, []);

  return (
    <div className="flex h-full flex-col gap-4">
      <FormSelect
        id="express.abbreviationName"
        name="express.abbreviationName"
        label="Tipo de Documento"
        selected={formik.values.express.abbreviationName}
        options={identificationTypes.map((type) => type.abbreviationName)}
        error={
          touched?.abbreviationName && errors?.abbreviationName
            ? errors?.abbreviationName
            : undefined
        }
        onBlur={formik.handleBlur}
        className="!h-8"
        labelClassname="!text-xs"
        labelContainerClassname="flex sm:!justify-end sm:w-32"
        containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
        style={{ maxWidth: "10rem" }}
        optionString={(option) => option.slice(0, 1)}
        onSelectOption={(option) =>
          formik.setFieldValue("express.abbreviationName", option)
        }
      />

      <FormText
        id="express.identificationNumber"
        name="express.identificationNumber"
        label="Nro. Documento"
        value={formik.values.express.identificationNumber}
        error={
          touched?.identificationNumber && errors?.identificationNumber
            ? errors?.identificationNumber
            : undefined
        }
        onChange={(e) => {
          let re: RegExp;
          if (formik.values.express.abbreviationName !== "P-") {
            // Only numbers
            re = /^[0-9\b]{0,10}$/;
          } else {
            // Only numbers and letters
            re = /^[a-zA-Z0-9\b]{0,10}$/;
          }

          if (e.target.value == "" || re.test(e.target.value)) {
            formik.handleChange(e);
          }
        }}
        onBlur={formik.handleBlur}
        className="!h-8"
        labelClassname="!text-xs"
        labelContainerClassname="flex sm:!justify-end sm:w-32"
        containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
        style={{ maxWidth: "10rem" }}
      />

      <FormText
        id="express.fullName"
        name="express.fullName"
        label="Nombre"
        value={formik.values.express.fullName}
        error={touched?.fullName && errors?.fullName ? errors?.fullName : undefined}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        className="!h-8"
        labelClassname="!text-xs"
        labelContainerClassname="flex sm:!justify-end sm:w-32"
        containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
        style={{ maxWidth: "15rem" }}
      />

      <FormText
        id="express.phoneNumber"
        name="express.phoneNumber"
        label="Teléfono"
        value={formik.values.express.phoneNumber}
        error={
          touched?.phoneNumber && errors?.phoneNumber ? errors?.phoneNumber : undefined
        }
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        className="!h-8"
        labelClassname="!text-xs"
        labelContainerClassname="flex sm:!justify-end sm:w-32"
        containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
        style={{ maxWidth: "15rem" }}
      />

      <FormText
        id="express.email"
        name="express.email"
        label="Correo"
        value={formik.values.express.email}
        error={touched?.email && errors?.email ? errors?.email : undefined}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        className="!h-8"
        labelClassname="!text-xs"
        labelContainerClassname="flex sm:!justify-end sm:w-32"
        containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
        style={{ maxWidth: "15rem" }}
      />

      <FormSearch
        id="express.belongsTo"
        name="express.belongsTo"
        label="Pertenece a"
        value={belongsToSearch}
        options={accounts}
        unselect={!!formik.values.express.belongsTo}
        className="!h-8"
        labelClassname="!text-xs"
        labelContainerClassname="flex sm:!justify-end sm:w-32"
        containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
        error={touched?.belongsTo && errors?.belongsTo ? errors?.belongsTo : undefined}
        onBlur={formik.handleBlur}
        onChange={(e) => setBelongsToSearch(e.target.value)}
        onChangeFocus={(focus) => !focus && updateDestinationSearch()}
        onSelectOption={(option) => formik.setFieldValue("express.belongsTo", option)}
        onFilter={filterAccountUnit}
        style={{ maxWidth: "20rem" }}
        RenderOption={({ option }) => <AccountOption {...option} />}
      />

      {/* <FormSelect
        id="express.city"
        name="express.city"
        label="Ciudad Destino"
        selected={formik.values.express.city}
        options={cities}
        error={touched?.city && errors?.city ? errors?.city : undefined}
        onBlur={formik.handleBlur}
        className="!h-8"
        labelClassname="!text-xs"
        labelContainerClassname="flex sm:!justify-end sm:w-32"
        containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
        style={{ maxWidth: "15rem" }}
        optionString={(option) => option}
        onSelectOption={(option) => formik.setFieldValue("express.city", option)}
      /> */}

      <FormTextArea
        id="express.address"
        name="express.address"
        label="Dirección"
        value={formik.values.express.address}
        error={touched?.address && errors?.address ? errors?.address : undefined}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        labelClassname="!text-xs"
        labelContainerClassname="flex sm:!justify-end sm:w-32 sm:mt-2"
        containerClassname="flex flex-1 sm:ml-0 sm:gap-8 flex-col sm:flex-row"
      />
    </div>
  );
};
