import { FC, useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '../../store/hooks';
import {
  MainPage,
  PageTitle,
  ConfirmModalStatus,
  PaginationFooter,
  FormSelect,
  FormText,
  ModalLoading,
  PrimaryButton,
  AccountAuthTable,
  AccountsAuthField,
} from '../../components';
import { PlusIcon } from '@heroicons/react/24/outline';
import { AccountsAuthInterfase } from '../../interfaces';
import { Link } from 'react-router-dom';
import { getAllAccountAuth } from '../../services/AccountAuthServices';

const ConsultAccountAuth: FC = () => {
  const user = useAppSelector((state) => state.user);
  let [loading, setLoading] = useState(false);
  let [loadingStatus, setLoadingStatus] = useState(
    ConfirmModalStatus.PROCESSING
  );
  let [loadingTitle, setLoadingTitle] = useState('Cargando...');

  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const [registersNumber, setRegistersNumber] = useState(10);
  const [accountAuth, setaccountAuth] = useState<AccountsAuthInterfase[]>([]);
  const [selecteds, setSelecteds] = useState<AccountsAuthInterfase[]>([]);

  const [sortBy, setSortBy] = useState({
    field: AccountsAuthField.NAME,
    asc: true,
  });

  const filteredAccountAuth = useMemo(() => {
    setPage(0);
    setSelecteds([]);
    const tokens = search?.toLowerCase().split(' ');
    return accountAuth.filter((disptach) => {
      return tokens.every((token) => {
        return (
          disptach.accountFullName?.toString().toLowerCase().includes(token) ||
          disptach.identificationNumber?.toLowerCase().includes(token) ||
          disptach.phoneNumber?.toString()?.toLowerCase().includes(token) ||
          disptach.email?.toString()?.toLowerCase().includes(token) ||
          disptach.addressLine1?.toString()?.toLowerCase().includes(token)
        );
      });
    });
  }, [accountAuth, search]);

  const paginatedaccountAuth = useMemo(() => {
    const orderedaccountAuth = filteredAccountAuth.sort((a, b) => {
      if (sortBy.field === AccountsAuthField.NAME) {
        return sortBy.asc
          ? a.accountFullName.localeCompare(b.accountFullName)
          : b.accountFullName.localeCompare(a.accountFullName);
      } else if (sortBy.field === AccountsAuthField.IDENTIFICATION) {
        return sortBy.asc
          ? a.identificationNumber.localeCompare(b.identificationNumber)
          : b.identificationNumber.localeCompare(a.identificationNumber);
      } else if (sortBy.field === AccountsAuthField.PHONE) {
        return sortBy.asc
          ? a.phoneNumber.localeCompare(b.phoneNumber)
          : b.phoneNumber.localeCompare(a.phoneNumber);
      } else if (sortBy.field === AccountsAuthField.EMAIL) {
        return sortBy.asc
          ? a.email.localeCompare(b.email)
          : b.email.localeCompare(a.email);
      } else if (sortBy.field === AccountsAuthField.ADDRESS) {
        return sortBy.asc
          ? a.addressLine1.localeCompare(b.addressLine1)
          : b.addressLine1.localeCompare(a.addressLine1);
      } else if (sortBy.field === AccountsAuthField.DATE) {
        return (
          (sortBy.asc
            ? a.creationDate?.localeCompare(b.creationDate ?? '')
            : b.creationDate?.localeCompare(a.creationDate ?? '')) ?? 0
        );
      }
      return 0;
    });

    const start = page * registersNumber;
    const end = start + registersNumber;

    return orderedaccountAuth.slice(start, end);
  }, [filteredAccountAuth, sortBy, page, registersNumber]);

  const handleSortByChange = (field: AccountsAuthField) => {
    if (sortBy.field === field) {
      setSortBy({ ...sortBy, asc: !sortBy.asc });
    } else {
      setSortBy({ field, asc: true });
    }
  };

  useEffect(() => {
    const accountID = '' + user?.client?.id;
    if (!accountID) return;
    const accountCode = '' + user?.client?.accountCode;

    const fetchShipments = async () => {
      setLoadingStatus(ConfirmModalStatus.PROCESSING);
      setLoadingTitle('Cargando...');
      setLoading(true);
      const accountAuth = await getAllAccountAuth(
        accountID,
        undefined,
        accountCode
      );
      var listaccountAuth = accountAuth?.model as AccountsAuthInterfase[];
      if (!accountAuth?.didError) {
        setaccountAuth(listaccountAuth);
        setLoading(false);
      } else {
        setLoadingStatus(ConfirmModalStatus.ERROR);
        setLoadingTitle('Error: ' + accountAuth?.errorMessage);
        setLoading(true);
      }
    };
    fetchShipments();
  }, [user?.client?.id]);

  return (
    <MainPage>
      <PageTitle title="Cuentas Autorizadas" />
      <div className="flex flex-1 flex-col">
        <div className="flex flex-1 justify-between gap-2 md:gap-6 flex-col-reverse md:flex-row">
          <div className="flex flex-1" style={{ maxWidth: '45rem' }}>
            <FormText
              id="search"
              name="search"
              value={search}
              placeholder="Busqueda rapida..."
              containerClassname="flex flex-1"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>

          <div className="flex flex-1 justify-between sm:items-center flex-col-reverse gap-6 sm:gap-2 sm:flex-row">
            <div className="flex items-center text-xs">
              <span>Mostrar</span>
              <div className="inline mx-1">
                <FormSelect
                  id="registersNumber"
                  name="registersNumber"
                  label=""
                  selected={registersNumber}
                  options={[10, 25, 50, 100]}
                  className="!w-20 !h-8 !px-2"
                  optionString={(option) => option.toString()}
                  onSelectOption={(option) => setRegistersNumber(option)}
                />
              </div>
              <span>registros</span>
            </div>

            <Link to="/accountAuth/create">
              <PrimaryButton className="flex gap-1 items-center">
                <PlusIcon className="h-4 w-4 shrink-0 text-white" />
                Nuevo
              </PrimaryButton>
            </Link>
          </div>
        </div>

        <div className="flex flex-1 flex-col">
          <AccountAuthTable
            sortBy={sortBy}
            accountsAuthData={paginatedaccountAuth}
            onChangeSortBy={handleSortByChange}
          />

          <PaginationFooter
            currentPage={page}
            itemsPerPage={registersNumber}
            totalItems={filteredAccountAuth.length}
            onPageChange={setPage}
          />
        </div>
      </div>
      <ModalLoading
        open={loading}
        title={loadingTitle}
        status={loadingStatus}
        setOpen={setLoading}
        onPrint={() => {}}
      />
    </MainPage>
  );
};

export default ConsultAccountAuth;
