import { FC, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";
import filePdfO from "@iconify/icons-fa/file-pdf-o";
import fileTextO from "@iconify/icons-fa/file-text-o";
import { getAccountDestinations } from "../../services";
import fileExcelO from "@iconify/icons-fa/file-excel-o";
import { DestinationInterface } from "../../interfaces";
import { ChevronDownIcon, PlusIcon } from "@heroicons/react/24/outline";
import {
  Popover,
  MainPage,
  FormText,
  PageTitle,
  buttonClassname,
  DestinationsTable,
  DestinationTableField,
  PrimaryButton,
} from "../../components";
import {
  exportDestinationsPDF,
  exportDestinationsTXT,
  exportDestinationsXLSX,
} from "../../utils/exports";

const ExportButton = (
  <div
    className={classNames(
      buttonClassname,
      "bg-main-500 hover:bg-main-400 hover:border-main-400 text-white focus-visible:outline-main-400"
    )}
  >
    <div className="flex gap-1 items-center">
      <span>Exportar</span>

      <ChevronDownIcon className="w-4 h-4" />
    </div>
  </div>
);

const ConsultDestinations: FC = () => {
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user);

  const [search, setSearch] = useState("");
  const [destinations, setDestinations] = useState<DestinationInterface[]>([]);
  const [sortBy, setSortBy] = useState({ field: DestinationTableField.CODE, asc: true });

  const filteredDestinations = useMemo(() => {
    const tokens = search.toLowerCase().split(" ");

    return destinations.filter((destination) => {
      return tokens.every((token) => {
        return (
          destination.location.code?.toLowerCase().includes(token) ||
          destination.location.name.toLowerCase().includes(token) ||
          destination.account.listAccountPhone.some((phone) =>
            (phone.countryPhoneAccessCode + phone.phoneNumber)
              .toLowerCase()
              .includes(token)
          ) ||
          destination.businessUnit?.buCode.toLowerCase().includes(token) ||
          destination.account.accountFullName.toLowerCase().includes(token)
        );
      });
    });
  }, [destinations, search]);

  const orderedDestinations = useMemo(() => {
    return filteredDestinations.sort((a, b) => {
      if (sortBy.field == DestinationTableField.CODE) {
        return (
          (sortBy.asc
            ? a.location.code?.localeCompare(b.location.code ?? "")
            : b.location.code?.localeCompare(a.location.code ?? "")) || 0
        );
      } else if (sortBy.field == DestinationTableField.ALIAS) {
        return sortBy.asc
          ? a.location.name.localeCompare(b.location.name)
          : b.location.name.localeCompare(a.location.name);
      } else if (sortBy.field == DestinationTableField.CITY) {
        return (
          (sortBy.asc
            ? a.location.code?.localeCompare(b.location.code || "")
            : b.location.code?.localeCompare(a.location.code || "")) || 0
        );
      } else if (sortBy.field == DestinationTableField.PHONE) {
        return (
          (sortBy.asc
            ? a.account.listAccountPhone[0]?.phoneNumber.localeCompare(
                b.account.listAccountPhone[0]?.phoneNumber
              )
            : b.account.listAccountPhone[0]?.phoneNumber.localeCompare(
                a.account.listAccountPhone[0]?.phoneNumber
              )) || 0
        );
      } else if (sortBy.field == DestinationTableField.STORE) {
        return sortBy.asc
          ? a.businessUnit?.buCode.localeCompare(b.businessUnit.buCode )
          : b.businessUnit?.buCode.localeCompare(a.businessUnit.buCode);
      } else if (sortBy.field == DestinationTableField.CONTACT) {
        return sortBy.asc
          ? a.account.accountFullName.localeCompare(b.account.accountFullName)
          : b.account.accountFullName.localeCompare(a.account.accountFullName);
      }
      return 0;
    });
  }, [filteredDestinations, sortBy]);

  const handleSortByChange = (field: DestinationTableField) => {
    if (sortBy.field == field) {
      setSortBy({ ...sortBy, asc: !sortBy.asc });
    } else {
      setSortBy({ field, asc: true });
    }
  };

  useEffect(() => {
    const id = user?.client?.id;
    if (!id) return;

    const fetchDestinations = async () => {
      const destinations = await getAccountDestinations(id);
      setDestinations(destinations);
    };

    fetchDestinations();
  }, [user?.client?.id]);

  return (
    <MainPage>
      <PageTitle title="Destinatarios" />

      <div className="mb-6">
        <div className="flex flex-1 flex-col sm:flex-row justify-between gap-6">
          <div className="flex flex-1 gap-2" style={{ maxWidth: "30rem" }}>
            <PrimaryButton
              className="hidden sm:inline-flex"
              onClick={() => navigate("/destinations/create", { state: { back: true } })}
            >
              <PlusIcon className="w-5 h-5" />
              Nuevo
            </PrimaryButton>

            <FormText
              id="search"
              name="search"
              value={search}
              placeholder="Buscar destinatario..."
              containerClassname="flex flex-1"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>

          <div className="flex flex-1 justify-between sm:flex-none">
            <PrimaryButton
              className="inline-flex sm:hidden"
              onClick={() => navigate("/destinations/create", { state: { back: true } })}
            >
              <PlusIcon className="w-5 h-5" />
              Nuevo
            </PrimaryButton>

            <Popover button={ExportButton}>
              <div className="flex flex-col font-semibold cursor-pointer">
                <div
                  onClick={() => exportDestinationsPDF(user.user!, filteredDestinations)}
                  className="flex flex-1 flex-col text-sm text-gray-700 truncate hover:bg-main-500 hover:text-white"
                >
                  <div className="flex  gap-2 py-2 px-4 w-32">
                    <Icon icon={filePdfO} className="w-5 h-5" />
                    <span>PDF</span>
                  </div>
                </div>

                <hr />

                <div
                  onClick={() => exportDestinationsXLSX(filteredDestinations)}
                  className="flex flex-1 flex-col text-sm text-gray-700 truncate hover:bg-main-500 hover:text-white"
                >
                  <div className="flex gap-2 py-2 px-4 w-32">
                    <Icon icon={fileExcelO} className="w-5 h-5" />
                    <span>EXCEL</span>
                  </div>
                </div>

                <hr />

                <div
                  onClick={() => exportDestinationsTXT(filteredDestinations)}
                  className="flex flex-1 flex-col text-sm text-gray-700 truncate hover:bg-main-500 hover:text-white"
                >
                  <div className="flex gap-2 py-2 px-4 w-32">
                    <Icon icon={fileTextO} className="w-5 h-5" />
                    <span>TXT</span>
                  </div>
                </div>
              </div>
            </Popover>
          </div>
        </div>

        <div className="flex flex-1 overflow-auto mt-6">
          <DestinationsTable
            sortBy={sortBy}
            destinations={orderedDestinations}
            onChangeSortBy={handleSortByChange}
          />
        </div>
      </div>
    </MainPage>
  );
};

export default ConsultDestinations;
