import { FieldsetHTMLAttributes, useMemo } from 'react';
import classNames from 'classnames';
import { FormikErrors } from 'formik';

interface FormRadioGroupProps<T>
  extends FieldsetHTMLAttributes<HTMLFieldSetElement> {
  name: string;
  label?: string;
  options: { name: string; value: T }[];
  selected?: T;
  disabled?: boolean;
  labelClassname?: string;
  optionsClassName?: string;
  wrapperClassName?: string;
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[];
  onSelectOption: (opt: T) => void;
}
export const FormRadioGroup = <T extends any>({
  name,
  label = '',
  options,
  selected,
  disabled,
  labelClassname,
  optionsClassName,
  wrapperClassName,
  error,
  onSelectOption,
  ...props
}: FormRadioGroupProps<T>) => {
  const Options = useMemo(() => {
    return options.map((option, index) => (
      <div key={index} className="flex items-center">
        <input
          id={`option-${name}-${option.name}-${index}`}
          name={`simple-radio-group-${name}-${index}`}
          type="radio"
          disabled={disabled}
          checked={option.value == selected}
          onChange={() => !disabled && onSelectOption(option.value)}
          className="h-4 w-4 border-gray-300 text-main-500 focus:ring-main-500"
        />
        <label
          htmlFor={`option-${name}-${option.name}-${index}`}
          className={classNames(
            'ml-3 block text-sm font-medium leading-6 truncate',
            disabled && 'text-gray-400',
            optionsClassName
          )}
        >
          {option.name}
        </label>
      </div>
    ));
  }, [options, name, disabled, selected, optionsClassName, onSelectOption]);

  return (
    <fieldset {...props}>
      <span
        className={classNames(
          'block text-sm font-bold leading-6 text-gray-700 truncate ring-red-500',
          labelClassname
        )}
      >
        {label}
      </span>

      <div className="flex flex-1 flex-col">
        <div
          className={classNames(
            'flex gap-4 flex-wrap flex-col relative flex flex-1 items-center',
            wrapperClassName
          )}
        >
          {Options}
        </div>

        {typeof error == 'string' && !!error && (
          <span className="mt-2 text-sm text-red-500">{error}</span>
        )}
        {Array.isArray(error) && (
          <span className="mt-2 text-sm text-red-500">{error.join(', ')}</span>
        )}
      </div>
    </fieldset>
  );
};
