import axios from 'axios';
import { alertService } from './alertService';
import { AuthenticationServices } from './AuthenticationServices';

const URLPrinter = process.env.REACT_APP_API_PRINTER_HOST; // For printer local --port 8000
const baseURL = process.env.REACT_APP_URL_PATH_API;

// Method to verify if the printer is avaliable
export const IsAvaliablePrint = async (): Promise<boolean> => {
  let result: boolean;
  try {
    const token = await AuthenticationServices();
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

    const response = await axios.get(`${URLPrinter}`);
    if (response.status === 200) {
      result = true;
    } else {
      result = false;
    }
  } catch (error) {
    alertService.error(
      'No se pudo comunicar con la impresora, verifique que el programa se este ejecutando.'
    );
    result = false;
  }
  return result;
};

//TODO simplify this method
// Method to obtain the labels of the shipment
async function getLabels(
  ShipmentNumber: number,
  ServiceID: number,
  BuCodeSource: string,
  PiecesNumber: number[]
) {
  let respose: any = [];
  await axios
    .post(`${baseURL}/v1/Shipment/GetShipmentLabelV2`, {
      ShipmentNumber,
      ServiceID,
      BuCodeSource,
      PiecesNumber,
    })
    .then((res) => {
      respose = res.data;
    })
    .catch((error) => {
      console.log(error);
    });
  return respose;
}
// Method to print the labels of the shipment using the external printer service
export const PrintLabel = async (
  ShipmentNumber: number,
  ServiceID: number,
  BuCodeSource: string,
  PiecesNumber: number[]
): Promise<boolean> => {
  let result: boolean;

  let StringLabel = await getLabels(
    ShipmentNumber,
    ServiceID,
    BuCodeSource,
    PiecesNumber
  );

  try {
    if (StringLabel.stringLabel.length === 0) {
      alertService.error(
        'Guía: ' + ShipmentNumber,
        'No se pudo obtener ninguna etiqueta de la guía especificada.'
      );
      return false;
    }
    let AvaliablePrint = await IsAvaliablePrint();
    if (!AvaliablePrint) {
      return false;
    }
    result = await new Promise(async (resolve, rejected) => {
      const token = await AuthenticationServices();
      axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

      const labelFormat = StringLabel.labelFormat;
      const stringLabel = StringLabel.stringLabel;

      await axios
        .post(`${URLPrinter}/PrintMultipleShipment`, {
          'StringLabel': StringLabel.stringLabel,
          'LabelFormat': StringLabel.labelFormat,
        })
        .then((res) => {
          resolve(true);
        })
        .catch((error) => {
          rejected(false);
        });
    });
  } catch (error) {
    result = false;
  }
  return result;
};
