import { FC, useEffect, useState } from "react";
import {
  ArrowLeftIcon,
  DocumentArrowDownIcon,
  DocumentTextIcon,
  QrCodeIcon,
} from "@heroicons/react/24/outline";
import {
  importShipmentsFromCSV,
  importShipmentsFromXLSX,
} from "../../utils/imports";
import {
  FormFile,
  FormText,
  MainPage,
  PageTitle,
  PrimaryButton,
  HorizontalTab,
  LinkTextIcon,
  ModalLoading,
  ConfirmModalStatus,
} from "../../components";
import { ImportInterface } from "../../interfaces/Import/importInterfase";
import { CCWIntegracionV2, DeliveryEnum } from "../../interfaces";
import { useAppSelector } from "../../store/hooks";
import { ImportsMasiveTable } from "../../components/Import/ImportsMasiveTable";
import { getShipmentsExists } from "../../services";
import { exportFormatoShipmentXLSX } from "../../utils/exports";
import {
  searchManifiestInternational,
  searchManifiestInternationalExcel,
} from "../../services/ImportServices";
import { ImportMapperV2Dto } from "../../utils/mappers";

const CONSIGNEE_TABS = [
  {
    name: "Archivo",
    icon: <DocumentTextIcon className="w-5 h-5" />,
  },
  {
    name: "Internacional",
    icon: <QrCodeIcon className="w-5 h-5" />,
  },
];

const ShipmentImport: FC = () => {
  const businessUnits = useAppSelector(
    (state) => state.inmutable.businessUnits
  );
  const identificationTypes = useAppSelector(
    (state) => state.inmutable.taxIdentificationTypes
  );
  const user = useAppSelector((state) => state.user);

  const citiesType = useAppSelector((state) => state.inmutable.cities);
  const citiesByBuType = useAppSelector((state) => state.inmutable.citiesByBu);
  const typeShipment = [
    { value: DeliveryEnum.OFFICE, name: "Oficina" },
    { value: DeliveryEnum.DELIVERY, name: "Domicilio" },
  ];

  const [tab, setTab] = useState(0);
  const [error, setError] = useState("");
  const [file, setFile] = useState<File>();
  const [manifestCode, setManifestCode] = useState("");
  const [fileData, setFileData] = useState<ImportInterface[]>([]);
  const [nextSave, setNextSave] = useState(false);
  const [guideDocumentCode, setGuideDocumentCode] = useState("");

  let [loading, setLoading] = useState(false);
  let [loadingStatus, setLoadingStatus] = useState(
    ConfirmModalStatus.PROCESSING
  );
  let [loadingTitle, setLoadingTitle] = useState("Cargando...");

  const handleFileChange = (file?: File) => {
    if (!file) {
      setFile(undefined);
      return;
    }

    const fileType = file.type;
    if (
      fileType === "text/csv" ||
      fileType === "application/vnd.ms-excel" ||
      fileType ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setFile(file);
    } else {
      setError("El archivo no es csv, xls o xlsx");
    }
  };

  const splitString = (str: string) => {
    let arr = str.split(",");
    let result = [];
    let temp = "";
    let count = 0;
    for (let i = 0; i < arr.length; i++) {
      temp += arr[i];
      count += arr[i].length;
      if (count >= 200) {
        result.push(temp);
        temp = "";
        count = 0;
      } else {
        temp += ",";
        count++;
      }
    }
    if (temp) {
      result.push(temp);
    }
    return result;
  };
  const handleFileSubmit = async () => {
    if (!file) {
      setError("Por favor seleccione un archivo");
      return;
    }

    let accountBillTo = user.paymentMethod?.filter(
      (x) => x.paymentModeID.toString() === "3"
    )![0]?.accountBillTo;

    if (
      accountBillTo == "00000000-0000-0000-0000-000000000000" ||
      accountBillTo == undefined
    ) {
      setLoadingStatus(ConfirmModalStatus.ERROR);
      setLoadingTitle(
        "Comuniquese con el departamento de Soporte Técnico. No tiene cuenta para facturar asignada"
      );
      setLoading(true);
      return;
    }

    if (user.client?.businessUnit! === undefined) {
      setLoadingStatus(ConfirmModalStatus.ERROR);
      setLoadingTitle(
        "Comuniquese con el departamento de Soporte Técnico. No tiene tienda origen asignado."
      );
      setLoading(true);
      return;
    }

    setError("");

    if (file.type === "text/csv") {
      importShipmentsFromCSV(file);
    } else {
      let result = await importShipmentsFromXLSX(file);
      result.shift();
      const columnB = result.map(function (value, index) {
        return value["B"];
      });
      const concatenatedColumnB = columnB.join(",");
      let dataExistArray = splitString(concatenatedColumnB);
      let dataExits = "";
      for (let i = 0; i < dataExistArray.length; i++) {
        dataExits += await getShipmentsExists(dataExistArray[i]);
      }
      setGuideDocumentCode(dataExits);

      result.forEach((shipment) => {
        shipment.A = shipment.A?.toString();
        shipment.B = shipment.B?.toString();
        shipment.C =
          typeShipment.find(
            (x) =>
              x.value.toString().toUpperCase() ===
                shipment.C?.toString().toUpperCase() ||
              x.name.toUpperCase() === shipment.C?.toString().toUpperCase()
          )?.value ?? "";

        shipment.D =
          citiesType.find(
            (x) =>
              x.locationCode.toString().toUpperCase() ===
                shipment.D?.toString().toUpperCase() ||
              x.locationName.toUpperCase() ===
                shipment.D?.toString().toUpperCase()
          )?.locationCode ?? "";

        shipment.E =
          businessUnits.find(
            (x) =>
              x.buCode.toString().toUpperCase() ===
              (shipment.E?.toString().length === 0 || shipment.E === undefined
                ? citiesByBuType.find(
                    (x) =>
                      x.locationCode ===
                      citiesType.find(
                        (x) =>
                          x.locationCode.toString().toUpperCase() ===
                            shipment.D?.toString().toUpperCase() ||
                          x.locationName.toUpperCase() ===
                            shipment.D?.toString().toUpperCase()
                      )?.locationCode
                  )?.buCode ?? ""
                : shipment.E?.toString())
          )?.buCode ?? "";

        shipment.F =
          identificationTypes
            .find(
              (x) =>
                (x.abbreviationName === user!.client?.abbreviationName &&
                  shipment.F === undefined) ||
                x.taxIdentificationTypeId.toString() ===
                  shipment.F?.toString().toUpperCase() ||
                x.abbreviationName.slice(0, 1) ===
                  shipment.F?.toString().toUpperCase()
            )
            ?.taxIdentificationTypeId.toString() ?? "";

        shipment.I =
          identificationTypes
            .find(
              (x) =>
                x.taxIdentificationTypeId.toString() ===
                  shipment.I?.toString().toUpperCase() ||
                x.abbreviationName.slice(0, 1) ===
                  shipment.I?.toString().toUpperCase()
            )
            ?.taxIdentificationTypeId.toString() ?? "";
        shipment.G =
          (shipment.G !== undefined
            ? shipment.G?.toString().trim()
            : user!.client?.identificationNumber?.toString().trim()) ?? "";
        shipment.H = shipment.H?.toString().trim();
        shipment.J = shipment.J?.toString().trim();
        shipment.K = shipment.K?.toString().trim();
        shipment.L = shipment.L?.toString().trim();
        shipment.M = shipment.M?.toString().trim();
        shipment.N = shipment.N?.toString().trim();
        shipment.O = shipment.O?.toString().trim();
        shipment.P = shipment.P?.toString().trim();
        shipment.Q = shipment.Q?.toString().trim();
        shipment.R = shipment.R?.toString().trim();
        shipment.S = shipment.S?.toString().trim();
        shipment.T = shipment.T?.toString().trim();
        shipment.U = shipment.U?.toString().trim();
        shipment.V = shipment.V?.toString().trim();
        shipment.W = shipment.W?.toString().trim();
      });
      setFileData(result);
      setNextSave(true);
    }
  };

  const SearchManifiest = async () => {
    if (!manifestCode) {
      setError("Por favor ingrese un codigo de manifiesto");
      return;
    }
    setError("");
    setLoadingStatus(ConfirmModalStatus.PROCESSING);
    setLoadingTitle("Cargando...");
    setLoading(true);

    const customercode = "" + user?.client?.accountCode;

    var data = await searchManifiestInternational(manifestCode, customercode);
    if (data.didError) {
      setLoadingStatus(ConfirmModalStatus.ERROR);
      setLoadingTitle("Error: " + data?.errorMessage);
      setLoading(true);
      return;
    }
    setLoading(false);
    if ((data.model?.length ?? 0) > 0) {
      var datamap =
        data.model?.map((dto: CCWIntegracionV2) => ImportMapperV2Dto(dto)) ??
        [];
      let numberguide = "";
      datamap?.forEach((shipment) => {
        shipment.C =
          typeShipment.find(
            (x) =>
              x.value.toString().toUpperCase() ===
                shipment.C?.toString().toUpperCase() ||
              x.name.toUpperCase() === shipment.C?.toString().toUpperCase()
          )?.value ?? "";

        shipment.F =
          shipment.F.length > 0
            ? shipment.F
            : user.client?.abbreviationName ?? "";
        shipment.G =
          shipment.G.length > 0
            ? shipment.G
            : user.client?.identificationNumber ?? "";
        shipment.H =
          shipment.H.length > 0
            ? shipment.H
            : user.client?.accountFullName ?? "";

        shipment.D =
          citiesType.find(
            (x) =>
              x.locationCode.toString().toUpperCase() ===
                shipment.D?.toString().toUpperCase() ||
              x.locationName.toUpperCase() ===
                shipment.D?.toString().toUpperCase()
          )?.locationCode ?? "";

        shipment.E =
          businessUnits.find(
            (x) =>
              x.buCode.toString().toUpperCase() ===
              (shipment.E?.toString().length === 0 || shipment.E === undefined
                ? citiesByBuType.find(
                    (x) =>
                      x.locationCode?.toString()?.toUpperCase() ===
                      shipment.D?.toString()?.toUpperCase()
                  )?.buCode
                : shipment.E?.toString())
          )?.buCode ?? "";

        shipment.F =
          identificationTypes
            .find(
              (x) =>
                x.taxIdentificationTypeId.toString() ===
                  shipment.F?.toString().toUpperCase() ||
                x.abbreviationName.slice(0, 1) ===
                  shipment.F?.toString().toUpperCase()
            )
            ?.taxIdentificationTypeId.toString() ?? "";

        shipment.I =
          identificationTypes
            .find(
              (x) =>
                x.taxIdentificationTypeId.toString() ===
                  shipment.I?.toString().toUpperCase() ||
                x.abbreviationName.slice(0, 1) ===
                  shipment.I?.toString().toUpperCase()
            )
            ?.taxIdentificationTypeId.toString() ?? "";

        shipment.G = shipment.G?.toString().trim();
        shipment.H = shipment.H?.toString().trim();
        shipment.J = shipment.J?.toString().trim();
        shipment.K = shipment.K?.toString().trim();
        shipment.L = shipment.L?.toString().trim();
        shipment.M = shipment.M?.toString().trim();
        shipment.N = shipment.N?.toString().trim();
        shipment.O = shipment.O?.toString().trim();
        shipment.P = shipment.P?.toString().trim();
        shipment.Q = shipment.Q?.toString().trim();
        shipment.R = shipment.R?.toString().trim();

        shipment.S = shipment.S?.toString().trim();
        shipment.T = shipment.T?.toString().trim();
        shipment.U = shipment.U?.toString().trim();
        shipment.V = shipment.V?.toString().trim();
        shipment.W = shipment.W?.toString().trim();
        shipment.L = shipment.L?.replace(/^(\+58|58)/, "");
        shipment.L = shipment.L?.replace("-", "").replace(".", "");
        shipment.L = shipment.L?.replace(/^(0)?(412|414|424|416|426)/, "$2");
        shipment.externalManifiest = manifestCode;

        shipment.disabled = numberguide === shipment.B ? true : false;
        numberguide = shipment.B;
      });
      setFileData(datamap);
      setNextSave(true);
    }
  };

  const DownloadPdf = async () => {
    if (!manifestCode) {
      setError("Por favor ingrese un codigo de manifiesto");
      return;
    }
    setError("");
    setLoadingStatus(ConfirmModalStatus.PROCESSING);
    setLoadingTitle("Cargando...");
    setLoading(true);

    const customercode = "" + user?.client?.accountCode;

    searchManifiestInternationalExcel(manifestCode, customercode)
      .then((response) => response.blob())
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);

        const anchorElement = document.createElement("a");

        anchorElement.href = href;
        anchorElement.download = "Importacion" + manifestCode + ".xlsx";

        document.body.appendChild(anchorElement);
        anchorElement.click();

        document.body.removeChild(anchorElement);
        window.URL.revokeObjectURL(href);
        setLoading(false);
      })
      .catch((error) => {
        setLoadingStatus(ConfirmModalStatus.ERROR);
        setLoadingTitle(
          "Error: intente mas tarde, si persiste contacte a soporte tecnico  "
        );
        setLoading(true);
      });
  };

  useEffect(() => {
    if (!nextSave) {
      setError("");
      setFile(undefined);
      setFileData([]);
    }
  }, [nextSave]);

  return (
    <MainPage>
      <PageTitle title="Importar Guías" />

      {!nextSave && (
        <>
          <HorizontalTab
            current={tab}
            tabs={CONSIGNEE_TABS}
            onChange={setTab}
          />
          <div className="flex flex-1 flex-col items-center justify-center mt-6 gap-4">
            {tab === 0 && (
              <>
                <div className="w-full flex justify-end items-end">
                  <PrimaryButton
                    onClick={() => {
                      exportFormatoShipmentXLSX();
                    }}
                    className="w-full sm:w-40"
                  >
                    Descargar Formato
                    <DocumentArrowDownIcon className="w-5 h-5"></DocumentArrowDownIcon>
                  </PrimaryButton>
                </div>
                <div className="w-full" style={{ maxWidth: "35rem" }}>
                  <FormFile
                    selected={file}
                    error={error}
                    label="Por favor seleccione el archivo que contiene los envíos a importar."
                    description="Archivos admitidos: CSV | XLS | XLSX"
                    id="shipment-import-file"
                    name="shipment-import-file"
                    onSelectFile={handleFileChange}
                  />
                </div>

                <PrimaryButton
                  onClick={handleFileSubmit}
                  className="w-full sm:w-32"
                >
                  Cargar
                </PrimaryButton>
              </>
            )}

            {tab === 1 && (
              <>
                <span className="text-gray-700 text-center mb-8">
                  Por favor ingrese el código de manifiesto internacional.
                </span>

                <div className="w-full" style={{ maxWidth: "35rem" }}>
                  <FormText
                    id="manifest-code"
                    name="manifest-code"
                    label="Código único"
                    value={manifestCode}
                    error={error}
                    onChange={(e) => setManifestCode(e.target.value)}
                    containerClassname="w-full"
                    labelContainerClassname="mb-2"
                  />
                </div>
                <div className="w-full flex items-center justify-center">
                  <PrimaryButton className=" sm:w-32" onClick={SearchManifiest}>
                    Cargar
                  </PrimaryButton>
                  <PrimaryButton
                    className=" sm:w-32 ml-2 flex items-center"
                    onClick={DownloadPdf}
                  >
                    Descargar Excel
                  </PrimaryButton>
                </div>
              </>
            )}
          </div>
        </>
      )}

      {nextSave && (
        <>
          <div
            className="flex flex-1 justify-between gap-12 md:gap-12 flex-col-reverse md:flex-row mt-2 "
            style={{ maxWidth: "100rem" }}
          >
            <LinkTextIcon
              className="text-sm font-medium cursor-pointer"
              onClick={() => setNextSave(false)}
            >
              <ArrowLeftIcon className="h-4 w-4 mr-1" aria-hidden="true" />{" "}
              <label>Regresar</label>
            </LinkTextIcon>
          </div>
          <ImportsMasiveTable
            imports={fileData}
            setNext={setNextSave}
            guideExits={guideDocumentCode}
            setGuideExits={setGuideDocumentCode}
          />
        </>
      )}

      <ModalLoading
        open={loading}
        title={loadingTitle}
        status={loadingStatus}
        setOpen={setLoading}
        onPrint={() => {}}
      />
    </MainPage>
  );
};

export default ShipmentImport;
