import { FC, useMemo } from "react";

import { BusinessUnitInterface, LocationInterface } from "../../interfaces";
import { AutocompleteRegion, FormMapsAutocomplete } from "./FormMaps";
import { FormikErrors } from "formik";

interface AddressSearchProps {
  /**
   * Stores with the territories that TEALCA covers
   */
  stores: BusinessUnitInterface[];
  /**
   * Location selected by the user
   */
  selected?: LocationInterface;
  /**
   * Indicates if the input is disabled
   */
  disabled?: boolean;
  /**
   * If true, the user can only select locations that are inside the stores
   */
  region: AutocompleteRegion;
  /**
   * Error message to display
   */
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[];
  /**
   * Callback function that is called when the user selects a location
   */
  setSelectedLocation: (
    location: LocationInterface,
    store?: BusinessUnitInterface
  ) => void;
}
export const AddressSearch: FC<AddressSearchProps> = ({
  stores,
  selected,
  region,
  disabled,
  error,
  setSelectedLocation,
}) => {
  const errorString = useMemo(() => {
    if (Array.isArray(error)) {
      return error.join(", ");
    } else if (typeof error === "object") {
      return Object.values(error).join(", ");
    }
    return error;
  }, [error]);

  return (
    <div className="relative w-full">
      {!!stores && (
        <FormMapsAutocomplete
          id="location-autocomplete"
          name="location-autocomplete"
          label=" "
          className="!h-8"
          error={errorString}
          region={region}
          selected={selected}
          disabled={disabled}
          onSelectLocation={setSelectedLocation}
        />
      )}
    </div>
  );
};
