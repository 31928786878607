import classNames from "classnames";
import { FC } from "react";

interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  children?: React.ReactNode;
}
export const Card: FC<CardProps> = ({ title, className, children, ...props }) => {
  return (
    <div
      {...props}
      className={classNames(
        "flex flex-col flex-1 rounded border-2 border-main-500",
        className
      )}
    >
      <div className="flex w-full px-4 py-2 bg-main-500 text-white font-semibold">
        {title}
      </div>

      <div>{children}</div>
    </div>
  );
};
