import { FC, useEffect, useMemo, useState } from "react";
import { useAppSelector } from "../../store/hooks";
import {
  MainPage,
  PageTitle,
  ConfirmModalStatus,
  FavoriteField,
  PaginationFooter,
  FavoriteDestinationTable,
  FormSelect,
  FormText,
  ModalLoading,
  PrimaryButton,
} from "../../components";
import {  PlusIcon } from "@heroicons/react/24/outline";
import { FavoriteDestinationsInterfase } from "../../interfaces";
import { getAllFavoriteDestinations } from "../../services/FavDestinationsServices";
import { Link } from 'react-router-dom';

const ConsultFavoriteDestination: FC = () => {
  const user = useAppSelector((state) => state.user);  
  let [loading, setLoading] = useState(false);
  let [loadingStatus, setLoadingStatus] = useState(
    ConfirmModalStatus.PROCESSING
  );
  let [loadingTitle, setLoadingTitle] = useState("Cargando...");

  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");  
  const [registersNumber, setRegistersNumber] = useState(10);
  const [favDestination, setfavoriteDestination] = useState<FavoriteDestinationsInterfase[]>([]);
  const [selecteds, setSelecteds] = useState<FavoriteDestinationsInterfase[]>([]);

  const [sortBy, setSortBy] = useState({
    field: FavoriteField.NAME,
    asc: true,
  });

  const filteredfavoriteDestination = useMemo(() => {
    setPage(0);
    setSelecteds([]);
    const tokens = search?.toLowerCase().split(" ");
    return favDestination.filter((disptach) => {
      return (
        tokens.every((token) => {
          return (
            disptach.accountFullName?.toString().toLowerCase().includes(token) ||           
           disptach.identificationNumber?.toLowerCase().includes(token) ||
           disptach.phoneNumber?.toString()?.toLowerCase().includes(token) ||
           disptach.email?.toString()?.toLowerCase().includes(token) ||
           disptach.addressLine1?.toString()?.toLowerCase().includes(token) 
          );
        })
      );
    });
  }, [favDestination, search]);

  const paginatedfavoriteDestination = useMemo(() => {
    const orderedfavoriteDestination = filteredfavoriteDestination.sort((a, b) => {
      if (sortBy.field == FavoriteField.NAME) {
        return sortBy.asc
          ? a.accountFullName.localeCompare(b.accountFullName)
          : b.accountFullName.localeCompare(a.accountFullName);
      } else 
      if (sortBy.field == FavoriteField.IDENTIFICATION) {
        return sortBy.asc
          ? a.identificationNumber.localeCompare(b.identificationNumber)
          : b.identificationNumber.localeCompare(a.identificationNumber);
      }
      else if (sortBy.field == FavoriteField.PHONE) {
        return sortBy.asc
          ? a.phoneNumber.localeCompare(b.phoneNumber)
          : b.phoneNumber.localeCompare(a.phoneNumber);
      }
      else if (sortBy.field == FavoriteField.EMAIL) {
        return sortBy.asc
          ? a.email.localeCompare(b.email)
          : b.email.localeCompare(a.email);
      }
      else if (sortBy.field == FavoriteField.ADDRESS) {
        return sortBy.asc
          ? a.addressLine1.localeCompare(b.addressLine1)
          : b.addressLine1.localeCompare(a.addressLine1);
      }else if (sortBy.field == FavoriteField.DATE) {
        return (
          (sortBy.asc
            ? a.creationDate?.localeCompare(b.creationDate ?? "")
            : b.creationDate?.localeCompare(a.creationDate ?? "")) ?? 0
        );
      }
      return 0;
    });

    const start = page * registersNumber;
    const end = start + registersNumber;

    return orderedfavoriteDestination.slice(start, end);
  }, [filteredfavoriteDestination, sortBy, page, registersNumber]);

  const handleSortByChange = (field: FavoriteField) => {
    if (sortBy.field == field) {
      setSortBy({ ...sortBy, asc: !sortBy.asc });
    } else {
      setSortBy({ field, asc: true });
    }
  };

  useEffect(() => {
    const accountID = ''+user?.client?.id;
    if (!accountID) return;

    const fetchShipments = async () => {
      setLoadingStatus(ConfirmModalStatus.PROCESSING);
      setLoadingTitle("Cargando...");
      setLoading(true);
      const favoriteDestination = await getAllFavoriteDestinations(accountID,undefined);
      var listfavoriteDestination = favoriteDestination?.model as FavoriteDestinationsInterfase[];      
      if(!favoriteDestination?.didError){
        setfavoriteDestination(listfavoriteDestination);
        setLoading(false);
      }else{
        setLoadingStatus(ConfirmModalStatus.ERROR);
        setLoadingTitle("Error: "+favoriteDestination?.errorMessage);
        setLoading(true);
      }
    };
    fetchShipments();
  }, [user?.client?.id]);

  
   return (
    <MainPage>
      <PageTitle title="Destinos Favoritos" />
      <div className="flex flex-1 flex-col">

        <div className="flex flex-1 justify-between gap-2 md:gap-6 flex-col-reverse md:flex-row">
          <div className="flex flex-1" style={{ maxWidth: "45rem" }}>
            <FormText
              id="search"
              name="search"
              value={search}
              placeholder="Busqueda rapida..."
              containerClassname="flex flex-1"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>

          <div className="flex flex-1 justify-between sm:items-center flex-col-reverse gap-6 sm:gap-2 sm:flex-row">
            <div className="flex items-center text-xs">
              <span>Mostrar</span>
              <div className="inline mx-1">
                <FormSelect
                  id="registersNumber"
                  name="registersNumber"
                  label=""
                  selected={registersNumber}
                  options={[10, 25, 50, 100]}
                  className="!w-20 !h-8 !px-2"
                  optionString={(option) => option.toString()}
                  onSelectOption={(option) => setRegistersNumber(option)}
                />
              </div>
              <span>registros</span>
            </div>
            

            <Link  to="/favoriteDestination/create">
                <PrimaryButton className="flex gap-1 items-center">
                  <PlusIcon className="h-4 w-4 shrink-0 text-white" />
                        Nuevo
                </PrimaryButton>
              </Link >
          </div>
        </div>

        <div className="flex flex-1 flex-col">          

          <FavoriteDestinationTable
            sortBy={sortBy}
            favoriteDestination={paginatedfavoriteDestination}            
            onChangeSortBy={handleSortByChange}
          />

          <PaginationFooter
            currentPage={page}
            itemsPerPage={registersNumber}
            totalItems={filteredfavoriteDestination.length}
            onPageChange={setPage}
          />
        </div>
      </div>
      <ModalLoading open={loading} title={loadingTitle} status={loadingStatus} setOpen={setLoading} onPrint={() => {}} />
    </MainPage>
  );
};

export default ConsultFavoriteDestination;
