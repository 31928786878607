import { FC, useState } from 'react';
import classNames from 'classnames';
import { Popover } from '../Atoms/Popover';
import { DispatcherCCWInterface } from '../../interfaces';

import {
  PrinterIcon,
  ChevronDownIcon,
  EllipsisVerticalIcon,
} from '@heroicons/react/24/outline';
import { DispatchPrintModal } from './DispatchPrintModal';
import moment from 'moment';

export enum DispatchField {
  CODE,
  ORIGEN,
  DESTINY,
  ROUTE,
  TRANSPORT,
  DRIVER,
  SEAL1,
  SEAL2,
  SEAL3,
  DATE,
}

const TableOptionsButton = (
  <div className="flex w-8 h-8 items-center justify-center">
    <EllipsisVerticalIcon className="w-5 h-5" />
  </div>
);

interface DispatchTableProps {
  selectedAll: boolean;
  dispatchs: DispatcherCCWInterface[];
  selectedDispatchs: DispatcherCCWInterface[];
  sortBy: { field: DispatchField; asc: boolean };
  onChangeSortBy: (field: DispatchField) => void;
  onSelectDispatch: (dispatch: DispatcherCCWInterface) => void;
  onSelectAll: () => void;
}
export const DispatchTable: FC<DispatchTableProps> = ({
  selectedAll,
  dispatchs,
  selectedDispatchs,
  sortBy,
  onChangeSortBy,
  onSelectDispatch,
  onSelectAll,
}) => {
  const [selected, setSelected] = useState<DispatcherCCWInterface>();
  const [openPrintModal, setOpenPrintModal] = useState(false);

  return (
    <div className="relative w-full table-fix-head mt-4 overflow-auto">
      <table className="table-auto w-full text-gray-600 text-xs text-left border border-gray-300">
        <thead className="select-none font-semibold border-b border-gray-300 bg-gray-50">
          <tr>
            <th
              className="px-2 py-2"
              onClick={() => onChangeSortBy(DispatchField.CODE)}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>Codigo</span>
                <div className="h-4 w-4">
                  {sortBy.field === DispatchField.CODE && (
                    <ChevronDownIcon
                      className={classNames(
                        'w-4 h-4',
                        sortBy.asc && 'rotate-180'
                      )}
                    />
                  )}
                </div>
              </div>
            </th>
            <th
              className="px-2 py-2"
              onClick={() => onChangeSortBy(DispatchField.ORIGEN)}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>Origen</span>
                <div className="h-4 w-4">
                  {sortBy.field === DispatchField.ORIGEN && (
                    <ChevronDownIcon
                      className={classNames(
                        'w-4 h-4',
                        sortBy.asc && 'rotate-180'
                      )}
                    />
                  )}
                </div>
              </div>
            </th>

            <th
              className="px-2 py-2 truncate"
              onClick={() => onChangeSortBy(DispatchField.DESTINY)}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>Destino</span>
                <div className="h-4 w-4">
                  {sortBy.field === DispatchField.DESTINY && (
                    <ChevronDownIcon
                      className={classNames(
                        'w-4 h-4',
                        sortBy.asc && 'rotate-180'
                      )}
                    />
                  )}
                </div>
              </div>
            </th>

            <th
              className="px-2 py-2"
              onClick={() => onChangeSortBy(DispatchField.ROUTE)}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>Ruta</span>
                <div className="h-4 w-4">
                  {sortBy.field === DispatchField.ROUTE && (
                    <ChevronDownIcon
                      className={classNames(
                        'w-4 h-4',
                        sortBy.asc && 'rotate-180'
                      )}
                    />
                  )}
                </div>
              </div>
            </th>

            <th
              className="px-2 py-2"
              onClick={() => onChangeSortBy(DispatchField.TRANSPORT)}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>Transporte</span>
                <div className="h-4 w-4">
                  {sortBy.field === DispatchField.TRANSPORT && (
                    <ChevronDownIcon
                      className={classNames(
                        'w-4 h-4',
                        sortBy.asc && 'rotate-180'
                      )}
                    />
                  )}
                </div>
              </div>
            </th>

            <th
              className="px-4 py-2"
              onClick={() => onChangeSortBy(DispatchField.DRIVER)}
            >
              <div className="flex gap-2 items-center cursor-pointer truncate">
                <span>Conductor</span>
                <div className="h-4 w-4">
                  {sortBy.field === DispatchField.DRIVER && (
                    <ChevronDownIcon
                      className={classNames(
                        'w-4 h-4',
                        sortBy.asc && 'rotate-180'
                      )}
                    />
                  )}
                </div>
              </div>
            </th>

            <th
              className="px-4 py-2"
              onClick={() => onChangeSortBy(DispatchField.SEAL1)}
            >
              <div className="flex gap-2 items-center cursor-pointer truncate">
                <span>Precinto 1</span>
                <div className="h-4 w-4">
                  {sortBy.field === DispatchField.SEAL1 && (
                    <ChevronDownIcon
                      className={classNames(
                        'w-4 h-4',
                        sortBy.asc && 'rotate-180'
                      )}
                    />
                  )}
                </div>
              </div>
            </th>

            <th
              className="px-3 py-2"
              onClick={() => onChangeSortBy(DispatchField.SEAL2)}
            >
              <div className="flex gap-2 items-center cursor-pointer truncate">
                <span>Precinto 2</span>
                <div className="h-4 w-4">
                  {sortBy.field === DispatchField.SEAL2 && (
                    <ChevronDownIcon
                      className={classNames(
                        'w-4 h-4',
                        sortBy.asc && 'rotate-180'
                      )}
                    />
                  )}
                </div>
              </div>
            </th>

            <th
              className="px-3 py-2"
              onClick={() => onChangeSortBy(DispatchField.SEAL3)}
              style={{ maxWidth: '7rem' }}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>Precinto 3</span>
                <div className="h-4 w-4">
                  {sortBy.field === DispatchField.SEAL3 && (
                    <ChevronDownIcon
                      className={classNames(
                        'w-4 h-4',
                        sortBy.asc && 'rotate-180'
                      )}
                    />
                  )}
                </div>
              </div>
            </th>
            <th
              className="px-3 py-2"
              onClick={() => onChangeSortBy(DispatchField.SEAL3)}
              style={{ maxWidth: '7rem' }}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>Fecha de Creación</span>
                <div className="h-4 w-4">
                  {sortBy.field === DispatchField.DATE && (
                    <ChevronDownIcon
                      className={classNames(
                        'w-4 h-4',
                        sortBy.asc && 'rotate-180'
                      )}
                    />
                  )}
                </div>
              </div>
            </th>

            <th />
          </tr>
        </thead>
        <tbody>
          {dispatchs.map((dispatch, index) => {
            const selected = selectedDispatchs.some(
              (s) =>
                s.masterShipmentHeaderCode == dispatch.masterShipmentHeaderCode
            );

            return (
              <tr
                key={dispatch.masterShipmentHeaderCode}
                className={classNames(
                  'hover:bg-gray-200',
                  index % 2 == 0 && 'bg-gray-100',
                  selected && '!bg-blue-200'
                )}
              >
                <td className="px-2 py-1 font-semibold">
                  {dispatch.masterShipmentHeaderCode}
                </td>
                <td className="px-2 py-1">{dispatch.buCodeSource}</td>
                <td className="px-2 py-1">{dispatch.buCodeDestination}</td>
                <td className="px-2 py-1">{dispatch.route}</td>
                <td className="px-2 py-1">{dispatch.transport}</td>
                <td className="px-2 py-1">{dispatch.brandName}</td>
                <td className="px-2 py-1">{dispatch.seal1 ?? 'N/D'}</td>
                <td className="px-2 py-1">{dispatch.seal2 ?? 'N/D'}</td>
                <td className="px-2 py-1">{dispatch.seal3 ?? 'N/D'}</td>
                <td className="px-2 py-1">
                  {!!dispatch.creationDate &&
                    moment(dispatch.creationDate).format('DD/MM/YYYY')}
                </td>

                <td className="pr-2">
                  <Popover
                    button={TableOptionsButton}
                    containerClassname="!-mt-8 ml-2 mr-5"
                  >
                    <div className="flex flex-col font-semibold cursor-pointer">
                      <div
                        onClick={() => {
                          setSelected(dispatch);
                          setOpenPrintModal(true);
                        }}
                        className="flex flex-1 flex-col text-sm text-gray-700 truncate hover:bg-main-500 hover:text-white"
                      >
                        <div className="flex gap-2 py-2 px-4 w-32 items-center">
                          <PrinterIcon className="w-5 h-5" />
                          <span>Imprimir</span>
                        </div>
                      </div>
                    </div>
                  </Popover>
                </td>
              </tr>
            );
          })}

          {dispatchs.length == 0 && (
            <tr>
              <td colSpan={11} className="px-4 py-4 text-center">
                No hay despachos en esta tabla.
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <DispatchPrintModal
        open={openPrintModal}
        numberDispatch={selected?.masterShipmentHeaderCode ?? ''}
        setOpen={setOpenPrintModal}
      />
    </div>
  );
};
