import { FC } from "react";
import classNames from "classnames";

interface HorizontalTabProps {
  current: number;
  tabs: { name: string; icon: JSX.Element }[];
  onChange: (index: number) => void;
}
export const HorizontalTab: FC<HorizontalTabProps> = ({ current, tabs, onChange }) => {
  return (
    <div
      className="flex flex-1 border-b border-gray-200 -mb-px flex space-x-8"
      aria-label="Tabs"
    >
      {tabs.map((tab, index) => (
        <div
          key={tab.name}
          onClick={() => onChange(index)}
          className={classNames(
            current == index
              ? "border-main-500 text-main-500"
              : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
            "group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium cursor-pointer"
          )}
        >
          <div
            className={classNames(
              current == index
                ? "text-main-500"
                : "text-gray-400 group-hover:text-gray-500",
              "-ml-0.5 mr-2"
            )}
            aria-hidden="true"
          >
            {tab.icon}
          </div>
          <span>{tab.name}</span>
        </div>
      ))}
    </div>
  );
};
