import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AccountInterface,
  BusinessUnitInterface,
  PaymentMethodAccountDto,
  PaymentModeId,
  UserInterface,
} from "../../interfaces";

interface UserState {
  logged: boolean;
  timer: number;
  user?: UserInterface;
  client?: AccountInterface;
  businessUnit?: BusinessUnitInterface;
  paymentMethod?: PaymentMethodAccountDto[];
}

const initialState: UserState = {
  logged: false,
  timer: 0,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state, action: PayloadAction<UserInterface>) => {
      state.user = action.payload;
      state.logged = true;
      state.timer = new Date().getTime() / 1000;
    },
    logout: (state) => {
      state.user = undefined;
      state.logged = false;
      state.client = undefined;
      state.timer = new Date().getTime() / 1000;
    },
    setClient: (state, action: PayloadAction<AccountInterface>) => {
      state.client = action.payload;
    },
    setBusinessUnit: (
      state,
      action: PayloadAction<BusinessUnitInterface | undefined>
    ) => {
      state.businessUnit = action.payload;
    },
    setTimer: (state, action: PayloadAction<number>) => {
      state.timer = action.payload;
    },
    setPaymentMethod: (
      state,
      action: PayloadAction<PaymentMethodAccountDto[] | undefined>
    ) => {
      state.paymentMethod = action.payload;
    },
  },
});

export const {
  login,
  logout,
  setClient,
  setBusinessUnit,
  setTimer,
  setPaymentMethod,
} = userSlice.actions;

export default userSlice.reducer;
