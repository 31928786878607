import { FC, SetStateAction, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Popover } from '../Atoms/Popover';
import { UserAccountsInterfase } from '../../interfaces';
import {
  ChevronDownIcon,
  EllipsisVerticalIcon,
  KeyIcon,
  PencilIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { v4 } from 'uuid';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { NoSymbolIcon } from '@heroicons/react/20/solid';
import { ChangeUserPassword, DeleteUser } from '../../services/UserServices';
import { Modal } from '../Atoms/Modal';
import { ModalQuestion } from '../Atoms/ModalQuestion';
import { ConfirmModalStatus } from '../Preshipment/ModalSuccess';
import ModalLoading from '../Atoms/ModalLoading';

export enum UserAdminField {
  LOGIN,
  NAME,
  FULLNAME,
  PROFILE,
  DEPARTAMENT,
  IDENTIFICATION,
  STATUS,
  PHONE,
  EMAIL,
  DATE,
}

const TableOptionsButton = (
  <div className="flex w-8 h-8 items-center justify-center">
    <EllipsisVerticalIcon className="w-5 h-5" />
  </div>
);

interface AccountAuthProps {
  userAdminData: UserAccountsInterfase[];
  sortBy: { field: UserAdminField; asc: boolean };
  onChangeSortBy: (field: UserAdminField) => void;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
}
export const UserAdminTable: FC<AccountAuthProps> = ({
  userAdminData,
  sortBy,
  onChangeSortBy,
  setReload,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [titleModal, setTitleOpenModal] = useState('');
  const [accepts, setAccepts] = useState(false);
  const [acceptsType, setAcceptsType] = useState(0);
  const [acceptsID, setAcceptsID] = useState<string | undefined>('');

  let [loading, setLoading] = useState(false);
  let [loadingStatus, setLoadingStatus] = useState(
    ConfirmModalStatus.PROCESSING
  );
  let [loadingTitle, setLoadingTitle] = useState('Cargando...');

  const [resetPassword, setresetPassword] = useState(false);
  const [userResetPassword, setUserResetPassword] = useState<
    UserAccountsInterfase | undefined
  >(undefined);

  const generateSecureKey = () => {
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789$*-+';
    let key = '';
    for (let i = 0; i < 15; i++) {
      key += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return key;
  };

  useEffect(() => {
    const fetchUser = async () => {
      if (accepts && acceptsType == 2) {
        setLoadingStatus(ConfirmModalStatus.PROCESSING);
        setLoadingTitle('Cargando...');
        setLoading(true);

        let response = await DeleteUser(acceptsID, undefined);
        if (response.didError) {
          setLoadingStatus(ConfirmModalStatus.ERROR);
          setLoadingTitle('Error: ' + response?.errorMessage);
          setLoading(true);
          setReload(false);
        } else {
          setLoadingStatus(ConfirmModalStatus.SUCCESS);
          setLoadingTitle('Desactivado Satisfactoriamente');
          const timer = setTimeout(() => {
            setReload(true);
            clearTimeout(timer);
          }, 2000);
        }
      } else if (accepts && acceptsType == 1) {
        setLoadingStatus(ConfirmModalStatus.PROCESSING);
        setLoadingTitle('Cargando...');
        setLoading(true);
        let response = await DeleteUser(undefined, acceptsID);
        if (response.didError) {
          setLoadingStatus(ConfirmModalStatus.ERROR);
          setLoadingTitle('Error: ' + response?.errorMessage);
          setLoading(true);
          setReload(false);
        } else {
          setLoadingStatus(ConfirmModalStatus.SUCCESS);
          setLoadingTitle('Eliminado Satisfactoriamente');
          const timer = setTimeout(() => {
            setReload(true);
            clearTimeout(timer);
          }, 2000);
        }
      }
    };
    fetchUser();
  }, [acceptsID, accepts, setReload, acceptsType]);

  useEffect(() => {
    const fetchPasswordUser = async () => {
      setLoadingStatus(ConfirmModalStatus.PROCESSING);
      setLoadingTitle('Guardando...');
      setLoading(true);
      let password = generateSecureKey();
      let response = await ChangeUserPassword(
        userResetPassword?.userID,
        userResetPassword?.userLogin,
        password ?? ''
      );
      if (response.didError) {
        setLoadingStatus(ConfirmModalStatus.ERROR);
        setLoadingTitle('Error: ' + response?.errorMessage);
        setLoading(true);
      } else {
        setLoadingStatus(ConfirmModalStatus.SUCCESS);
        setLoadingTitle(
          `Se actualizo correctamente. Su nueva contraseña es ${password} La misma fue enviada por Correo.`
        );
      }
      setresetPassword(false);
    };
    if (!!accepts) fetchPasswordUser();
  }, [
    resetPassword,
    accepts,
    userResetPassword?.userID,
    userResetPassword?.userLogin,
  ]);

  return (
    <div className="relative w-full table-fix-head mt-4 overflow-auto">
      <table className="table-auto w-full text-gray-600 text-xs text-left border border-gray-300">
        <thead className="select-none font-semibold border-b border-gray-300 bg-gray-50">
          <tr>
            <th
              className="px-2 py-2"
              onClick={() => onChangeSortBy(UserAdminField.LOGIN)}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>Usuario</span>
                <div className="h-4 w-4">
                  {sortBy.field === UserAdminField.LOGIN && (
                    <ChevronDownIcon
                      className={classNames(
                        'w-4 h-4',
                        sortBy.asc && 'rotate-180'
                      )}
                    />
                  )}
                </div>
              </div>
            </th>
            <th
              className="px-2 py-2"
              onClick={() => onChangeSortBy(UserAdminField.NAME)}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>Nombre</span>
                <div className="h-4 w-4">
                  {sortBy.field === UserAdminField.NAME && (
                    <ChevronDownIcon
                      className={classNames(
                        'w-4 h-4',
                        sortBy.asc && 'rotate-180'
                      )}
                    />
                  )}
                </div>
              </div>
            </th>
            <th
              className="px-2 py-2"
              onClick={() => onChangeSortBy(UserAdminField.FULLNAME)}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>Apellido</span>
                <div className="h-4 w-4">
                  {sortBy.field === UserAdminField.FULLNAME && (
                    <ChevronDownIcon
                      className={classNames(
                        'w-4 h-4',
                        sortBy.asc && 'rotate-180'
                      )}
                    />
                  )}
                </div>
              </div>
            </th>

            <th
              className="px-2 py-2 truncate"
              onClick={() => onChangeSortBy(UserAdminField.PROFILE)}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>Perfil</span>
                <div className="h-4 w-4">
                  {sortBy.field === UserAdminField.PROFILE && (
                    <ChevronDownIcon
                      className={classNames(
                        'w-4 h-4',
                        sortBy.asc && 'rotate-180'
                      )}
                    />
                  )}
                </div>
              </div>
            </th>
            <th
              className="px-2 py-2 truncate"
              onClick={() => onChangeSortBy(UserAdminField.DEPARTAMENT)}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>Departamento</span>
                <div className="h-4 w-4">
                  {sortBy.field === UserAdminField.DEPARTAMENT && (
                    <ChevronDownIcon
                      className={classNames(
                        'w-4 h-4',
                        sortBy.asc && 'rotate-180'
                      )}
                    />
                  )}
                </div>
              </div>
            </th>
            <th
              className="px-2 py-2 truncate"
              onClick={() => onChangeSortBy(UserAdminField.IDENTIFICATION)}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>Identificación</span>
                <div className="h-4 w-4">
                  {sortBy.field === UserAdminField.IDENTIFICATION && (
                    <ChevronDownIcon
                      className={classNames(
                        'w-4 h-4',
                        sortBy.asc && 'rotate-180'
                      )}
                    />
                  )}
                </div>
              </div>
            </th>
            <th
              className="px-2 py-2 truncate"
              onClick={() => onChangeSortBy(UserAdminField.STATUS)}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>Estatus</span>
                <div className="h-4 w-4">
                  {sortBy.field === UserAdminField.STATUS && (
                    <ChevronDownIcon
                      className={classNames(
                        'w-4 h-4',
                        sortBy.asc && 'rotate-180'
                      )}
                    />
                  )}
                </div>
              </div>
            </th>
            <th
              className="px-2 py-2 truncate"
              onClick={() => onChangeSortBy(UserAdminField.PHONE)}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>Teléfono</span>
                <div className="h-4 w-4">
                  {sortBy.field === UserAdminField.PHONE && (
                    <ChevronDownIcon
                      className={classNames(
                        'w-4 h-4',
                        sortBy.asc && 'rotate-180'
                      )}
                    />
                  )}
                </div>
              </div>
            </th>
            <th
              className="px-2 py-2"
              onClick={() => onChangeSortBy(UserAdminField.EMAIL)}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>Email</span>
                <div className="h-4 w-4">
                  {sortBy.field === UserAdminField.EMAIL && (
                    <ChevronDownIcon
                      className={classNames(
                        'w-4 h-4',
                        sortBy.asc && 'rotate-180'
                      )}
                    />
                  )}
                </div>
              </div>
            </th>

            <th
              className="px-2 py-2"
              onClick={() => onChangeSortBy(UserAdminField.DATE)}
            >
              <div className="flex gap-2 items-center cursor-pointer truncate">
                <span>Fecha Creación</span>
                <div className="h-4 w-4">
                  {sortBy.field === UserAdminField.DATE && (
                    <ChevronDownIcon
                      className={classNames(
                        'w-4 h-4',
                        sortBy.asc && 'rotate-180'
                      )}
                    />
                  )}
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {userAdminData.map((userAccountItem, index) => {
            return (
              <tr
                key={v4() + index}
                className={classNames(
                  'hover:bg-gray-200',
                  index % 2 === 0 && 'bg-gray-100'
                )}
              >
                <td className="px-2 py-1 font-semibold">
                  {userAccountItem.userLogin}
                </td>
                <td className="px-2 py-1 font-semibold">
                  {userAccountItem.userName}
                </td>
                <td className="px-2 py-1">{userAccountItem.userLastName}</td>
                <td className="px-2 py-1">
                  {userAccountItem.profileDescription}
                </td>
                <td className="px-2 py-1">{userAccountItem.departamentName}</td>
                <td className="px-2 py-1">
                  {userAccountItem.userIdentificationNumber}
                </td>
                <td className="px-2 py-1">{userAccountItem.statusName}</td>
                <td className="px-2 py-1">{userAccountItem.userPhone}</td>
                <td className="px-2 py-1">{userAccountItem.userEmail}</td>

                <td className="px-2 py-1">
                  {moment(userAccountItem.createDate).format('DD/MM/YYYY')}
                </td>
                <td className="pr-2">
                  <Popover
                    button={TableOptionsButton}
                    containerClassname="!-mt-8 ml-2 mr-5"
                  >
                    <div className="flex flex-col font-semibold cursor-pointer">
                      <div className="flex flex-1 flex-col text-sm text-gray-700 truncate hover:bg-main-500 hover:text-white">
                        <NavLink
                          to={`/user/create/${userAccountItem.userAccountID}/${
                            userAccountItem.accountID ?? ''
                          }/${userAccountItem.accountSiteID ?? ''}`}
                        >
                          <div className="flex gap-2 py-2 px-4 w-64 items-center">
                            <PencilIcon className="w-5 h-5" />
                            <span>Editar</span>
                          </div>
                        </NavLink>
                      </div>
                    </div>
                    <div className="flex flex-col font-semibold cursor-pointer">
                      <div
                        className="flex flex-1 flex-col text-sm text-gray-700 truncate hover:bg-main-500 hover:text-white"
                        onClick={() => {
                          setOpenModal(true);
                          setTitleOpenModal(
                            `¿Desea desactivar el usuario "${userAccountItem.userLogin}" al cliente?`
                          );
                          setAcceptsID(
                            userAccountItem.userAccountID ?? undefined
                          );
                          setAcceptsType(1);
                        }}
                      >
                        <div className="flex gap-2 py-2 px-4 w-64 items-center">
                          <NoSymbolIcon className="w-5 h-5" />
                          <span>Desactivar usuario al cliente</span>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col font-semibold cursor-pointer">
                      <div
                        className="flex flex-1 flex-col text-sm text-gray-700 truncate hover:bg-main-500 hover:text-white"
                        onClick={() => {
                          setOpenModal(true);
                          setTitleOpenModal(
                            `¿Desea eliminar el usuario "${userAccountItem.userLogin}"?`
                          );
                          setAcceptsID(userAccountItem.userID ?? undefined);
                          setAcceptsType(2);
                        }}
                      >
                        <div className="flex gap-2 py-2 px-4 w-64 items-center">
                          <TrashIcon className="w-5 h-5" />
                          <span>Eliminar usuario</span>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col font-semibold cursor-pointer">
                      <div
                        className="flex flex-1 flex-col text-sm text-gray-700 truncate hover:bg-main-500 hover:text-white"
                        onClick={() => {
                          setOpenModal(true);
                          setTitleOpenModal(
                            `¿Desea restablecer contraseña del usuario "${userAccountItem.userLogin}"?`
                          );
                          setUserResetPassword(userAccountItem ?? undefined);
                        }}
                      >
                        <div className="flex gap-2 py-2 px-4 w-64 items-center">
                          <KeyIcon className="w-5 h-5" />
                          <span>Restablecer contraseña</span>
                        </div>
                      </div>
                    </div>
                  </Popover>
                </td>
              </tr>
            );
          })}

          {userAdminData.length === 0 && (
            <tr>
              <td colSpan={11} className="px-4 py-4 text-center">
                No hay usuarios en esta tabla.
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <ModalQuestion
        open={openModal}
        title={titleModal}
        setOpen={setOpenModal}
        setAccepts={setAccepts}
      ></ModalQuestion>

      <ModalLoading
        open={loading}
        title={loadingTitle}
        status={loadingStatus}
        setOpen={setLoading}
        onPrint={() => {}}
      />
    </div>
  );
};
