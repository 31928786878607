import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import * as Yup from "yup";
import { useAppSelector } from "../../store/hooks";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import logo from "../../assets/images/TEALCA-logo.jpg";
import {
  ConfirmModalStatus,
  FormText,
  ModalLoading,
  PrimaryButton,
  SecondaryButton,
} from "../../components";
import { Formik } from "formik";
import classNames from "classnames";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { changePassword } from "../../services";

export interface UserPasswordUpdateFormValues {
  loginOrEmail: string;
  oldPassword: string;
  newPassword: string;
  repeatPassword: string;
}
const initialValues: UserPasswordUpdateFormValues = {
  loginOrEmail: "",
  oldPassword: "",
  newPassword: "",
  repeatPassword: "",
};
interface UserPasswordUpdateProps {
  setShowUpadatePassword?: Dispatch<SetStateAction<boolean>>;
}
const UserPasswordUpdate: FC<UserPasswordUpdateProps> = ({
  setShowUpadatePassword,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userToken } = useParams();
  const { userLoginEmailToken } = useParams();
  const userLogged = useAppSelector((state) => state.user.user);

  const [apiErrors, setApiErrors] = useState<string[]>([]);
  const [passwordChanged, setPasswordChanged] = useState(false);
  let [loading, setLoading] = useState(false);
  let [loadingStatus, setLoadingStatus] = useState(
    ConfirmModalStatus.PROCESSING
  );
  let [loadingTitle, setLoadingTitle] = useState("Cargando...");

  useEffect(() => {
    //If there is an user, but this page is not called from Profile, then redirect to profile
    if (!!userLogged && userToken == undefined && !setShowUpadatePassword) {
      navigate("/profile");
    }
  }, [userLogged, setShowUpadatePassword, navigate, userToken]);

  const updatePassword = async (values: UserPasswordUpdateFormValues) => {
    setLoadingStatus(ConfirmModalStatus.PROCESSING);
    setLoadingTitle("Validando información...");
    setLoading(true);

    const response = await changePassword({
      UserEmailOrLogin:
        location.state?.email ?? userLoginEmailToken ?? values.loginOrEmail,
      UserOldPassword: location.state?.password ?? values.oldPassword,
      UserNewPassword: values.newPassword,
      ApplicationCode: "020",
      UserTokenHash: !!userToken ? userToken : null,
    });

    if (!response || response.didError) {
      setLoadingStatus(ConfirmModalStatus.ERROR);
      setLoadingTitle("Error al cambiar la contraseña: " + response.message);
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    } else {
      setLoadingStatus(ConfirmModalStatus.SUCCESS);
      setLoadingTitle("Por favor inicie sesión con la nueva contraseña");
      navigate("/");
    }
  };

  const validationSchema = Yup.object().shape({
    loginOrEmail: Yup.string().test(
      "loginOrEmail",
      "Debe ingresar el correo o el username",
      function (value) {
        return !!value || !!location.state || !!userToken;
      }
    ),
    oldPassword: Yup.string().test(
      "oldPassword",
      "Debe ingresar la contraseña actual",
      function (value) {
        return !!value || !!location.state || !!userToken;
      }
    ),
    newPassword: Yup.string().required("Debe ingresar la nueva contraseña"),
    repeatPassword: Yup.string()
      .required("Debe repetir la nueva contraseña")
      .test(
        "passwords-match",
        "Las contraseñas no coinciden",
        function (value) {
          return this.parent.newPassword == value;
        }
      ),
  });

  return (
    <main
      id="auth-main"
      className="flex items-center justify-center absolute inset-0 w-full h-full"
    >
      <div
        className="flex flex-col bg-white rounded m-4 sm:m-8"
        style={{ maxWidth: "37rem" }}
      >
        {/* Header */}
        <div className="py-1.5 flex flex-1 justify-center">
          <h1 className="text-lg text-center font-bold text-main-500">
            Bienvenido a MiTealca
          </h1>
        </div>
        <hr />

        {/* Body */}

        <div className="flex flex-1 px-2 mt-4">
          <div className="flex-1 items-center justify-center p-2 hidden sm:flex">
            <img src={logo} alt="Tealca logo" width="230" height="230" />
          </div>

          <div className="flex flex-1 flex-col px-2 py-5 bg-gray-100 border rounded shadow-inner">
            {!passwordChanged && (
              <Formik<UserPasswordUpdateFormValues>
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                  updatePassword(values).then(() => {
                    actions.setSubmitting(false);
                    actions.resetForm({
                      values: initialValues,
                    });
                  });
                }}
              >
                {(formik) => {
                  const {
                    errors,
                    values,
                    touched,
                    handleChange,
                    handleSubmit,
                    handleBlur,
                  } = formik;
                  return (
                    <form className="space-y-6" onSubmit={handleSubmit}>
                      {!!apiErrors && apiErrors.length > 0 && (
                        <p className="text-center ">
                          <span className="text-sm text-red-500">
                            {apiErrors.join(", ")}
                          </span>
                        </p>
                      )}
                      <div className="flex flex-1 flex-col gap-2">
                        <div
                          className={classNames(
                            (!!location.state || !!userToken) && "hidden"
                          )}
                        >
                          <FormText
                            id="new-password-email"
                            name="loginOrEmail"
                            error={
                              touched.loginOrEmail && errors.loginOrEmail
                                ? errors.loginOrEmail
                                : ""
                            }
                            placeholder="Correo electrónico o username"
                            value={values.loginOrEmail}
                            onChange={(value) => {
                              handleChange(value);
                              setApiErrors([]);
                            }}
                            onBlur={handleBlur}
                          />
                        </div>

                        <div
                          className={classNames(
                            (!!location.state || !!userToken) && "hidden"
                          )}
                        >
                          <FormText
                            id="old-password"
                            name="oldPassword"
                            type="password"
                            error={
                              touched.oldPassword && errors.oldPassword
                                ? errors.oldPassword
                                : ""
                            }
                            placeholder="Contraseña Actual"
                            value={values.oldPassword}
                            onChange={(value) => {
                              handleChange(value);
                              setApiErrors([]);
                            }}
                            onBlur={handleBlur}
                          />
                        </div>
                        <div className="py-1.5 -mt-5 flex flex-1 justify-center">
                          <h1 className="text-lg text-center text-main-500">
                            Cambio de clave
                          </h1>
                        </div>

                        <div>
                          <FormText
                            id="new-password"
                            name="newPassword"
                            type="password"
                            error={
                              touched.newPassword && errors.newPassword
                                ? errors.newPassword
                                : ""
                            }
                            placeholder="Nueva Contraseña"
                            value={values.newPassword}
                            onChange={(value) => {
                              handleChange(value);
                              setApiErrors([]);
                            }}
                            onBlur={handleBlur}
                          />
                        </div>

                        <div>
                          <FormText
                            id="repeat-password"
                            name="repeatPassword"
                            type="password"
                            error={
                              touched.repeatPassword && errors.repeatPassword
                                ? errors.repeatPassword
                                : ""
                            }
                            placeholder="Repetir Contraseña"
                            value={values.repeatPassword}
                            onChange={(value) => {
                              handleChange(value);
                              setApiErrors([]);
                            }}
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>

                      <div className="flex flex-1 ">
                        <PrimaryButton type="submit" className="w-full -mt-15">
                          Aceptar
                        </PrimaryButton>
                      </div>
                      <div className=" py-0 " style={{ marginTop: 5 }}>
                        <SecondaryButton
                          className="w-full"
                          onClick={() => navigate("/")}
                        >
                          Iniciar Sesion
                        </SecondaryButton>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            )}
          </div>
        </div>

        {/* Footer */}

        <div className="p-4">
          <span
            className="block text-gray-900 leading-1"
            style={{ fontSize: "0.65rem" }}
          >
            “…Los derechos de la propiedad intelectual y/o industrial sobre la
            información contenida en este software (textos, diseños, gráficos,
            programación...) son de titularidad de Transferencias y Encomiendas
            Angulo Lopez C.A, (TEALCA), la cual se reserva todos los derechos.
            Está autorizado el uso personal, no comercial del mismo; Se prohíbe
            expresamente todo uso o reproducción comercial de cualquiera de los
            textos, imágenes o materiales de cualquier tipo que se encuentren en
            este software, sin autorización expresa y por escrito de (TEALCA),
            Las marcas o signos distintivos, logotipos y en general símbolos
            distintivos de cualquier naturaleza que aparezcan en este software
            son propiedad de (TEALCA) y están protegidos por la legislación
            vigente aplicable...”
          </span>
        </div>
        <ModalLoading
          open={loading}
          title={loadingTitle}
          status={loadingStatus}
          setOpen={setLoading}
          onPrint={() => {}}
        />
      </div>
    </main>

    // <div className="flex h-screen flex-1 items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
    //   <div className="w-full h-full max-w-sm flex flex-1 flex-col justify-around">
    //     <div>
    //       {!userLogged && (
    //         //Logo header
    //         <>
    //           <h1 className="text-center text-2xl font-bold leading-10 tracking-tight text-gray-900">
    //             Taquilla TEALCA
    //           </h1>
    //         </>
    //       )}
    //       <h2 className="mt-5 text-center text-2xl font-semibold leading-9 tracking-tight text-gray-900">
    //         {!!location.state ? "Crea tu contraseña" : "Cambiar contraseña"}
    //       </h2>
    //     </div>

    //     {!passwordChanged && (
    //       <Formik<UserPasswordUpdateFormValues>
    //         initialValues={initialValues}
    //         validationSchema={validationSchema}
    //         onSubmit={(values, actions) => {
    //           updatePassword(values).then(() => {
    //             actions.setSubmitting(false);
    //             actions.resetForm({
    //               values: initialValues,
    //             });
    //           });
    //         }}
    //       >
    //         {(formik) => {
    //           const {
    //             errors,
    //             values,
    //             touched,
    //             handleChange,
    //             handleSubmit,
    //             handleBlur,
    //           } = formik;
    //           return (
    //             <form className="space-y-6" onSubmit={handleSubmit}>
    //               {!!apiErrors && apiErrors.length > 0 && (
    //                 <p className="text-center ">
    //                   <span className="text-sm text-red-500">
    //                     {apiErrors.join(", ")}
    //                   </span>
    //                 </p>
    //               )}
    //               <div className="flex flex-1 flex-col gap-2">
    //                 <div
    //                   className={classNames(
    //                     (!!location.state || !!userToken) && "hidden"
    //                   )}
    //                 >
    //                   <FormText
    //                     id="new-password-email"
    //                     name="loginOrEmail"
    //                     error={
    //                       touched.loginOrEmail && errors.loginOrEmail
    //                         ? errors.loginOrEmail
    //                         : ""
    //                     }
    //                     placeholder="Correo electrónico o username"
    //                     value={values.loginOrEmail}
    //                     onChange={(value) => {
    //                       handleChange(value);
    //                       setApiErrors([]);
    //                     }}
    //                     onBlur={handleBlur}
    //                   />
    //                 </div>

    //                 <div
    //                   className={classNames(
    //                     (!!location.state || !!userToken) && "hidden"
    //                   )}
    //                 >
    //                   <FormText
    //                     id="old-password"
    //                     name="oldPassword"
    //                     type="password"
    //                     error={
    //                       touched.oldPassword && errors.oldPassword
    //                         ? errors.oldPassword
    //                         : ""
    //                     }
    //                     placeholder="Contraseña Actual"
    //                     value={values.oldPassword}
    //                     onChange={(value) => {
    //                       handleChange(value);
    //                       setApiErrors([]);
    //                     }}
    //                     onBlur={handleBlur}
    //                   />
    //                 </div>

    //                 <div>
    //                   <FormText
    //                     id="new-password"
    //                     name="newPassword"
    //                     type="password"
    //                     error={
    //                       touched.newPassword && errors.newPassword
    //                         ? errors.newPassword
    //                         : ""
    //                     }
    //                     placeholder="Nueva Contraseña"
    //                     value={values.newPassword}
    //                     onChange={(value) => {
    //                       handleChange(value);
    //                       setApiErrors([]);
    //                     }}
    //                     onBlur={handleBlur}
    //                   />
    //                 </div>

    //                 <div>
    //                   <FormText
    //                     id="repeat-password"
    //                     name="repeatPassword"
    //                     type="password"
    //                     error={
    //                       touched.repeatPassword && errors.repeatPassword
    //                         ? errors.repeatPassword
    //                         : ""
    //                     }
    //                     placeholder="Repetir Contraseña"
    //                     value={values.repeatPassword}
    //                     onChange={(value) => {
    //                       handleChange(value);
    //                       setApiErrors([]);
    //                     }}
    //                     onBlur={handleBlur}
    //                   />
    //                 </div>
    //               </div>

    //               <div className="flex flex-1">
    //                 <PrimaryButton type="submit" className="w-full">
    //                   Aceptar
    //                 </PrimaryButton>
    //               </div>
    //             </form>
    //           );
    //         }}
    //       </Formik>
    //     )}

    //     {passwordChanged && (
    //       <div className="flex flex-col items-center">
    //         <p className="text-center ">
    //           <span className="text-green-500">
    //             La contraseña ha sido cambiada exitosamente.
    //             {!userLogged
    //               ? "Por favor inicie sesión con la nueva contraseña."
    //               : ""}
    //           </span>
    //         </p>
    //       </div>
    //     )}

    //     <div className="flex flex-col items-center">
    //       {!userLogged && (!location.state || passwordChanged) && (
    //         <LinkText
    //           text="Iniciar Sesión"
    //           onClick={() => navigate("/login")}
    //         />
    //       )}
    //       {!!setShowUpadatePassword && (
    //         <LinkText
    //           text="Volver"
    //           onClick={() => setShowUpadatePassword(false)}
    //         />
    //       )}
    //     </div>
    //   </div>
    // </div>
  );
};
export default UserPasswordUpdate;
