import axios from 'axios';
import {
  AccountAgreementInterfase,
  AccountAgreementResponseInterfase,
  AccountAgreementSpInterfase,
  AccountsAuthInterfase,
} from '../interfaces';
import {
  ListResponse,
  SimpleResponse,
} from '../interfaces/Shipment/ListResponseDto';
import { AuthenticationServices } from './AuthenticationServices';

const { REACT_APP_URL_PATH_API } = process.env;

export const getAllAccountAuth = async (
  accountID?: string,
  AgreementAuthID?: string,
  AccountCode?: string
): Promise<ListResponse<AccountsAuthInterfase[]> | undefined> => {
  const token = await AuthenticationServices();
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  var response = await axios
    .get(
      `${REACT_APP_URL_PATH_API}/accounts/GetAllAccountAuth?AccountID=${
        accountID ?? ''
      }&AgreementAuthID=${AgreementAuthID ?? ''}&AccountCode=${
        AccountCode ?? ''
      }`
    )
    .then(function (response) {
      var responsedto = response.data;
      return responsedto as ListResponse<AccountsAuthInterfase[]>;
    })
    .catch(function (error) {
      //dirError= true;
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        //let  errorData = error.response.data;
        //errorMessage = errorData.message ?? "";
        console.log(error.message ?? '');
        return error.response as ListResponse<AccountsAuthInterfase[]>;
      } else {
        // Something happened in setting up the request that triggered an Error
        //errorMessage = error.message ?? "";
        console.log(error.message ?? '');
      }
      return [] as unknown as ListResponse<AccountsAuthInterfase[]>;
    });

  return response;
};

export const createAccountAuthApi = async (
  consigne: AccountAgreementSpInterfase
): Promise<SimpleResponse<AccountAgreementResponseInterfase[]>> => {
  try {
    const token = await AuthenticationServices();
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    var response = await axios
      .post(`${REACT_APP_URL_PATH_API}/Agreement/InsertAgreementAuth`, consigne)
      .then(function (response) {
        var data =
          response.data as ListResponse<AccountAgreementResponseInterfase>;

        return data;
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          let errorData = error.response
            .data as ListResponse<AccountAgreementResponseInterfase>;
          console.log(
            'Error generando cuenta autorizada:',
            errorData.errorMessage
          );

          return errorData;
        } else {
          // Something happened in setting up the request that triggered an Error
          var data = {} as ListResponse<AccountAgreementResponseInterfase>;
          data.didError = true;
          data.errorMessage =
            'Error generando cuenta autorizada:' + error.message;
          console.log('Error generando cuenta autorizada:', error.message);
          return data;
        }
        return error;
      });

    return response as ListResponse<AccountAgreementResponseInterfase[]>;
  } catch (error) {
    console.log('Error generando cuenta autorizada:', error);
  }

  return {} as ListResponse<AccountAgreementResponseInterfase[]>;
};

export const getAllAgreementAccountAuth = async (
  accountID?: string,
  agreementID?: string,
  accountCode?: string
): Promise<ListResponse<AccountAgreementInterfase[]>> => {
  const token = await AuthenticationServices();
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  var response = await axios
    .get(
      `${REACT_APP_URL_PATH_API}/Agreement/AgreementSerAndPay?AccountID=${
        accountID ?? ''
      }&AgreementID=${agreementID ?? ''}&accountSite=${accountCode ?? ''}`
    )
    .then(function (response) {
      var responsedto = response.data;
      return responsedto as ListResponse<AccountAgreementInterfase[]>;
    })
    .catch(function (error) {
      //dirError= true;
      if (error.response) {
        console.log(error.message ?? '');
        return error.response as ListResponse<AccountAgreementInterfase[]>;
      } else {
        console.log(error.message ?? '');
      }
      return [] as unknown as ListResponse<AccountAgreementInterfase[]>;
    });

  return response;
};
