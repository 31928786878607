import { FC, useEffect, useMemo, useState } from "react";
import {
  ConfirmModalStatus,
  FormSelect,
  FormText,
  MainPage,
  ModalLoading,
  PageTitle,
  PaginationFooter,
  UserAdminField,
} from "../../components";
import { getAllUserClients } from "../../services/UserServices";
import { UserAccountsCountInterfase } from "../../interfaces";
import { NavLink } from "react-router-dom";
import { ChevronDoubleRightIcon } from "@heroicons/react/24/outline";

const ConsultUserClient: FC = () => {
  const [isHovered, setIsHovered] = useState<number | undefined>(0);
  let [loading, setLoading] = useState(false);
  let [loadingStatus, setLoadingStatus] = useState(
    ConfirmModalStatus.PROCESSING
  );
  let [loadingTitle, setLoadingTitle] = useState("Cargando...");
  const [userAdmin, setuserAdmin] = useState<UserAccountsCountInterfase[]>([]);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [registersNumber, setRegistersNumber] = useState(10);

  let sortAdminBy = {
    field: UserAdminField.NAME,
    asc: true,
  };

  const filtereduserAdmin = useMemo(() => {
    setPage(0);
    const tokens = search?.toLowerCase().split(" ");
    return userAdmin.filter((disptach) => {
      return tokens.every((token) => {
        return (
          disptach.accountCode?.toString().toLowerCase().includes(token) ||
          disptach.accountName?.toLowerCase().includes(token) ||
          disptach.typePayment?.toString()?.toLowerCase().includes(token) ||
          disptach.countUsers?.toString()?.toLowerCase().includes(token)
        );
      });
    });
  }, [userAdmin, search]);

  const paginateduserAdmin = useMemo(() => {
    const ordereduserAdmin = filtereduserAdmin.sort((a, b) => {
      return sortAdminBy.asc
        ? a.accountCode.localeCompare(b.accountCode)
        : b.accountCode.localeCompare(a.accountCode);
    });

    const start = page * registersNumber;
    const end = start + registersNumber;

    return ordereduserAdmin.slice(start, end);
  }, [filtereduserAdmin, sortAdminBy, page, registersNumber]);

  useEffect(() => {
    const fetchUser = async () => {
      setLoadingStatus(ConfirmModalStatus.PROCESSING);
      setLoadingTitle("Cargando...");
      setLoading(true);
      const userAdmin = await getAllUserClients();
      var listuserAdmin = userAdmin?.model as UserAccountsCountInterfase[];
      if (!userAdmin?.didError) {
        setuserAdmin(listuserAdmin);
        setLoading(false);
      } else {
        setLoadingStatus(ConfirmModalStatus.ERROR);
        setLoadingTitle("Error: " + userAdmin?.errorMessage);
        setLoading(true);
      }
    };
    fetchUser();
  }, []);

  return (
    <MainPage>
      <PageTitle title="Listado de Usuarios por cliente" />

      <div className="flex flex-1 flex-col">
        <div className="flex flex-1 justify-center items-center gap-2 md:gap-6 flex-col-reverse md:flex-row">
          <div className="flex flex-1" style={{ maxWidth: "150rem" }}>
            <FormText
              id="search"
              name="search"
              value={search}
              placeholder="Busqueda rapida..."
              containerClassname="flex flex-1"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>

          <div className="">
            <div className="flex items-center text-xs">
              <span>Mostrar</span>
              <div className="inline mx-1">
                <FormSelect
                  id="registersNumber"
                  name="registersNumber"
                  label=""
                  selected={registersNumber}
                  options={[10, 25, 50, 100]}
                  className="!w-20 !h-8 !px-2"
                  optionString={(option) => option.toString()}
                  onSelectOption={(option) => setRegistersNumber(option)}
                />
              </div>
              <span>registros</span>
            </div>
          </div>
        </div>

        <div className="container mx-auto px-4 mb-5 mt-5">
          {paginateduserAdmin.map((user, index) => (
            <NavLink
              to={`/user/${user.accountID}/${user.accountSiteID ?? "0"}`}
              key={index}
              className="flex justify-between items-center p-4 bg-white shadow rounded-lg mb-1 hover:bg-gray-100 cursor-pointer"
              onMouseEnter={() => setIsHovered(index)}
              onMouseLeave={() => setIsHovered(undefined)}
            >
              <div>
                <h5 className="font-semibold text-sm">
                  {user.accountCode} - {user.accountName}
                </h5>
              </div>
              <div className="flex items-center">
                <button className="inline-flex items-center py-1 px-2 rounded-full text-xs font-medium bg-blue-500 text-white">
                  {isHovered === index
                    ? user.countUsers + " Usuarios"
                    : user.countUsers}
                </button>
                <span className="mr-2"></span>
                {isHovered === index && (
                  <ChevronDoubleRightIcon
                    className="w-4"
                    style={{ color: "#94a3b8" }}
                  />
                )}
              </div>
            </NavLink>
          ))}
        </div>

        <PaginationFooter
          currentPage={page}
          itemsPerPage={registersNumber}
          totalItems={filtereduserAdmin.length}
          onPageChange={setPage}
        />
      </div>

      <ModalLoading
        open={loading}
        title={loadingTitle}
        status={loadingStatus}
        setOpen={setLoading}
        onPrint={() => {}}
      />
    </MainPage>
  );
};

export default ConsultUserClient;
