import { PieceForm, PieceFormValues } from './PieceForm';
import { PieceTable } from './PieceTable';
import { PrimaryButton } from '../Atoms/Buttons';
import { FormText } from '../FormFields/FormText';
import { FormCheckbox } from '../FormFields/FormCheckbox';
import { FormTextArea } from '../FormFields/FormTextArea';
import { FormRadioGroup } from '../FormFields/FormRadioGroup';
import { FormikErrors, FormikProps, FormikTouched } from 'formik';
import {
  DeliveryEnum,
  ItemInterface,
  LocationInterface,
  PieceInterface,
  ShippingMethodEnum,
  discountItem,
} from '../../interfaces';
import { useEffect, useRef, useState } from 'react';
import {
  formantPackageType,
  formantPaymentModeId,
  formantWeightTypeEnum,
  handleNumberChange,
} from '../../utils/format';
import { ServiceEnum } from '../../interfaces/Services/ServicesEnum';
import { PackageTypeEnum } from '../../interfaces/Shipment/PackageTypeEnum';
import { WeightTypeEnum } from '../../interfaces/Shipment/WeightTypeEnum';
import { useAppSelector } from '../../store/hooks';
import { PaymentModeId } from '../../interfaces/Shipment/PaymentModeEnum';
import ShipmentItemTable from './ShipmentItemTable';
import { v4 } from 'uuid';
import { ConsigneeFormValuesV2 } from './ConsigneeFormV2';
import { getShipmentItems, getShipmentRates } from '../../services';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';

export interface ShipmentFormValuesV2 {
  service: ServiceEnum;
  paymentModeID: PaymentModeId;
  shippingMethod: ShippingMethodEnum;
  tracking: string;
  isSafeKeeping: boolean;
  packageType: string;
  weightUnit: string;
  pieces: PieceInterface[];
  observations: string;
  valueDeclarate: string;
  total?: number;
  totalChargedWeight?: number;
  totalPhysicalWeight?: number;
  totalDimensionalWeight?: number;
  totalPostalTaxBaseCurr?: number;
  items?: ItemInterface[];
  deliveryDistance?: number;
}
interface ShipmentFieldV2 {
  newPiece: PieceFormValues;
  shipment: ShipmentFormValuesV2;
  consignee: ConsigneeFormValuesV2;
  shippingLocation: LocationInterface;
}
interface ShipmentFormPropsV2<T> {
  formik: FormikProps<T & ShipmentFieldV2>;
}

export const ShipmentFormV2 = <T extends any>({
  formik,
}: ShipmentFormPropsV2<T>) => {
  const errors = formik.errors.shipment as
    | FormikErrors<ShipmentFormValuesV2>
    | undefined;
  const touched = formik.touched.shipment as
    | FormikTouched<ShipmentFormValuesV2>
    | undefined;

  const [selectValueDeclared, setSelectValueDeclared] =
    useState<boolean>(false);

  const [shipmentItems, setShipmentItems] = useState<ItemInterface[]>([]);
  const [distanceItems, setDistanceItems] = useState<number>(0);

  const [baseShipmentItems, setBaseShipmentItems] = useState<ItemInterface[]>(
    []
  );
  const rateKey = useRef<string>('');
  const itemsKey = useRef<string>('');

  const onSelectSafeKeeping = () => {
    setSelectValueDeclared(!selectValueDeclared);
    formik.setFieldValue('shipment.isSafeKeeping', !selectValueDeclared);
  };
  const user = useAppSelector((state) => state.user)!;

  useEffect(() => {
    if (
      formik.values.shipment.paymentModeID?.toString() ===
      PaymentModeId.COD.toString()
    ) {
      setSelectValueDeclared(true);
      formik.setFieldValue('shipment.isSafeKeeping', true);
    } else {
      setSelectValueDeclared(false);
      formik.setFieldValue('shipment.isSafeKeeping', false);
    }
  }, [formik.values.shipment.paymentModeID]);

  useEffect(() => {
    let piecesList = formik.values.shipment.pieces;
    let countPieces = piecesList.length;
    piecesList.map((pieces) => {
      let piecesData = formik.values.shipment.valueDeclarate;
      piecesData = piecesData === undefined ? '0' : piecesData;
      pieces.declaredValue = parseFloat(piecesData ?? '0') / countPieces;
    });
    formik.setFieldValue('shipment.pieces', piecesList);
  }, [formik.values.shipment.valueDeclarate]);

  useEffect(() => {
    const { pieces } = formik.values.shipment;
    if (
      !pieces ||
      // !shipper ||
      // !buSource ||
      // !consigneeAddress ||
      pieces.length === 0 ||
      baseShipmentItems.length === 0
    ) {
      setShipmentItems([]);
      formik.setFieldValue('shipment.items', []);
      formik.setFieldValue('shipment.totalChargedWeight', 0);
      formik.setFieldValue('shipment.totalPhysicalWeight', 0);
      formik.setFieldValue('shipment.totalDimensionalWeight', 0);
      return;
    }

    if (
      formik.values.shipment.paymentModeID?.toString() !==
      PaymentModeId.COD?.toString()
    )
      return;
    const getAllShipmentItems = async () => {
      const key = v4();
      rateKey.current = key;
      let countItem = pieces.reduce((acc, p) => acc + p.declaredValue, 0);

      if (
        user!.client?.businessUnit?.location === undefined ||
        formik.values.consignee.businessUnit?.location === undefined
      )
        return;

      let response = await getShipmentRates(
        ServiceEnum.STANDARD,
        formik.values.shipment.paymentModeID,
        formik.values.consignee.ShipmentType ?? DeliveryEnum.OFFICE,
        formik.values.consignee.businessUnit?.buCode ?? '',
        user!.client?.businessUnit?.location ?? ({} as LocationInterface),
        formik.values.shippingLocation.name === ''
          ? formik.values.consignee.businessUnit.location
          : formik.values.shippingLocation ?? ({} as LocationInterface),
        baseShipmentItems,
        pieces,
        countItem > 0 ? true : false,
        user!.client.id ?? undefined,
        formik.values.shipment.paymentModeID.toString() ===
          PaymentModeId.COD.toString()
          ? user!.client.id
          : undefined
      );

      if (!response || key !== rateKey.current) {
        return;
      }
      if (response.didError || !response.model) {
        return;
      }
      rateKey.current = '';
      setShipmentItems(response.model.items);
      setDistanceItems(response.model.distance);
      formik.setFieldValue(
        'shipment.deliveryDistance',
        response.model.distance
      );
      formik.setFieldValue(
        'shipment.items',
        response.model.items
          .filter(
            (item) =>
              item.mandatory ||
              formik.values.shipment.items?.some((s) => s.id === item.id)
          )
          .sort((a, b) => a.order - b.order)
      );
      formik.setFieldValue(
        'shipment.totalChargedWeight',
        response.model.chargedWeight
      );
      formik.setFieldValue(
        'shipment.totalPhysicalWeight',
        response.model.physicalWeight
      );
      formik.setFieldValue(
        'shipment.totalDimensionalWeight',
        response.model.dimensionalWeight
      );
    };
    getAllShipmentItems();
  }, [
    formik.values.shipment.service,
    formik.values.shipment.paymentModeID,
    formik.values.shipment.valueDeclarate,
    formik.values.consignee.ShipmentType,
    formik.values.shipment.pieces,
    formik.values.consignee,
    baseShipmentItems,
  ]);

  // Get shipment items
  useEffect(() => {
    const getAllShipmentItems = async () => {
      const key = v4();
      itemsKey.current = key;
      const response = await getShipmentItems(
        ServiceEnum.STANDARD,
        formik.values.shipment.paymentModeID,
        formik.values.consignee.ShipmentType ?? DeliveryEnum.OFFICE,
        formik.values.shipment.pieces?.reduce(
          (acc, p) => acc + p.declaredValue,
          0
        ) > 0,
        formik.values.shipment.paymentModeID.toString() ===
          PaymentModeId.COD.toString()
          ? user!.client!.id
          : undefined
      );

      if (!response || key !== itemsKey.current) return;
      if (response.didError || !response.model) {
        return;
      }
      itemsKey.current = '';

      // Filter ipostel and discount
      setBaseShipmentItems(
        response.model.filter(
          (item) =>
            !item.name.toLowerCase().includes('ipostel') &&
            item.id !== discountItem.id
        )
      );
    };

    if (
      formik.values.shipment.paymentModeID?.toString() ===
      PaymentModeId.COD?.toString()
    )
      getAllShipmentItems();
  }, [
    formik.values.shipment.service,
    formik.values.shipment.paymentModeID,
    formik.values.consignee.ShipmentType,
    formik.values.shipment.valueDeclarate,
    formik.values.consignee,
    formik.values.shipment.pieces,
  ]);

  useEffect(() => {
    formik.setFieldValue('shipment.valueDeclarate', '');
  }, [formik.values.shipment.paymentModeID]);

  useEffect(() => {
    if (formik.values.shipment.paymentModeID === PaymentModeId.CREDIT) {
      let accountBillTo = user.paymentMethod?.filter(
        (x) => x.paymentModeID === formik.values.shipment.paymentModeID
      )![0]?.accountBillTo;
      formik.setFieldValue('shipment.accountBillToID', accountBillTo);
    } else {
      formik.setFieldValue('shipment.accountBillToID', undefined);
    }
  }, [formik.values.shipment.paymentModeID]);

  return (
    <div className="flex flex-1 flex-col gap-16">
      <div className="flex flex-1 flex-col lg:flex-row gap-6 xl:gap-8 lg:gap-16">
        <div className="flex flex-1 h-full flex-col gap-4 px-0 sm:px-8 lg:px-0">
          {user.paymentMethod !== undefined &&
            user.paymentMethod?.length > 1 && (
              <FormRadioGroup
                id="shipment.paymentModeID"
                name="shipment.paymentModeID"
                label="Metodo de pago"
                selected={formik.values.shipment.paymentModeID}
                labelClassname="!text-xs flex sm:!justify-end sm:w-32"
                className="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
                wrapperClassName="flex !flex-row"
                optionsClassName="text-xs !ml-1.5"
                options={Object.values(user.paymentMethod).map((method) => ({
                  name: formantPaymentModeId(method.paymentModeID),
                  value: method.paymentModeID,
                }))}
                onSelectOption={(opt) =>
                  formik.setFieldValue('shipment.paymentModeID', opt)
                }
              />
            )}

          {user.paymentMethod !== undefined &&
            user.paymentMethod?.length === 1 && (
              <FormText
                disabled
                id="shipment.paymentModeID"
                name="shipment.paymentModeID"
                label="Metodo de pago"
                value={formantPaymentModeId(
                  formik.values.shipment.paymentModeID
                )}
                error={
                  touched?.paymentModeID && errors?.paymentModeID
                    ? errors?.paymentModeID
                    : undefined
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="!h-8"
                labelClassname="!text-xs"
                labelContainerClassname="flex sm:!justify-end sm:w-32"
                containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
                style={{ maxWidth: '10rem' }}
              />
            )}

          {user.paymentMethod === undefined && (
            <FormText
              disabled
              id="shipment.paymentModeID"
              name="shipment.paymentModeID"
              label="Metodo de pago"
              value={formantPaymentModeId(formik.values.shipment.paymentModeID)}
              error={
                touched?.paymentModeID && errors?.paymentModeID
                  ? errors?.paymentModeID
                  : undefined
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="!h-8"
              labelClassname="!text-xs"
              labelContainerClassname="flex sm:!justify-end sm:w-32"
              containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
              style={{ maxWidth: '10rem' }}
            />
          )}

          <FormCheckbox
            id="shipment.isSafeKeeping"
            name="shipment.isSafeKeeping"
            label="¿Viaja asegurado?"
            onChange={() => {
              onSelectSafeKeeping();
            }}
            onBlur={formik.handleBlur}
            checked={formik.values.shipment.isSafeKeeping}
            labelClassname="!text-xs mb-1"
            labelContainerClassname="flex sm:!justify-end sm:w-32"
            containerClassname="justify-end flex flex-1 gap-8 items-center !flex-row-reverse"
            disabled={
              formik.values.shipment.paymentModeID?.toString() ===
              PaymentModeId.COD?.toString()
            }
          />

          {selectValueDeclared === true ? (
            <div className="flex items-center">
              <FormText
                id="shipment.valueDeclarate"
                name="shipment.valueDeclarate"
                label="Valor declarado en dólares"
                autoComplete="off"
                value={formik.values.shipment.valueDeclarate}
                error={
                  touched?.valueDeclarate && errors?.valueDeclarate
                    ? errors?.valueDeclarate
                    : undefined
                }
                onChange={(e) => handleNumberChange(e, formik, true)}
                onBlur={formik.handleBlur}
                className="!h-8"
                labelClassname="!text-xs"
                labelContainerClassname="flex sm:!justify-end sm:w-32"
                containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
              />
              {(touched?.valueDeclarate && errors?.valueDeclarate) ||
              formik.values.shipment.valueDeclarate === '' ? (
                <XMarkIcon className="w-5 ml-2 -mt-4 text-sm text-red-500"></XMarkIcon>
              ) : (
                <CheckIcon
                  className="w-5 ml-2"
                  style={{ color: '#166534' }}
                ></CheckIcon>
              )}
            </div>
          ) : (
            <div /* Este es el div 2 */ className="red2" />
          )}

          {formik.values.shipment.paymentModeID?.toString() !==
            PaymentModeId.COD?.toString() && (
            <>
              <FormRadioGroup
                id="shipment.packageType"
                name="shipment.packageType"
                label="Tipo de paquete"
                selected={formik.values.shipment.packageType}
                labelClassname="!text-xs flex sm:!justify-end sm:w-32"
                className="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
                wrapperClassName="flex !flex-row"
                optionsClassName="text-xs !ml-1.5"
                options={Object.values(PackageTypeEnum).map((method) => ({
                  name: formantPackageType(method),
                  value: method,
                }))}
                onSelectOption={(opt) =>
                  formik.setFieldValue('shipment.packageType', opt)
                }
              />

              <FormRadioGroup
                disabled
                id="shipment.weightUnit"
                name="shipment.weightUnit"
                label="Tipo de Peso"
                selected={formik.values.shipment.weightUnit}
                labelClassname="!text-xs flex sm:!justify-end sm:w-32"
                className="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
                wrapperClassName="flex !flex-row"
                optionsClassName="text-xs !ml-1.5"
                options={Object.values(WeightTypeEnum).map((method) => ({
                  name: formantWeightTypeEnum(method),
                  value: method,
                }))}
                onSelectOption={(opt) =>
                  formik.setFieldValue('shipment.weightUnit', opt)
                }
              />

              <FormText
                id="shipment.tracking"
                name="shipment.tracking"
                label="N° de Tracking/Precinto"
                value={formik.values.shipment.tracking}
                error={
                  touched?.tracking && errors?.tracking
                    ? errors?.tracking
                    : undefined
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="!h-8"
                labelClassname="!text-xs"
                labelContainerClassname="flex sm:!justify-end sm:w-32"
                containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
                style={{ maxWidth: '10rem' }}
              />
            </>
          )}

          <FormTextArea
            id="shipment.observations"
            name="shipment.observations"
            label="Observaciones de la guía"
            value={formik.values.shipment.observations}
            error={
              touched?.observations && errors?.observations
                ? errors?.observations
                : undefined
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            labelClassname="!text-xs"
            labelContainerClassname="flex sm:!justify-end sm:w-32 sm:mt-2"
            containerClassname="flex flex-1 sm:ml-0 sm:gap-8 flex-col sm:flex-row"
            style={{ maxWidth: '30rem' }}
          />
        </div>
        <div className="inline-block h-[250px] min-h-[1em] w-0.5 self-stretch bg-neutral-100 dark:bg-black/10"></div>
        <div className="flex flex-1 flex-col lg:px-0 xl:pl-4">
          <span className="text-sm font-bold text-gray-700 mb-4">
            Complete los datos de la pieza:
          </span>

          <PieceForm formik={formik} />
        </div>
      </div>
      {formik.values.shipment.paymentModeID?.toString() ===
        PaymentModeId.COD?.toString() && (
        <div className="flex flex-1 overflow-auto mr-3 -mt-6">
          <div className="flex flex-col w-full items-end text-gray-800 mr-6">
            <p className="">
              Peso a facturar:{' '}
              <span className="font-semibold">
                {formik.values.shipment.totalChargedWeight?.toFixed(2) ??
                  '0.00'}{' '}
                Kg
              </span>
            </p>
            <p className="text-xs mt-2">
              Peso balanza:{' '}
              <span className="font-semibold">
                {formik.values.shipment.totalPhysicalWeight?.toFixed(2) ??
                  '0.00'}{' '}
                Kg
              </span>
            </p>
            <p className="text-xs">
              Peso volumétrico:{' '}
              <span className="font-semibold">
                {formik.values.shipment.totalDimensionalWeight?.toFixed(2) ??
                  '0.00'}{' '}
                Kg
              </span>
            </p>
          </div>
        </div>
      )}
      <div className="flex flex-1 -mb-16">
        <span className="text-sm font-bold text-gray-700">
          Datos de la Piezas:
        </span>
      </div>
      <div className="flex flex-1 overflow-auto">
        <PieceTable
          error={touched?.pieces && !!errors?.pieces}
          pieces={formik.values.shipment.pieces}
          onDelete={(index) => {
            const newPieces = [...formik.values.shipment.pieces];
            newPieces.splice(index, 1);
            formik.setFieldValue('shipment.pieces', newPieces);
          }}
          typeSend={formik.values.shipment.packageType ?? PackageTypeEnum.BOX}
        />
      </div>
      {formik.values.shipment.paymentModeID?.toString() ===
        PaymentModeId.COD?.toString() && (
        <div className="flex flex-1 overflow-auto">
          <ShipmentItemTable
            editable
            total={formik.values.shipment.total}
            items={formik.values.shipment.items ?? []}
            distance={formik.values.shipment.deliveryDistance}
            declaredValue={
              parseFloat(formik.values.shipment.valueDeclarate) ?? undefined
            }
            availableItems={shipmentItems.filter(
              (item) =>
                !item.mandatory &&
                !(formik.values.shipment.items ?? []).some(
                  (i) => i.id === item.id
                )
            )}
          />
        </div>
      )}

      <div className="flex flex-1 justify-center">
        <PrimaryButton className="px-10 w-full sm:w-auto" type="submit">
          Guardar
        </PrimaryButton>
      </div>
    </div>
  );
};
