import { FC, useState } from 'react';
import classNames from 'classnames';
import { PieceInterface } from '../../interfaces';
import { TrashIcon as TrashIconSolid } from '@heroicons/react/20/solid';
import { TrashIcon as TrashIconOutline } from '@heroicons/react/24/outline';

interface PieceItemProps {
  piece: PieceInterface;
  index: number;
  onDelete: (index: number) => void;
}
const PieceItem: FC<PieceItemProps> = ({ piece, index, onDelete }) => {
  const [deleteHover, setDeleteHover] = useState(false);

  return (
    <tr
      className={classNames(
        'hover:bg-gray-200',
        index % 2 === 0 && 'bg-gray-100'
      )}
    >
      <td className="px-4 py-4 font-semibold">{index + 1}</td>
      <td className="px-4 py-4 truncate">{piece.category.name}</td>
      <td className="px-4 py-4 truncate">{piece.weight.toFixed(2)} Kg</td>
      <td className="px-4 py-4 truncate">
        {piece.height}cm - {piece.width}cm - {piece.length}cm
      </td>
      <td
        className="w-12"
        onMouseEnter={() => setDeleteHover(true)}
        onMouseLeave={() => setDeleteHover(false)}
      >
        {deleteHover ? (
          <TrashIconSolid
            onClick={() => onDelete(index)}
            className="w-10 h-10 p-2 text-red-600 cursor-pointer"
          />
        ) : (
          <TrashIconOutline
            onClick={() => onDelete(index)}
            className="w-10 h-10 p-2 text-red-600 cursor-pointer"
          />
        )}
      </td>
    </tr>
  );
};

interface PieceTableProps {
  pieces: PieceInterface[];
  error?: boolean;
  onDelete: (index: number) => void;
}
export const PieceTable: FC<PieceTableProps> = ({
  pieces,
  error,
  onDelete,
}) => {
  return (
    <div
      style={{ maxHeight: '20rem' }}
      className={classNames(
        'relative w-full overflow-y-auto table-fix-head mt-4 border border-gray-300',
        error ? 'border-red-500' : 'border-gray-300'
      )}
    >
      <table className="table-auto w-full text-gray-600 text-sm text-left">
        <thead className="select-none font-semibold border-b border-gray-300 bg-gray-50">
          <tr>
            <th className="px-4 py-2">#</th>
            <th className="px-4 py-2">Categoría</th>
            <th className="px-4 py-2">Peso</th>
            <th className="px-4 py-2">Dimensiones</th>

            <th className="px-4 py-2" />
          </tr>
        </thead>
        <tbody>
          {pieces.map((piece, index) => (
            <PieceItem
              key={index}
              piece={piece}
              index={index}
              onDelete={onDelete}
            />
          ))}

          {pieces.length === 0 && !error && (
            <tr>
              <td colSpan={6} className="px-4 py-4 text-center">
                Parece que aún no has agregado ninguna pieza
              </td>
            </tr>
          )}
          {pieces.length === 0 && error && (
            <tr>
              <td colSpan={6} className="px-4 py-4 text-center text-red-500">
                Debe agregar al menos una pieza
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
