import { FC, useEffect, useState } from "react";
import { Header, Footer, NavSidebar, NavigationItem } from "..";
import {
  HomeIcon,
  MapPinIcon,
  FolderIcon,
  EnvelopeIcon,
  InboxStackIcon,
  PencilSquareIcon,
  PaperAirplaneIcon,
  ClipboardDocumentListIcon,
  TruckIcon,
  CogIcon,
} from "@heroicons/react/24/outline";
import { useAppSelector } from "../../store/hooks";
import { GetModuleByUser } from "../../services";

const iconMap: any = {
  HomeIcon: HomeIcon,
  EnvelopeIcon: EnvelopeIcon,
  PaperAirplaneIcon: PaperAirplaneIcon,
  FolderIcon: FolderIcon,
  PencilSquareIcon: PencilSquareIcon,
  MapPinIcon: MapPinIcon,
  InboxStackIcon: InboxStackIcon,
  ClipboardDocumentListIcon: ClipboardDocumentListIcon,
  TruckIcon: TruckIcon,
  Cog: CogIcon,
};

interface MainPageProps {
  children?: React.ReactNode;
}
export const MainPage: FC<MainPageProps> = ({ children }) => {
  const user = useAppSelector((state) => state.user);
  const [navigation2, setNavigation2] = useState<NavigationItem[]>([]);

  useEffect(() => {
    async function GetModuleUser() {
      const result: NavigationItem[] = await GetModuleByUser(
        user.user?.id,
        user.user?.profileID
      );
      result.forEach((item) => {
        item.iconRender = iconMap[item.icon as string];
      });
      setNavigation2(result);
    }
    GetModuleUser();
  }, []);

  return (
    <main
      className="flex flex-col w-full h-full"
      style={{ minHeight: "100vh" }}
    >
      <div>
        <Header />
      </div>

      <div className="relative flex flex-1 lg:pl-64 lg:pr-4 pl-12 pt-16 lg:pt-0">
        <NavSidebar navigation={navigation2} />

        <div className="px-4 py-6 w-full">{children}</div>
      </div>

      <div className="mt-auto z-10">
        <Footer />
      </div>
    </main>
  );
};
